import React from 'react'
import PropTypes from 'prop-types'

import {ucfirst} from 'embo/utils/string/case'
import {DAYS} from 'embo/utils/date/constants'


function formatDate(date) {
  return date.toLocaleString(undefined, {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
}

function formatTime(date) {
  const h = date.getHours()
  let m = date.getMinutes()
  if (m === 0) {
    return `${h}h`
  }
  if (m < 10) m = `0${m}`
  return `${h}h${m}`
}

const SingleDay = ({start, end}) => (
  <div className="event-date">
    <div className="event-datetime event-datetime--start">
      <span className="event-datetime__date">
        {ucfirst(formatDate(start))}
      </span>
      <span className="event-datetime__time">
        {formatTime(start)}
      </span>
    </div>
  </div>
)
SingleDay.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
}

const MultiDay = ({start, end}) => (
  <div className="event-date event-date--span">
    <div className="event-datetime event-datetime--start">
      <span className="event-datetime__date">
        Du {formatDate(start)}
      </span>
      <span className="event-datetime__time">
        {formatTime(start)}
      </span>
    </div>
    <div className="event-datetime event-datetime--end">
      <span className="event-datetime__date">
        Au {formatDate(end)}
      </span>
      <span className="event-datetime__time">
        {formatTime(end)}
      </span>
    </div>
  </div>
)
MultiDay.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
}

const EventDate = ({start, end}) => {
  if (end.getTime() - start.getTime() > DAYS) {
    return <MultiDay start={start} end={end}/>
  }
  return <SingleDay start={start} end={end}/>
}
EventDate.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
}

export default React.memo(EventDate, (prevProps, nextProps) => {
  return prevProps.start.getTime() === nextProps.start.getTime()
    && prevProps.end.getTime() === nextProps.end.getTime()
})
