/**
 * Translation from modifier key to the associated property in the event.
 * @see http://www.w3.org/TR/DOM-Level-3-Events/#keys-Modifiers
 */
const modifierKeyToProp = {
  'Alt': 'altKey',
  'Control': 'ctrlKey',
  'Meta': 'metaKey',
  'Shift': 'shiftKey',
}

// IE8 does not implement getModifierState so we simply map it to the only
// modifier keys exposed by the event itself, does not support Lock-keys.
// Currently, all major browsers except Chrome seems to support Lock-keys.
export default function getModifierState(nativeEvent, keyArg) {
  if (nativeEvent.getModifierState) {
    return nativeEvent.getModifierState(keyArg)
  }
  const keyProp = modifierKeyToProp[keyArg]
  return keyProp ? !!nativeEvent[keyProp] : false
}