import Exception from 'embo/utils/Exception'

const HTTP_CREATED = 201
const HTTP_UNPROCESSABLE_ENTITY = 422


export class ValidationError extends Exception {
  constructor(validation) {
    super('Validation Error')
    this.validation = validation
  }
}

/**
 * Posts a Symfony form.
 *
 * @param {HTMLFormElement} form
 * @param {Number} successCode The HTTP response code expected on success.
 *
 * @throws {ValidationError} when the server-side form validation fails.
 * @throws {Error} when posting the form fails.
 *
 * @returns {Promise}
 */
export default function post(form, successCode = HTTP_CREATED) {
  return fetch(form.action, {
    method: 'post',
    headers: {'X-Requested-With': 'XMLHttpRequest'},
    credentials: 'same-origin',
    body: new FormData(form),
  }).then(response => {
    const {status, statusText} = response
    if (status === successCode) {
      return response.json()
    }
    if (status === HTTP_UNPROCESSABLE_ENTITY) {
      // Validation error
      return response.json()
        .then(json => {
          const validation = flattenFormErrors(json, form.name)
          throw new ValidationError(validation)
        })
    }
    const err = new Error(`${status}: ${statusText}`)
    err.response = response
    throw err
  })
}

/**
 * Processes the Symfony form validation error response.
 *
 * Returns a flat map "form_name" => [errors]
 *
 * @param {Object} form The toplevel validation object.
 * @param {String} name The form name.
 * @param {Object} output
 *
 * @returns {Object}
 */
function flattenFormErrors(form, name, output = {}) {
  const {errors, children} = form
  if (errors && errors.length) output[name] = errors
  if (!children) return output

  Object.keys(children)
    .forEach(k => flattenFormErrors(children[k], `${name}_${k}`, output))

  return output
}
