import React, {Children} from 'react'
import PropTypes from 'prop-types'
import {useTransition, animated, config as SpringConfig} from 'react-spring'

import modulo from 'embo/utils/math/mod'
import usePrevious from 'embo/components/hooks/usePrevious'

export const FORWARD = 1
export const BACKWARD = -1

const getTransitionConfig = direction => ({
  from: {
    opacity: 0,
    transform: `translate3d(${direction === BACKWARD ? '-' : ''}100%, 0 ,0)`,
  },
  enter: {opacity: 1, transform: 'translate3d(0%, 0, 0)'},
  leave: {
    opacity: 0,
    transform: `translate3d(${direction === FORWARD ? '-' : ''}100%, 0, 0)`,
  },
  config: SpringConfig.stiff,
})

const Slider = ({
  children,
  currentIndex = 0,
  className = 'slider',
}) => {
  const items = Children.toArray(children)
  const numItems = items.length

  const previousIndex = usePrevious(currentIndex)
  const direction = currentIndex < previousIndex ? BACKWARD : FORWARD
  const current = modulo(currentIndex, numItems)

  const transitions = useTransition(current, null, getTransitionConfig(direction))

  return (
    <div className={className}
      style={{
        position: 'relative',
      }}
    >
      {transitions.map(({item, key, props}) => (
        <animated.div key={key}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            ...props,
          }}
        >
          {items[item]}
        </animated.div>
      ))}
    </div>
  )
}
Slider.propTypes = {
  children: PropTypes.node,
  currentIndex: PropTypes.number,
  direction: PropTypes.oneOf([FORWARD, BACKWARD]),
  className: PropTypes.string,
}
export default Slider
