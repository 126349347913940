import React, {useCallback, useState} from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import {PosterType} from './propTypes'
import BackgroundBlur from '../common/images/BackgroundBlur'
import Spinner from '../common/spinner/Pentagram'


const {floor} = Math


const getSrcSet = thumbnails => Object.keys(thumbnails)
  .map(format => {
    const {url, width} = thumbnails[format]
    return `${url} ${width}w`
  })
  .join(', ')

const getSizes = image => {
  const w = floor(image.width)
  const h = floor(image.height)
  return `(min-aspect-ratio: ${w}/${h}) calc(100vh * (${w}/${h})), 100vw`
}


const Poster = ({
  poster,
  onAuthorClick,
}) => {

  const {image, name, author, dateCreated, copyrightHolder, copyrightYear} = poster

  const [loaded, setLoaded] = useState(false)
  const [backgroundImage, setBackgroundImage] = useState(null)

  const handleImageLoaded = useCallback(event => {
    setLoaded(true)
    setBackgroundImage(event.target.currentSrc)
  })
  const handleAuthorClicked = useCallback(
    event => {
      event.preventDefault()
      onAuthorClick(author)
    },
    [author, onAuthorClick]
  )

  const classes = cx('embo-posters__poster', {
    'embo-posters__poster--is-loaded': loaded,
  })
  const date = new Date(dateCreated).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  return (
    <figure className={classes}>
      <BackgroundBlur image={backgroundImage}/>
      <img srcSet={getSrcSet(image.thumbnail)} sizes={getSizes(image)} onLoad={handleImageLoaded}/>
      {!loaded && <Spinner size="md"/>}
      <figcaption className="embo-posters__poster__caption">
        <div>
          «{name}», par &nbsp;
          <a onClick={handleAuthorClicked} href="#">{author}</a>
          &nbsp; le {date}.
        </div>
        {copyrightHolder && <div>© {copyrightYear} - {copyrightHolder}</div>}
      </figcaption>
    </figure>
  )
}
Poster.propTypes = {
  poster: PosterType.isRequired,
  onAuthorClick: PropTypes.func.isRequired,
}

export default Poster
