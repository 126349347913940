import React from 'react'
import PropTypes from 'prop-types'


const {floor} = Math

const formatTime = seconds => {
  seconds = floor(seconds)
  let minutes = floor(seconds / 60)
  seconds = seconds % 60
  if (minutes < 10) minutes = '0' + minutes
  if (seconds < 10) seconds = '0' + seconds
  return `${minutes}:${seconds}`
}

const Timer = ({
  title,
  value = 0,
}) => (
  <div role="timer" title={title} aria-label={title} className="embo-player__timer">
    {formatTime(value)}
  </div>
)
Timer.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
}
export default React.memo(Timer, (prevProps, nextProps) => {
  return nextProps.value === prevProps.value
    && nextProps.title === prevProps.title
})
