class Vsync {
  constructor() {
    // Tasks to run in the next frame.
    this._tasks = []
    // States for tasks in the next frame in the same order.
    this._states = []
    // Whether a new animation frame has been scheduled.
    this._scheduled = false
  }

  /**
   * Creates a function that will call {@link run} method.
   *
   * @param {!Object} task
   * @return {function()}
   */
  createTask(task) {
    return state => this.run(task, state)
  }

  /**
   * Runs vsync task: measure followed by mutate.
   * Returns a promise that will be resolved as soon as the task has been completed.
   *
   * If state is not provided, the value passed to the measure and mutate will be undefined.
   *
   * @param {Object} task
   * @param {Object} state
   * @return {!Promise}
   */
  run(task, state = {}) {
    return new Promise(resolve => {
      this._run({
        measure: state => task.measure(state),
        mutate: state => resolve(task.mutate(state)),
      }, state)
    })
  }

  /**
   * Runs the mutate operation via vsync.
   *
   * @param {function()} mutator
   * @return {!Promise}
   */
  mutate(mutator) {
    return new Promise(resolve => {
      this._run({
        mutate: () => resolve(mutator()),
      })
    })
  }

  /**
   * Runs the measure operation via vsync.
   *
   * @param {function():TYPE} measurer
   * @return {!Promise<TYPE>}
   */
  measure(measurer) {
    return new Promise(resolve => {
      this._run({
        measure: () => resolve(measurer()),
      })
    })
  }

  _run(task, state = {}) {
    this._tasks.push(task)
    this._states.push(state)
    this._schedule()
  }

  _schedule() {
    if (this._scheduled) return
    // Schedule actual animation frame and then run tasks.
    this._scheduled = true
    requestAnimationFrame(this._runScheduledTasks)
  }

  /**
   * Runs all scheduled tasks.
   * This is typically called in an RAF callback.
   * Tests may call this method to force execution of tasks without waiting.
   */
  _runScheduledTasks = () => {
    this._scheduled = false
    // TODO Avoid array allocation with a double buffer.
    const {_tasks, _states} = this
    const l = _tasks.length

    this._tasks = []
    this._states = []

    for (let i = 0; i < l; i++) {
      if (_tasks[i].measure) {
        // Allow measure tasks to pass state to mutations
        _states[i] = _tasks[i].measure(_states[i])
      }
    }
    for (let i = 0; i < l; i++) {
      if (_tasks[i].mutate) {
        _tasks[i].mutate(_states[i])
      }
    }
  }
}

const vsync = new Vsync()

export default vsync
