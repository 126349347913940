import SymfonyForm from './BaseSymfonyForm'
import vsync from 'embo/utils/vsync'
import dom from 'embo/utils/dom'
import {on} from 'embo/utils/events'
import animate from 'embo/utils/animate'
import icon from 'embo/ui/icon'


const ADD_PERIOD_BTN_TEMPLATE = `<button class="btn btn-success btn-sm">
    ${icon('plus')} Ajouter une période
</button>`


export default class ProposalForm extends SymfonyForm {
  constructor() {
    super('embo_proposal')

    this.periodCollectionForm = dom.qs('#embo_proposal_periods', this.form)
    this.periodTemplate = this.periodCollectionForm.dataset.prototype
    this.initializePeriodCollection()
  }

  initializePeriodCollection() {

    on(this.periodCollectionForm, 'click', '.embo-daterange-delete', ({event, target}) => {
      event.preventDefault()
      const periodForm = target.closest('.form-group')
      animate(periodForm, 'slideUp', {duration: 300}).then(() => {
        vsync.mutate(() => {
          periodForm.remove()
          this.updatePeriodIndexes()
        })
      })
    })

    const newPeriodContainer = dom.el('div', {
      html: ADD_PERIOD_BTN_TEMPLATE,
      'class': 'form-group',
    })
    const addPeriodBtn = dom.qs('button', newPeriodContainer)

    on(addPeriodBtn, 'click', e => {
      e.preventDefault()
      this.addPeriodForm(newPeriodContainer)
    })

    return vsync.mutate(() => {
      this.periodCollectionForm.appendChild(newPeriodContainer)
    })
  }

  addPeriodForm(container) {
    // Remplace '__name__' dans le HTML du prototype par un nombre basé sur
    // la longueur de la collection courante
    const index = this.periodCollectionForm.children.length
    const tpl = this.periodTemplate
      .replace(/__name__label__/g, `Période n° ${index}`)
      .replace(/__name__/g, index - 1)

    const newForm = dom.parse(tpl)[0]
    container.parentNode.insertBefore(newForm, container)
    animate(newForm, 'slideDown', {duration: 200})
  }

  updatePeriodIndexes() {
    dom.qsa('.form-group', this.periodCollectionForm).forEach((group, i) => {
      const label = dom.qs('label', group)
      // The add period button doesn't have a label
      if (!label) return
      label.textContent = `Période n° ${i + 1}`
      // update inputs
      // we have to use this selector since fields can be compound (not a real input element).
      dom.qsa('[id^="embo_proposal_periods_"]', group).forEach(el => {
        el.id = el.id.replace(/^embo_proposal_periods_\d+/, `embo_proposal_periods_${i}`)
        if (el.name) {
          el.name = el.name.replace(/\[periods\]\[\d+\]/, `[periods][${i}]`)
        }
      })
      // update error containers
      dom.qsa('.form-errors', group).forEach(el => {
        el.dataset.errorsFor = el.dataset.errorsFor.replace(/^embo_proposal_periods_\d+/, `embo_proposal_periods_${i}`)
      })
    })
  }
}
