const ESCAPE_RX = /[&<>"'`]/g
const ESCAPE_LOOKUP = {
  '&': '&amp;',
  '>': '&gt;',
  '<': '&lt;',
  '"': '&quot;',
  "'": '&#39;',
  '`': '&#96;',
}

const escaper = char => ESCAPE_LOOKUP[char]

export default function escapeHTML(str) {
  return str.replace(ESCAPE_RX, escaper)
}
