import React from 'react'
import PropTypes from 'prop-types'


const NextButton = ({onClick}) => (
  <button className="embo-player__next-btn" title="Next" onClick={onClick}>
    <svg width="36" height="36" viewBox="0 0 36 36">
      <path d="M16.2,12.5 L16.2,16.49 L9,12.5 L9,23.5 L16.2,19.50 L16.2,23.5 L24.5,19.1 L24.5,23.5 L27,23.5 L27,12.5 L24.5,12.5 L24.5,17.45 L16.2,12.5 Z"/>
    </svg>
  </button>
)
NextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}
export default React.memo(NextButton, () => true)
