const RGBA_RX = /^rgba?\(\s*(\d+),\s*(\d+),\s*(\d+)\s*(?:,\s*((?:0?\.\d+)|0|1))?\s*\)$/


export function fromRgbString(str) {
  const matches = str.match(RGBA_RX)
  if (!matches) {
    // transparent
    return [0, 0, 0, 0]
  }
  const rgba = matches.slice(1, 4).map(c => parseInt(c, 10))
  rgba.push(matches[4] === undefined ? 1 : parseFloat(matches[4]))
  return rgba
}

export function toHex(r, g, b) {
  return [r, g, b].reduce((hex, c) => hex + ('0' + c.toString(16)).slice(-2), '#')
}
