import cx from 'classnames'

import dom from 'embo/utils/dom'
import vsync from 'embo/utils/vsync'
import animate from 'embo/utils/animate'
import icon from 'embo/ui/icon'


function render(size = 'small', attrs, style) {
  const radius = size === 'big' ? 32 : 16
  const strokeWidth = size === 'big' ? 6 : 4
  const d = radius * 2

  const className = cx('spinner', {
    'spinner--big': size === 'big',
    'spinner--small': size === 'small',
  }, attrs.className)

  return dom.el('span', {
    ...attrs,
    className,
    html: `<svg width="${d}" height="${d}" viewBox="0 0 ${d} ${d}">
      <circle stroke-width="${strokeWidth}"
              cx="${radius}" cy="${radius}" r="${radius - strokeWidth / 2}"
              fill="none" stroke-linecap="round"
      />
    </svg>`,
  }, {
    ...style,
    display: 'none',
  })
}


export default class Spinner {
  constructor(size = 'small', attrs = {}, style = {}) {
    this._container = render(size, attrs, style)
    this._display = style.display || 'inline-flex'
  }

  get element() {
    return this._container
  }

  show() {
    return animate(this._container, 'fadeIn', {duration: 250, display: this._display})
  }

  hide() {
    return animate(this._container, 'fadeOut', {duration: 250})
  }
}


const setBtnState = (btn, state) => {
  ['primary', 'secondary', 'success', 'danger', 'warning'].forEach(name => {
    const method = state === name ? 'add' : 'remove'
    btn.classList[method](`btn-${name}`)
  })
}


export class SpinnerButton {
  constructor(el) {
    this.button = el
    // Add an internal wrapper because of FF bug
    // https://bugzilla.mozilla.org/show_bug.cgi?id=984869#c2
    this.container = dom.el('div', {
      className: 'btn-spinner__inner',
      html: el.innerHTML,
    })
    this.spinner = new Spinner('small')
    this.states = {
      normal: {
        className: el.className,
        icon: '',
        text: el.innerHTML,
      },
      success: {
        icon: `<span class="btn-spinner__indicator">${icon('done')}</span>`,
        text: '<span class="btn-spinner__msg">OK !</span>',
      },
      failure: {
        icon: `<span class="btn-spinner__indicator">${icon('warning')}</span>`,
        text: '<span class="btn-spinner__msg">KO ...</span>',
      },
    }
    vsync.mutate(() => {
      this.button.innerHTML = ''
      this.button.appendChild(this.container)
      this.button.classList.add('btn-spinner')
    })
  }

  reset() {
    return vsync.mutate(() => {
      this.button.className = this.states.normal.className
      this.container.innerHTML = this._getStateHtml('normal')
    })
  }

  spin() {
    return vsync.mutate(() => {
      const icn = this.button.querySelector('.btn-spinner__indicator')
      if (icn) icn.remove()
      setBtnState(this.button, 'primary')
      this.container.insertBefore(this.spinner.element, this.container.firstChild)
    }).then(() => this.spinner.show())
  }

  unspin() {
    return this.spinner.hide().then(() => {
      this.spinner.element.remove()
    })
  }

  success(message) {
    return this.unspin().then(() => vsync.mutate(() => {
      this.container.innerHTML = this._getStateHtml('success', message)
      setBtnState(this.button, 'success')
    }))
  }

  failure(message) {
    return this.unspin().then(() => {
      this.container.innerHTML = this._getStateHtml('failure', message)
      setBtnState(this.button, 'danger')
    })
  }

  _getStateHtml(state, message) {
    state = this.states[state]
    message = message ? message : state.text
    return `${state.icon} ${message}`
  }
}
