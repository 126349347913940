import PropTypes from 'prop-types'

export const ImageType = PropTypes.shape({
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  thumbnail: PropTypes.objectOf(PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
  })),
})

export const PosterType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  copyrightHolder: PropTypes.string,
  copyrightYear: PropTypes.string,
  image: ImageType,
})

export const ThumbnailType = PropTypes.shape({
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PosterType.isRequired,
})
