import {combineReducers} from 'redux'

import entities from './entities'
import player from './player'
import playlist from './playlist'
import minical from './minical'
import posters from './posters'


export default combineReducers({
  entities,
  player,
  playlist,
  minical,
  posters,
})
