function Entry(key, value) {
  this.key = key
  this.value = value
  this.prev = null
  this.next = null
  this.timestamp = Date.now()
}

export function CacheMiss() {}


export default class LRUCache {
  constructor(limit = 16, ttl = Infinity) {
    this._limit = limit
    this._ttl = ttl

    this._map = new Map()
    this._head = null
    this._tail = null
  }

  get size() {
    return this._map.size
  }

  has(key) {
    const entry = this._map.get(key)
    return entry && (Date.now() - entry.timestamp < this._ttl)
  }

  get(key) {
    if (!this.has(key)) {
      return new CacheMiss()
    }
    const {value} = this._map.get(key)
    this.delete(key)
    this._setHead(new Entry(key, value))

    return value
  }

  set(key, value) {
    const newEntry = new Entry(key, value)
    const oldEntry = this._map.get(key)
    if (oldEntry) {
      oldEntry.value = value
      this.delete(key)
    } else {
      if (this._map.size >= this._limit) {
        this._map.delete(this._tail.key)
        this._tail = this._tail.prev
        this._tail.next = null
      }
    }
    this._setHead(newEntry)

    return this
  }

  delete(key) {
    const entry = this._map.get(key)
    if (entry.prev !== null) {
      entry.prev.next = entry.next
    } else {
      this._head = entry.next
    }
    if (entry.next !== null) {
      entry.next.prev = entry.prev
    } else {
      this._tail = entry.prev
    }
    this._map.delete(key)

    return this
  }

  clear() {
    this._map.clear()
    this._head = this._tail = null

    return this
  }

  forEach(cb) {
    let entry = this._head
    while (entry) {
      cb(entry.value, entry.key, this)
      entry = entry.next
    }

    return this
  }

  // ========== Iterator protocol ========== //
  /*
  * keys() {
    let entry = this._head
    while (entry) {
      yield entry.key
      entry = entry.next
    }
  }

  * values() {
    let entry = this._head
    while (entry) {
      yield entry.value
      entry = entry.next
    }
  }

  * entries() {
    let entry = this._head
    while (entry) {
      yield entry.value
      entry = entry.next
    }
  }
  */

  _setHead(entry) {
    entry.next = this._head
    entry.prev = null
    if (this._head !== null) {
      this._head.prev = entry
    }
    this._head = entry
    if (this._tail === null) {
      this._tail = entry
    }
    this._map.set(entry.key, entry)
  }
}
