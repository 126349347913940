import StyleDeclaration from './StyleDeclaration'
import {ucfirst} from '../string/case'


const PREFIXES = ['Moz', 'Webkit', 'O', 'ms']

let domVendorPrefix

/**
 * Return a possibly vendor-prefixed version of the given style property name.
 *
 * @param {String} name
 * @param {Boolean} isSupportTest
 *
 * @returns {String|null}
 */
export default function getVendorPropertyName(name, isSupportTest = false) {
  if (name in StyleDeclaration) {
    return name
  }
  const upperName = ucfirst(name)
  let vendorProp

  if (domVendorPrefix) {
    vendorProp = domVendorPrefix + upperName
    if (vendorProp in StyleDeclaration) {
      return vendorProp
    }
  } else {
    for (let i = 0, l = PREFIXES.length; i < l; ++i) {
      vendorProp = PREFIXES[i] + upperName
      if (vendorProp in StyleDeclaration) {
        domVendorPrefix = PREFIXES[i]
        return vendorProp
      }
    }
  }

  // if support test, do no fallback to original prop name
  if (!isSupportTest) {
    return name
  }

  return null
}
