import {createSelector} from 'reselect'

import {
  selectPosters as selectPosterEntities,
} from './entities'

//
// Action creators
// --------------------------------------------------------------------------------------------------------------------

export const SET_CURRENT_POSTER = 'embo/posters/SET_CURRENT_POSTER'

export function setCurrentPoster(posterId) {
  return {
    type: SET_CURRENT_POSTER,
    payload: posterId,
    meta: {analytics: true},
  }
}


//
// Reducers
// --------------------------------------------------------------------------------------------------------------------

const initialState = {
  current: null,
}

export default function reducer(state = initialState, action = {}) {
  const {type, payload} = action
  switch (type) {

    case SET_CURRENT_POSTER:
      return {...state, current: payload}

    default:
      return state
  }
}


//
// Selectors
// --------------------------------------------------------------------------------------------------------------------

const selectCurrentPosterId = state => state.posters.current
const selectViewport = state => state.viewport

export const selectPosters = createSelector(
  [selectPosterEntities],
  posters => Object.values(posters).map(poster => {
    const {image: {width, height}} = poster
    return {...poster, width, height}
  }),
)

export const selectCurrentPoster = createSelector(
  [selectPosterEntities, selectCurrentPosterId],
  (posters, current) => posters[current],
)
