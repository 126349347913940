import {pathToRegexp} from 'path-to-regexp'

import decodeURLEncodedURIComponent from './decodeUrlEncodedUriComponent'


export default class Route {
  constructor(path, options = {}) {
    this.path = (path === '*') ? '(.*)' : path
    this.method = 'GET'
    this.keys = []
    this.regexp = pathToRegexp(
      this.path,
      this.keys,
      options,
    )
  }

  /**
   * Return route middleware with
   * the given callback `func()`.
   *
   * @param {Function} func
   * @return {Function}
   */
  middleware(func) {
    return (context, next) => {
      if (this.match(context.path, context.parameters)) {
        return func(context, next)
      }
      next()
    }
  }

  match(path, parameters) {
    const qsIndex = path.indexOf('?')
    const pathname = qsIndex > -1 ? path.slice(0, qsIndex) : path
    const matches = this.regexp.exec(decodeURIComponent(pathname))

    if (!matches) return false

    matches.slice(1).forEach((match, i) => {
      const key = this.keys[i]
      const value = decodeURLEncodedURIComponent(match)
      if (value !== undefined || !(parameters.hasOwnProperty(key.name))) {
        parameters[key.name] = value
      }
    })

    return true
  }
}
