//
// Action creators
// --------------------------------------------------------------------------------------------------------------------

const ADD_ENTITIES = 'embo/entities/ADD_ENTITIES'

export function addEntities(entities) {
  return {type: ADD_ENTITIES, payload: entities}
}

const SET_ENTITIES = 'embo/entities/SET_ENTITIES'

export function setEntities(entities) {
  return {type: SET_ENTITIES, payload: entities}
}


//
// Reducers
// --------------------------------------------------------------------------------------------------------------------

const initialState = {
  event: {},
  performer: {},
  track: {},
  poster: {},
}

function merge(state, entities) {
  return Object.keys(state).reduce((newState, key) => {
    newState[key] = {...state[key], ...entities[key]}
    return newState
  }, {})
}

function update(state, entities) {
  return Object.keys(state).reduce((newState, key) => {
    newState[key] = entities[key] ? entities[key] : state[key]
    return newState
  }, {})
}


export default function reducer(state = initialState, action = {}) {
  const {type, payload} = action
  switch (type) {
    case ADD_ENTITIES: {
      return merge(state, payload)
    }
    case SET_ENTITIES: {
      return update(state, payload)
    }
    default:
      return state
  }
}


//
// Selectors
// --------------------------------------------------------------------------------------------------------------------

export const selectEvents = state => state.entities.event
export const selectPerformers = state => state.entities.performer
export const selectTracks = state => state.entities.track
export const selectPosters = state => state.entities.poster
