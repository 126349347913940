export default class JustifiedLayout {
  constructor(images, options) {
    this.options = {
      maxHeight: 280,
      maxRatio: 1.1,
      margin: 8,
      ...options,
    }
    this.images = images.map(this.rescale)
  }

  render(width, height) {
    const {maxHeight, maxRatio, margin} = this.options
    const numImages = this.images.length
    const layout = []

    // total number of images appearing in all previous rows
    let numProcessed = 0
    // number of images appearing in the current row
    let numImagesInRow = 0
    // index of the first image in the current row
    let baseline = 0
    let y = 0
    let currentItem

    while (numProcessed < numImages) {
      // Count the number of images that are going to appear in the row,
      // along with their accumulated width until we reach the desired total width.
      numImagesInRow = 0
      // total width of images in this row, including margins
      let currentRowWidth = 0
      // calculate width of images and number of images to view in this row.
      while (currentRowWidth * maxRatio < width) {
        let image = this.images[baseline + (numImagesInRow++)]
        if (!image) {
          // we got past the last image, rewind and break
          numImagesInRow--
          break
        }
        currentRowWidth += image.width + margin
      }

      // Ratio of actual width of row to total width of images to be used.
      let ratio = width / currentRowWidth
      // new height is not original height * ratio
      let newHeight = Math.floor(maxHeight * ratio)
      // reset total width to be total width of processed images
      currentRowWidth = 0
      for (let i = 0; i < numImagesInRow; i++) {
        let image = this.images[baseline + i]
        currentItem = {
          data: image,
          x: currentRowWidth,
          y,
          // Calculate new width based on ratio
          width: Math.floor(image.width * ratio),
          height: newHeight,
        }
        layout.push(currentItem)
        // add to total width with margins
        currentRowWidth += currentItem.width + margin
        numProcessed++
      }
      // we need to cheat and adjust the width of the last cell in the row
      if (currentRowWidth < width) {
        let adjustment = width - currentRowWidth
        currentItem.width += adjustment
        currentRowWidth += adjustment
      }
      if (currentRowWidth > width) {
        let adjustment = currentRowWidth - width
        currentItem.width -= adjustment
        currentRowWidth -= adjustment
      }
      y += currentItem.height + margin
      baseline += numImagesInRow
    }

    return this.handleOrphans(layout, numImagesInRow)
  }

  rescale = image => {
    const {maxHeight} = this.options
    const {width, height} = image
    if (height !== maxHeight) {
      // scale image to match maxHeight
      image.width = Math.floor(width * maxHeight / height)
      image.height = maxHeight
    }
    return image
  }

  handleOrphans(layout, numImgInLastRow) {
    const {maxHeight, maxRatio, margin} = this.options
    const maxAllowedHeight = maxHeight * maxRatio

    const rowStart = layout.length - numImgInLastRow
    const lastRow = layout.slice(rowStart)
    const mustResize = lastRow.some(image => image.height > maxAllowedHeight)

    if (mustResize) {
      let x = 0
      lastRow.forEach(image => {
        let ratio = maxHeight / image.height
        image.height = maxHeight
        image.width = Math.floor(image.width * ratio)
        image.x = x
        x += image.width + margin
      })
    }

    return layout
  }
}
