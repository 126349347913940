export default function debounce(fn, delay) {
  let timeout = null
  return function debounced(...args) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      fn(...args)
    }, delay)
  }
}
