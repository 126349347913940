import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../common/Icon'


const errorMessage = error => {
  if (error instanceof MediaError) {
    switch (error.code) {
      case MediaError.MEDIA_ERR_ABORTED:
        return 'chargement interrompu.'
      case MediaError.MEDIA_ERR_NETWORK:
        return 'erreur réseau.'
      case MediaError.MEDIA_ERR_DECODE:
        return 'erreur de décodage.'
      case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
        return 'fichier inexistant ou format non supporté.'
    }
  } else if (error instanceof DOMException) {
    switch (error.code) {
      case DOMException.ABORT_ERR:
        return 'chargement interrompu.'
      case DOMException.NETWORK_ERR:
        return 'erreur réseau.'
      case DOMException.NOT_SUPPORTED_ERR:
        return 'fichier inexistant ou format non supporté.'
    }
  }
  return `[${error.code}] ${error.message}.`
}

const ErrorInfo = ({error}) => {
  const msg = `Erreur au chargement de la piste: ${errorMessage(error)}`
  return (
    <div className="embo-player__error-info" title={msg}>
      <span className="sr-only">{msg}</span>
      <Icon name="warning" width={18} height={18}/>
    </div>
  )
}
ErrorInfo.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.instanceOf(MediaError),
    PropTypes.instanceOf(DOMException), // in Chrome
  ]).isRequired,
}

export default React.memo(ErrorInfo)
