import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import EventDate from '../common/EventDate'
import {eventShape} from './propTypes'


const StatusMessage = ({status}) => {
  const className = 'embo-minical__event__status-msg'
  switch (status) {
    case 'canceled':
      return <div className={className}>☠ <span>ANNULÉ</span> ☠</div>
    case 'postponed':
      return <div className={className}>☢ <span>REPORTÉ</span> ☢</div>
    default:
      return null
  }
}
StatusMessage.propTypes = {
  status: PropTypes.string,
}

const Performer = ({id, name, status}) => {
  const canceled = status === 'canceled'
  const className = cx('embo-minical__performer', {
    'embo-minical__performer--is-canceled': canceled,
  })
  return (
    <li key={id} className={className} title={canceled ? 'Annulé !' : null}>
      <span>{name}</span>
      {canceled ? <span className="sr-only">{' (Annulé !) '}</span> : null}
    </li>
  )
}
Performer.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string,
}

const Event = ({event, style, onMount, onUnmount}) => {

  const container = useRef(null)

  useEffect(() => {
    onMount(event.id, container.current)
    return () => onUnmount(event.id)
  }, [event.id, onMount, onUnmount])

  const title = event.name ? <div className="embo-minical__title">{event.name}</div> : null
  const classes = cx('embo-minical__event', `embo-minical__event--is-${event.status}`)

  return (
    <li ref={container} className={classes} style={style}>
      <a href={event.status === 'canceled' ? null : event.url}>
        <div className="embo-minical__date">
          <EventDate start={event.startDate} end={event.endDate}/>
        </div>
        <StatusMessage status={event.status} />
        {title}
        <ul className="embo-minical__performer-list">
          {event.performer.map(({id, name, status}) => (
            <Performer key={id} id={id} name={name} status={status}/>
          ))}
        </ul>
      </a>
    </li>
  )
}
Event.propTypes = {
  event: eventShape.isRequired,
  style: PropTypes.object.isRequired,
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
}

export default Event
