import PropTypes from 'prop-types'

export const TrackType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  performer: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  event: PropTypes.shape({
    name: PropTypes.string,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    url: PropTypes.string.isRequired,
    description: PropTypes.string,
    performers: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    })),
  }).isRequired,
})

export const defaultTrack = {
  name: '',
  performer: {name: ''},
  event: {name: ''},
}
