import decodeURLEncodedURIComponent from './decodeUrlEncodedUriComponent'


/**
 * Initialize a new "request" `Context`
 * with the given `path` and optional initial `state`.
 *
 * @param {String} path
 * @param {Object} state
 * @api public
 */
export default class Context {
  constructor(path, state = {}, base = '') {
    this.init = false
    this._isPropagationStopped = false

    if ('/' === path[0] && 0 !== path.indexOf(base)) {
      path = base + path
    }
    const i = path.indexOf('?')

    this.canonicalPath = path
    this.path = path.replace(base, '') || '/'

    this.title = document.title
    this.state = {...state, path}
    this.querystring = ~i ? decodeURLEncodedURIComponent(path.slice(i + 1)) : ''
    this.pathname = decodeURLEncodedURIComponent(~i ? path.slice(0, i) : path)
    this.parameters = {}

    // fragment
    this.hash = ''
    if (!~this.path.indexOf('#')) return
    const parts = this.path.split('#')
    this.path = parts[0]
    this.hash = decodeURLEncodedURIComponent(parts[1]) || ''
    this.querystring = this.querystring.split('#')[0]
  }

  pushState() {
    window.history.pushState(
      this.state,
      this.title,
      this.canonicalPath,
    )
  }

  save() {
    window.history.replaceState(
      this.state,
      this.title,
      this.canonicalPath,
    )
  }

  stopPropagation() {
    this._isPropagationStopped = true
  }

  get isPropagationStopped() {
    return this._isPropagationStopped
  }
}
