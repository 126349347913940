import dom from 'embo/utils/dom'
import vsync from 'embo/utils/vsync'
import animate from 'embo/utils/animate'
import icon from 'embo/ui/icon'


export function showErrors(form, validation) {
  return clearErrors(form, false).then(() => insertErrorLists(form, validation))
}

function insertErrorLists(form, validation) {
  const lists = Object.keys(validation).map(id => {
    const errors = validation[id]
    const errorsContainer = errorContainerForField(form, id)
    const topLevel = id === form.name
    const list = createErrorList(errors, topLevel)

    return vsync.mutate(() => {
      list.style.display = 'none'
      errorsContainer.append(list)

      if (!topLevel) {
        const input = dom.qs(`#${id}`, form)
        input.setAttribute('aria-invalid', 'true')
        const group = input.closest('.form-group')
        group.classList.add('has-error')
      }
    }).then(() => list)
  })
  return Promise.all(lists).then(lists => animate(lists, 'slideDown', {duration: 250}))
}


export function createErrorList(errors, topLevel = false) {
  const ul = dom.el('ul', {
    'class': `form-errors__list ${topLevel ? 'form-errors__list--toplevel' : ''}`,
  })
  errors.forEach(err => {
    const li = dom.el('li', {
      html: `${icon('warning')} ${err}`,
    })
    ul.appendChild(li)
  })
  return ul
}


export function clearErrors(form, transition = true) {
  const alerts = dom.qsa('.form-errors__list', form)
  const cleanup = () => {
    alerts.forEach(alert => alert.remove())
    dom.qsa('.has-error', form).forEach(group => {
      dom.qsa('input', group).forEach(input => input.setAttribute('aria-invalid', 'false'))
      group.classList.remove('has-error')
    })
  }

  if (transition) {
    return animate(alerts, 'slideUp', {duration: 250}).then(() => vsync.mutate(cleanup))
  }
  return vsync.mutate(cleanup)
}


function errorContainerForField(form, id) {
  const container = dom.qs(`.form-errors[data-errors-for="${id}"]`, form)
  if (!container) {
    throw new Error(`No error container for field "${id}". Please fix your template !`)
  }
  return container
}
