import buildDom from './buildDom'

/**
 * ChildNode polyfill
 * specifications: https://developer.mozilla.org/en-US/docs/Web/API/ChildNode
 */
const ChildNodePrototype = {
  before(...nodes) {
    if (this.parentNode) {
      this.parentNode.insertBefore(buildDom(...nodes), this)
    }
  },
  after(...nodes) {
    if (this.parentNode) {
      this.parentNode.insertBefore(buildDom(...nodes), this.nextSibling)
    }
  },
  replaceWith(...nodes) {
    if (this.parentNode) {
      this.parentNode.replaceChild(buildDom(...nodes), this)
    }
  },
  remove() {
    if (this.parentNode) {
      this.parentNode.removeChild(this)
    }
  },
}

const implementors = ['Element', 'DocumentType', 'CharacterData']

implementors.forEach(klass => {
  Object.keys(ChildNodePrototype).forEach(method => {
    if (!window[klass] || window[klass].prototype[method]) return
    window[klass].prototype[method] = ChildNodePrototype[method]
  })
})
