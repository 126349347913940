import React from 'react'
import {Provider} from 'react-redux'

import Player from './Player'


const PlayerWrapper = ({store}) => (
  <Provider store={store}>
    <Player/>
  </Provider>
)
export default PlayerWrapper
