import React, {useCallback, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {useDrag} from 'react-use-gesture'
import cx from 'classnames'

import {TrackType} from './propTypes'
import ErrorInfo from './ErrorInfo'
import TrackInfoButton from './TrackInfoButton'
import Icon from '../common/Icon'


function zeroPad(n) {
  return n < 10 ? `0${n}` : n
}


const PlaylistItem = ({
  track,
  position,
  isCurrent,
  isDragged,
  onMount,
  onUnmount,
  onSelect,
  onRemove,
  onMove,
  onDragStart,
  onDragMove,
  onDragEnd,
  style,
  onTrackInfoRequest,
}) => {
  const [isNew, setIsNew] = useState(true)
  const container = useRef(null)

  useEffect(() => {
    onMount(track.id, container.current)
    const isNewTimeout = setTimeout(() => setIsNew(false), 8000)
    return () => {
      onUnmount(track.id)
      clearTimeout(isNewTimeout)
    }
  }, [])

  const handleTrackSelected = useCallback(
    () => onSelect(track.id),
    [track.id, onSelect]
  )
  const handleTrackInfoClicked = useCallback(
    () => onTrackInfoRequest(track.id, container.current),
    [track.id, onTrackInfoRequest]
  )
  const handleRemoveClicked = useCallback(
    () => onRemove(track.id),
    [track.id, onRemove]
  )

  const bindDrag = useDrag(({xy: [, y], first, last}) => {
    if (first) {
      onDragStart(track.id, y)
    } else if (last) {
      onDragEnd(track.id, y)
    } else {
      onDragMove(track.id, y)
    }
  })

  const handleDragHandleKey = useCallback(
    event => {
      switch (event.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
          onMove(track.id, position - 1)
          event.preventDefault()
          return
        case 'ArrowDown':
        case 'ArrowRight':
          onMove(track.id, position + 1)
          event.preventDefault()
          return
      }
    },
    [track.id, position, onMove]
  )

  const classes = cx({
    'embo-player__playlist__track': true,
    'embo-player__playlist__track--is-current': isCurrent,
    'embo-player__playlist__track--is-dragged': isDragged,
    'embo-player__playlist__track--is-new': isNew,
    'embo-player__playlist__track--has-error': track.error,
  })

  return (
    <div ref={container} className={classes} style={style}>
      <button className="embo-player__playlist__track__handle"
        onKeyDown={handleDragHandleKey}
        {...bindDrag()}
      >
        <Icon name="reorder" width={18} height={18}/>
      </button>
      <TrackInfoButton onClick={handleTrackInfoClicked}/>
      <div className="embo-player__playlist__track__title" onClick={handleTrackSelected}>
        {`${zeroPad(position + 1)}. ${track.performer.name} - ${track.name}`}
      </div>
      {track.error && <ErrorInfo error={track.error}/>}
      <button title='Remove' onClick={handleRemoveClicked}>
        <span className="sr-only">Remove</span>
        <Icon name="close" width={18} height={18}/>
      </button>
    </div>
  )
}
PlaylistItem.propTypes = {
  track: TrackType.isRequired,
  position: PropTypes.number.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  isDragged: PropTypes.bool.isRequired,
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onDragMove: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
  onTrackInfoRequest: PropTypes.func.isRequired,
}
export default PlaylistItem
