import React, {useCallback, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'

import usePrevious from 'embo/components/hooks/usePrevious'

(() => {
  // HTMLMediaElement.prototype.play() returns a Promise in modern browsers
  // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
  const audio = document.createElement('audio')
  // Mute audio element to prevent autoplay warning
  audio.muted = true
  const playPromise = audio.play()
  if (!playPromise) {
    const play = HTMLMediaElement.prototype.play
    HTMLMediaElement.prototype.play = function() {
      return new Promise((resolve, reject) => {
        try {
          play.apply(this, arguments)
          resolve()
        } catch (err) {
          reject(err)
        }
      })
    }
  } else {
    playPromise.catch(() => {})
  }
})()

const Audio = ({
  src,
  playing,
  currentTime,
  volume,
  onLoad,
  onPlay,
  onPause,
  onEnd,
  onVolume,
  onDuration,
  onTimer,
  onError,
}) => {
  const element = useRef(null)
  const previousSrc = usePrevious(src)
  const wasPlaying = usePrevious(playing)

  const load = useCallback(() => {
    try {
      // Certain types of errors are not catch by the audio element ?
      element.current.load()
    } catch (err) {
      onError(err)
    }
  }, [onError])
  const play = useCallback(() => {
    element.current.play().catch(err => onError(err))
  }, [onError])

  useEffect(() => {
    if (!src) return
    if (src !== previousSrc) {
      load()
      if (playing) play()
    } else if (playing !== wasPlaying) {
      if (playing) play()
      else element.current.pause()
    }
  }, [src, previousSrc, playing, wasPlaying, load, play])
  useEffect(() => {
    element.current.volume = volume
  }, [volume])
  useEffect(() => {
    element.current.currentTime = currentTime
  }, [currentTime])

  const handleVolumeChange = useCallback(({target}) => onVolume(target.volume), [onVolume])
  const handleDurationChange = useCallback(({target}) => onDuration(target.duration), [onDuration])
  const handleTimeUpdate = useCallback(({target}) => onTimer(target.currentTime), [onTimer])
  const handleError = useCallback(({target}) => onError(target.error), [onError])

  return (
    <audio ref={element}
      src={src}
      onPlay={onPlay}
      onPause={onPause}
      onEnded={onEnd}
      onVolumeChange={handleVolumeChange}
      onError={handleError}
      onCanPlayThrough={onLoad}
      onDurationChange={handleDurationChange}
      onTimeUpdate={handleTimeUpdate}
    />
  )
}
Audio.propTypes = {
  src: PropTypes.string,
  playing: PropTypes.bool,
  currentTime: PropTypes.number,
  volume: PropTypes.number,
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onEnd: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  onVolume: PropTypes.func.isRequired,
  onDuration: PropTypes.func.isRequired,
  onTimer: PropTypes.func.isRequired,
}

export default Audio
