import React, {useCallback, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {useDrag} from 'react-use-gesture'

import vsync from 'embo/utils/vsync'

import VolumeIcon from '../common/VolumeIcon'


const {min, max, round} = Math

const computeVolumeFromCoords = (mouseY, trackCoords) => {
  const ratio = 1 - ((mouseY - trackCoords.top) / trackCoords.height)
  return max(0, min(1, ratio))
}


const Volume = ({
  volume,
  onChange,
}) => {

  const [expanded, setExpanded] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  const sliderTrack = useRef(null)
  const bindSliderDrag = useDrag(({xy, first, last, memo}) => {
    if (first) {
      memo = sliderTrack.current.getBoundingClientRect()
      setIsDragging(true)
    } else if (last) {
      setIsDragging(false)
    }
    const volume = computeVolumeFromCoords(xy[1], memo)
    onChange(volume)

    return memo
  })

  const mutedVolume = useRef(volume)
  const toggleMute = useCallback(() => {
    if (volume) {
      mutedVolume.current = volume
      onChange(0)
    } else {
      const value = mutedVolume.current || 1
      onChange(value)
    }
  }, [volume, onChange])

  const handleButtonMouseEnter = useCallback(() => setExpanded(true), [])
  const handleButtonMouseLeave = useCallback(() => setExpanded(false), [])
  const handleButtonClick = useCallback(
    () => toggleMute(),
    [toggleMute]
  )
  const handleButtonKeyDown = useCallback(
    event => {
      let handled = true

      switch (event.key) {
        case 'ArrowDown':
        case 'ArrowLeft': {
          // decrement by 0.1
          const value = max(0, volume - 0.1)
          onChange(value)
          break
        }
        case 'ArrowUp':
        case 'ArrowRight': {
          // increment by 0.1
          const value = min(1, volume + 0.1)
          onChange(value)
          break
        }
        case ' ':
        case 'Spacebar':
          toggleMute()
          break
        default:
          handled = false
          break
      }

      // Suppress "double action" if event handled
      if (handled) event.preventDefault()
    },
    [volume, onChange, toggleMute]
  )
  const handleSliderClick = useCallback(
    ({clientY}) => {
      vsync.run({
        measure: () => sliderTrack.current.getBoundingClientRect(),
        mutate: coords => {
          const volume = computeVolumeFromCoords(clientY, coords)
          onChange(volume)
        },
      })
    },
    [onChange]
  )
  const handleSliderClickCapture = useCallback(
    event => {
      if (isDragging) event.stopPropagation()
    },
    [isDragging]
  )

  const classes = cx('embo-player__volume', {
    // do not close the popup while dragging
    'embo-player__volume--is-expanded': expanded || isDragging,
  })
  const handleClasses = cx('embo-player__volume-slider__handle', {
    'embo-player__volume-slider__handle--is-dragging': isDragging,
  })

  return (
    <div className={classes}
      onMouseEnter={handleButtonMouseEnter}
      onMouseLeave={handleButtonMouseLeave}
    >
      <button className="embo-player__volume-btn" title={`Volume (${round(volume * 100)}%)`}
        onClick={handleButtonClick}
        onKeyDown={handleButtonKeyDown}
      >
        <VolumeIcon bars={volume > 0.5 ? 2 : volume > 0 ? 1 : 0} width={36} height={36}/>
      </button>
      <div className="embo-player__volume-slider"
        onClick={handleSliderClick}
        onClickCapture={handleSliderClickCapture}
        role="slider" aria-valuemin="0" aria-valuemax="1" aria-valuenow={volume}
      >
        <div ref={sliderTrack} className="embo-player__volume-slider__bg">
          <div className="embo-player__volume-slider__progress"
            style={{transform: `scaleY(${volume})`}}
          />
          <div {...bindSliderDrag()} className={handleClasses}
            style={{bottom: `${volume * 100}%`}}
          />
        </div>
      </div>
    </div>
  )
}
Volume.propTypes = {
  volume: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}
export default React.memo(Volume)
