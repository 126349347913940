import React from 'react'
import PropTypes from 'prop-types'
import {useTransition, animated, config as SpringPresets} from 'react-spring'

import Icon from '../common/Icon'
import EventDate from '../common/EventDate'
import {TrackType} from './propTypes'


const EventInfo = ({event}) => {
  let performers = event.performers.map(perf => perf.name).join(' / ')
  if (!event.name) {
    performers = <a href={event.url}>{performers}</a>
  }
  return (
    <div className="embo-player__info-panel__slide__event">
      {event.image && (
        <div className="embo-player__info-panel__slide__event__image">
          {event.image}
        </div>
      )}
      <div className="embo-player__info-panel__slide__event__body">
        {event.name && (
          <div className="embo-player__info-panel__slide__event__title">
            <a href={event.url}>{event.name}</a>
          </div>
        )}
        <EventDate start={event.startDate} end={event.endDate}/>
        <div className="embo-player__info-panel__slide__event__performers">
          {performers}
        </div>
        {event.description && (
          <div className="embo-player__info-panel__slide__event__description">
            {event.description}
          </div>
        )}
      </div>
    </div>
  )
}
EventInfo.propTypes = {
  event: PropTypes.object.isRequired,
}


const TrackInfoPanel = ({
  visible,
  track,
  onNext,
  onPrevious,
  onClose,
}) => {

  const transitions = useTransition(
    track ? [track] : [],
    track => track.id,
    {
      from: {transform: 'translateY(0%)'},
      enter: {transform: 'translateY(-100%)'},
      leave: {transform: 'translateY(0%)'},
      config: SpringPresets.stiff,
    }
  )

  return transitions.map(({item, key, props}) => {
    const {name, event, performer} = item
    return (
      <animated.div key={key} style={props} className="embo-player__info-panel__slide">
        <header className="embo-player__info-panel__slide__header">
          <div className="embo-player__info-panel__slide__title">
            <span className="sr-only">Informations sur la piste:</span>
            {performer.name} - {name}
          </div>
          <div className="embo-player__info-panel__slide__nav">
            <button onClick={onPrevious}>
              <span className="sr-only">Précédente</span>
              <Icon name="chevron-left" width={24} height={24}/>
            </button>
            <button onClick={onNext}>
              <span className="sr-only">Suivante</span>
              <Icon name="chevron-right" width={24} height={24}/>
            </button>
            <button onClick={onClose}>
              <span className="sr-only">Fermer</span>
              <Icon name="close" width={24} height={24}/>
            </button>
          </div>
        </header>
        <div className="embo-player__info-panel__slide__body">
          <EventInfo event={event}/>
        </div>
      </animated.div>
    )
  })
}
TrackInfoPanel.propTypes = {
  visible: PropTypes.bool,
  track: TrackType,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(TrackInfoPanel, (prevProps, nextProps) => {
  return nextProps.visible === prevProps.visible
    && nextProps.track === prevProps.track
})
