import {createStore, applyMiddleware} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'

import rootReducer from '../state/reducers'
import middlewares from './middleware'


const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
})

const enhancer = composeEnhancers(applyMiddleware(...middlewares))

function configureStore(initialState) {
  const store = createStore(rootReducer, initialState, enhancer)
  if (process.env.NODE_ENV !== 'production') {
    // Hot reload reducers (requires Webpack HMR to be enabled)
    if (module.hot) {
      module.hot.accept('../state/reducers', () => store.replaceReducer(rootReducer))
    }
  }

  return store
}

const store = configureStore()
export default store
