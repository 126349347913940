import platform from '../platform'
import Observable from '../Observable'
import {layoutRect} from '../dom/layoutRect'


const {round} = Math


export default class NativeWindowBinding {
  constructor(win) {
    this.win = win
    this._scrollingElement = this._getScrollingElement()

    this._scrollObservable = new Observable()
    this._resizeObservable = new Observable()

    this.win.addEventListener('resize', this._handleResize)
    this.win.addEventListener('scroll', this._handleScroll)
  }

  cleanup() {
    this._scrollObservable.clear()
    this._resizeObservable.clear()
    this.win.removeEventListener('resize', this._handleResize)
    this.win.removeEventListener('scroll', this._handleScroll)
  }

  get scrollingElement() {
    return this._scrollingElement
  }

  getSize() {
    // documentElement.clientHeight is buggy on iOS Safari and thus cannot be used.
    // But when the values are undefined, fallback to documentElement.clientHeight.
    if (platform.isIos() && !platform.isChrome()) {
      const width = this.win.innerWidth
      const height = this.win.innerHeight
      if (width && height) {
        return {width, height}
      }
    }
    const el = this.win.document.documentElement
    return {
      width: el.clientWidth,
      height: el.clientHeight,
    }
  }

  getScrollTop() {
    return this._scrollingElement.scrollTop || this.win.pageYOffset
  }

  getScrollLeft() {
    return this._scrollingElement.scrollLeft || this.win.pageXOffset
  }

  getScrollWidth() {
    return this._scrollingElement.scrollWidth
  }

  getScrollHeight() {
    return this._scrollingElement.scrollHeight
  }

  getLayoutRect(el) {
    const scrollTop = this.getScrollTop()
    const scrollLeft = this.getScrollLeft()
    const b = el.getBoundingClientRect()
    return layoutRect(
      round(b.left + scrollLeft),
      round(b.top + scrollTop),
      round(b.width),
      round(b.height),
    )
  }

  setScrollTop(top) {
    this._getScrollingElement().scrollTop = top
  }

  setScrollLeft(left) {
    this._getScrollingElement().scrollLeft = left
  }

  scrollTo(x = 0, y = 0) {
    this.win.scrollTo(x, y)
  }

  onScroll(callback) {
    this._scrollObservable.add(callback)
  }

  onResize(callback) {
    this._resizeObservable.add(callback)
  }

  _handleResize = event => this._resizeObservable.fire()

  _handleScroll = event => this._scrollObservable.fire()

  _getScrollingElement() {
    const {document} = this.win
    if (document.scrollingElement) {
      return document.scrollingElement
    }
    if (document.body) {
      return document.body
    }
    return document.documentElement
  }
}
