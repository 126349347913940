if (typeof window.ga !== 'function') {
  console.warn('Analytics disabled')
  if (process.env.NODE_ENV !== 'production') {
    window.ga = (...args) => console.log('ANALYTICS', ...args)
  } else {
    window.ga = () => {}
  }
}
if (typeof navigator.sendBeacon === 'function') {
  window.ga('set', 'transport', 'beacon')
}

export function trackPageView(page, title) {
  window.ga('set', {page, title})
  window.ga('send', 'pageview')
}

export function trackException(err, fatal = false) {
  const msg = `${err.constructor.name} [${err.code}]: ${err.toString()}`
  window.ga('send', 'exception', {
    exDescription: msg,
    exFatal: fatal,
  })
}

export function trackSocialAction(socialNetwork, socialAction, socialTarget) {
  window.ga('send', 'social', {socialNetwork, socialAction, socialTarget})
}

export function trackOutboundLink(link) {
  window.ga('send', 'event', {
    eventCategory: 'Outbound Link',
    eventAction: 'click',
    eventLabel: link.href,
    // force transport to beacon, since we do not want to wait for response
    transport: 'beacon',
  })
}

export function trackEvent(eventCategory, eventAction, eventLabel) {
  window.ga('send', 'event', {eventCategory, eventAction, eventLabel})
}
