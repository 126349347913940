import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Icon from '../common/Icon'

const PlaylistButton = ({
  open = false,
  onClick,
}) => {
  const title = `${open ? 'Hide' : 'Show'} playlist`
  const classes = cx('embo-player__playlist-btn', {
    'embo-player__playlist-btn--open': open,
  })

  return (
    <button className={classes} title={title} onClick={onClick}>
      <Icon name="menu" width={32} height={32}/>
    </button>
  )
}
PlaylistButton.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}
export default React.memo(PlaylistButton, (prevProps, nextProps) => {
  return nextProps.open === prevProps.open
})
