import React from 'react'
import {render} from 'react-dom'

import store from 'embo/store'
import {setEntities} from 'embo/state/entities'
import {loadPosters} from 'embo/api/dom'

import PosterGallery from 'embo/components/posters/App'

import {findComponentNodes} from './react-components'


export default function mountPosterGallery() {
  const nodes = findComponentNodes('Posters')
  if (!nodes.length) {
    throw new Error('Could not find node for Posters component.')
  }
  const node = nodes[0]
  const rect = node.getBoundingClientRect()
  const top = rect.top + window.pageYOffset

  return loadPosters().then(data => {
    store.dispatch(setEntities(data.entities))
    render(
      <PosterGallery store={store} initialWidth={rect.width}/>,
      node,
    )
  })
}
