import dom from 'embo/utils/dom'

const TEXTFIELDS_SELECTOR = '.embo-input input, .embo-input textarea'


function checkInputValue(input) {
  const value = input.value.trim()
  const field = input.closest('.embo-input')
  if (value) {
    field.classList.add('embo-input--filled')
  } else {
    field.classList.remove('embo-input--filled')
  }
}


function onFocusBlur(event) {
  const {target} = event
  //TODO: check input types link number, date, etc...
  if (!target.matches(TEXTFIELDS_SELECTOR)) return
  checkInputValue(target)
}

function onChange(event) {
  const {target} = event
  if (!target.matches('.embo-input select:not([multiple]')) return
  checkInputValue(target)

}

export default function init() {
  dom.qsa('.embo-input__field').forEach(input => {
    checkInputValue(input)
  })
  // these events do not bubble...
  document.body.addEventListener('focus', onFocusBlur, true)
  document.body.addEventListener('blur', onFocusBlur, true)
  document.body.addEventListener('change', onChange, true)
}
