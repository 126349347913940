import React, {useState, useRef, useCallback} from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Spinner from '../common/spinner/Pentagram'
import {PosterType} from './propTypes'


const THUMBNAIL_FORMATS = [
  'posters_xxs',
  'posters_xs',
  'posters_sm',
]

const selectFormats = (thumbnails, formats = []) => {
  if (!formats.length) {
    return Object.keys(thumbnails).map(format => thumbnails[format])
  }
  return formats.map(format => {
    if (!thumbnails[format]) {
      throw new Error(`Format "${format}" not found in ${Object.keys(thumbnails)}`)
    }
    return thumbnails[format]
  })
}

const getSrcSet = thumbnails => selectFormats(thumbnails, THUMBNAIL_FORMATS)
  .map(({url, width}) => `${url} ${width}w`)
  .join(', ')


const Thumbnail = ({
  poster,
  visible,
  style,
  onClick,
}) => {

  const {id, name, author, image} = poster

  const [loaded, setLoaded] = useState(false)
  const container = useRef(null)

  const handleLoaded = useCallback(() => setLoaded(true), [])
  const handleClicked = useCallback(
    () => onClick(id, container.current),
    [id, container, onClick]
  )
  const handleKeyDown = useCallback(event => {
    if (['Enter', ' ', 'Spacebar'].includes(event.key)) {
      onClick(id, container.current)
    }
  },
  [id, container, onClick])

  const title = `«${name}» par ${author}`
  const classes = cx('embo-posters__thumbnail', {
    'embo-posters__thumbnail--is-loaded': loaded,
  })

  return (
    <div ref={container} className={classes} style={style} title={title} tabIndex="0"
      onClick={handleClicked}
      onKeyDown={handleKeyDown}
    >
      {(visible || loaded) && (
        <img
          alt={title}
          srcSet={getSrcSet(image.thumbnail)}
          sizes={`${style.width}px`}
          style={{width: style.width, height: 'auto'}}
          onLoad={handleLoaded}
        />
      )}
      {(visible && !loaded) && (
        <div className="embo-posters__thumbnail__spinner" aria-label="Image is loading">
          <Spinner size="md"/>
        </div>
      )}
    </div>
  )
}
Thumbnail.propTypes = {
  poster: PosterType.isRequired,
  visible: PropTypes.bool.isRequired,
  style: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    // Not required since it can be prefixed
    transform: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default React.memo(Thumbnail)
