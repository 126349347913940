import React, {useCallback, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {animated, useTransition, config as SpringPresets} from 'react-spring'

import {scrollIntoView} from 'embo/utils/animate'
import vsync from 'embo/utils/vsync'
import viewport from 'embo/utils/viewport'

import useInstance from 'embo/components/hooks/useInstance'
import PlaylistItem from './PlaylistItem'
import {TrackType} from './propTypes'
import {domRect} from 'embo/utils/dom/layoutRect'


const AnimatedItem = animated(PlaylistItem)
const TRANSITION_CONFIG = {
  from: {opacity: 0, transform: 'translate3d(0px, 0px, 0px)'},
  enter: {opacity: 1},
  leave: {opacity: 0},
  update: ({transform, opacity}) => ({transform, opacity}),
  config: SpringPresets.stiff,
}
const getTransitionKey = item => item.key
const getTransitionItems = (tracks, layouts, itemMargin, draggingItemId, dragY) => {
  const numTracks = tracks.length
  let currentHeight = 0
  let totalHeight = 0

  const items = tracks.map((track, i) => {
    const trackId = track.id
    const layout = layouts[trackId]
    const isDragged = draggingItemId === trackId

    const y = isDragged ? dragY - layout.height / 2 : layout ? currentHeight : 0
    const z = isDragged ? 20 : 0

    const style = {
      transform: `translate3d(0px, ${y}px, ${z}px)`,
      zIndex: isDragged ? 1 : 0,
      opacity: 1,
    }

    if (layout) {
      currentHeight += layout.height
      if (i < numTracks - 1) {
        currentHeight += itemMargin
      }
      totalHeight = currentHeight
    }

    return {key: trackId, track, isDragged, ...style}
  })

  return {items, totalHeight}
}

const Playlist = ({
  visible,
  tracks,
  current = null,
  presented = null,
  itemMargin = 8,
  onItemSelected,
  onItemMoved,
  onItemRemoved,
  onTrackInfoRequest,
  onTrackPresented,
}) => {
  const numTracks = tracks.length

  const scrollview = useRef(null)
  const tracklist = useRef(null)

  const [draggingItemId, setDraggingItemId] = useState(null)
  const [dragY, setDragY] = useState(0)
  const [layouts, setLayouts] = useState({})

  const self = useInstance(() => {
    return {
      nodes: {},
      dimensions: {},
      updateDimensions() {
        this.dimensions = {
          tracklist: domRect(tracklist.current),
          scrollview: domRect(scrollview.current),
        }
      },
      getDragDistanceFromTracklistTop(absoluteY, refresh) {
        if (refresh) this.updateDimensions()
        return absoluteY - this.dimensions.tracklist.top
      },
      adjustScroll(absoluteY, relativeY) {
        const {top, bottom, scrollTop, scrollHeight} = this.dimensions.scrollview
        let scrollAmount = 10
        let nextScroll = null

        if (absoluteY > bottom - scrollAmount) {
          // we're approaching the bottom of scrollview's clientRect
          // let's see if we need to scroll down
          if (scrollTop + scrollAmount >= scrollHeight) {
            scrollAmount = scrollHeight - scrollTop
          }
          if (scrollAmount > 0) {
            relativeY += scrollAmount
            nextScroll = scrollTop + scrollAmount
          }
        } else if (scrollTop && absoluteY < top + scrollAmount) {
          if (scrollTop - scrollAmount <= 0) {
            scrollAmount = scrollTop
          }
          if (scrollAmount > 0) {
            relativeY -= scrollAmount
            nextScroll = scrollTop - scrollAmount
          }
        }

        if (nextScroll !== null) {
          scrollview.current.scrollTop = nextScroll
        }

        return relativeY
      },
    }
  })

  // present requested track to user
  useEffect(() => {
    if (presented) {
      const node = self.nodes[presented]
      if (visible && node) {
        scrollIntoView(node, scrollview.current).then(() => onTrackPresented())
      }
    }
  }, [onTrackPresented, presented, self, visible])
  // observe viewport changes
  useEffect(() => {
    return viewport.onChange(({relayoutAll}) => {
      if (!relayoutAll) return
      vsync.run({
        measure: () => {
          return Object.keys(self.nodes).reduce((layouts, trackId) => {
            const node = self.nodes[trackId]
            layouts[trackId] = {width: node.offsetWidth, height: node.offsetHeight}
            return layouts
          }, {})
        },
        mutate: layouts => setLayouts(layouts),
      })
    })
  }, [self])

  const handleItemMount = useCallback((trackId, node) => {
    self.nodes[trackId] = node
    vsync.run({
      measure: () => ({width: node.offsetWidth, height: node.offsetHeight}),
      mutate: layout => setLayouts(prev => ({...prev, [trackId]: layout})),
    })
  }, [self.nodes])

  const handleItemUnmount = useCallback((trackId) => {
    delete self.nodes[trackId]
  }, [self.nodes])

  const handleItemDragStart = useCallback((trackId, y) => {
    const dragY = self.getDragDistanceFromTracklistTop(y, true)
    setDraggingItemId(trackId)
    setDragY(dragY)
  }, [self])
  const handleItemDragMove = useCallback((trackId, y) => {
    if (!draggingItemId) return
    vsync.run({
      measure: () => {
        //FIXME: cache this !
        const relativeY = self.getDragDistanceFromTracklistTop(y, true)
        // Search through items to find where to insert
        let currentHeight = 0
        // Use the last track's key by default.
        // If track position is not found, it means it's outside the last track.
        let hoveredRowPos = numTracks - 1
        let hoveredRowId = tracks[hoveredRowPos]
        tracks.some((track, i) => {
          const layout = layouts[track.id]
          currentHeight += layout.height + itemMargin
          if (relativeY < currentHeight) {
            hoveredRowId = track.id
            hoveredRowPos = i
            return true
          }
        })
        return {relativeY, hoveredRowId, hoveredRowPos}
      },
      mutate: ({relativeY, hoveredRowId, hoveredRowPos}) => {
        // Swap tracks if necessary
        if (hoveredRowId !== draggingItemId) {
          onItemMoved(draggingItemId, hoveredRowPos)
        }
        relativeY = self.adjustScroll(y, relativeY)
        setDragY(relativeY)
      },
    })
  }, [draggingItemId, itemMargin, layouts, numTracks, onItemMoved, self, tracks])
  const handleItemDragEnd = useCallback(() => {
    vsync.mutate(() => {
      setDragY(0)
      setDraggingItemId(null)
    })
  }, [])

  const classes = cx('embo-player__playlist', {
    'embo-player__playlist--is-visible': visible,
    'embo-player__playlist--is-dragging': draggingItemId !== null,
  }, [])

  const {items, totalHeight} = getTransitionItems(tracks, layouts, itemMargin, draggingItemId, dragY)
  const transitions = useTransition(items, getTransitionKey, TRANSITION_CONFIG)

  return (
    <div className={classes}>
      <div className="embo-player__playlist__scrollview" ref={scrollview}>
        <div className="embo-player__playlist__tracklist" ref={tracklist}>
          <div style={{height: totalHeight}}>
            {transitions.map(({item: {track, isDragged}, key, props}, i) => (
              <AnimatedItem key={key}
                track={track}
                position={i}
                isCurrent={track.id === current}
                isDragged={isDragged}
                onTrackInfoRequest={onTrackInfoRequest}
                onMount={handleItemMount}
                onUnmount={handleItemUnmount}
                onSelect={onItemSelected}
                onRemove={onItemRemoved}
                onMove={onItemMoved}
                onDragStart={handleItemDragStart}
                onDragMove={handleItemDragMove}
                onDragEnd={handleItemDragEnd}
                style={{
                  position: 'absolute',
                  ...props,
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
Playlist.propTypes = {
  visible: PropTypes.bool.isRequired,
  tracks: PropTypes.array.isRequired,
  current: PropTypes.string,
  presented: PropTypes.string,
  onItemSelected: PropTypes.func.isRequired,
  onItemMoved: PropTypes.func.isRequired,
  onItemRemoved: PropTypes.func.isRequired,
  onTrackInfoRequest: PropTypes.func.isRequired,
  onTrackPresented: PropTypes.func.isRequired,
  itemMargin: PropTypes.number,
}
export default Playlist
