import dom from 'embo/utils/dom'
import {on, one, getKey} from 'embo/utils/events'
import animate from 'embo/utils/animate'
import * as color from 'embo/utils/color'

import constants from 'embo/constants'


function parseBackgroundColor(el) {
  const {backgroundColor} = getComputedStyle(el)
  const [r, g, b, a] = color.fromRgbString(backgroundColor)
  return {
    backgroundColorRed: r,
    backgroundColorGreen: g,
    backgroundColorBlue: b,
    backgroundColorAlpha: a,
  }
}


function joinBackgroundColor(color) {
  const channels = Object.keys(color)
  const output = channels.map(k => color[k]).join(',')
  const alpha = channels.length === 4 ? 'a' : ''

  return `rgb${alpha}(${output})`
}

//
// Prevent scrolling during transitions
// --------------------------------------------------------------------------------------------------------------------

const preventScroll = e => e.preventDefault()
const scrollTriggers = ['wheel', 'mousewheel', 'touchmove', 'keydown']

function lockScroll() {
  unlockScroll()
  scrollTriggers.forEach(e => window.addEventListener(e, preventScroll))
}

function unlockScroll() {
  scrollTriggers.forEach(e => window.removeEventListener(e, preventScroll))
}


const instances = new WeakMap()

const getInstance = el => {
  if (!instances.has(el)) {
    instances.set(el, new ModalMorph(el))
  }
  return instances.get(el)
}

const defaults = {
  easing: constants.easing.default,
}

class ModalMorph {
  /**
   *
   * @param {HTMLElement} dialog
   * @param {object} options
   */
  constructor(dialog, options = {}) {
    this.dialog = dialog
    this.options = {...defaults, ...options}

    this.button = null
    this.isOpen = false
    this.content = this.dialog.querySelector('.modal-morph__content')
    this.body = this.dialog.querySelector('.modal-morph__body')

    this.backdrop = dom.el('div', {className: 'modal-morph__backdrop'})
    this.dialog.prepend(this.backdrop)

    on(this.dialog, 'click', '[data-dismiss="modal"]', this._handleDismissClick)
    on(this.backdrop, 'click', this._handleDismissClick)
    on(this.dialog, 'keydown', this._handleKeyDown)
  }

  /**
   *
   * @param {HTMLElement} button
   * @returns {Promise.<T>}
   */
  openFrom(button) {
    this.button = button
    const {easing} = this.options
    lockScroll()

    // -----> First
    const btnPos = this.button.getBoundingClientRect()
    const btnColor = parseBackgroundColor(this.button)

    // -----> Last
    this.dialog.classList.add('modal-morph--is-open')
    const contentPos = this.content.getBoundingClientRect()
    const contentColor = parseBackgroundColor(this.content)

    // -----> Invert
    const diffX = btnPos.left - contentPos.left
    const diffY = btnPos.top - contentPos.top
    const diffW = btnPos.width / contentPos.width
    const diffH = btnPos.height / contentPos.height

    animate.set(this.content, {
      translateX: `${diffX}px`,
      translateY: `${diffY}px`,
      scaleX: diffW,
      scaleY: diffH,
      ...btnColor,
      opacity: 0,
    })
    animate.set(this.body, {opacity: 0})

    // -----> Play
    animate(this.button, {opacity: [0, 1]}, {duration: 250})
    animate(this.content, {opacity: [1, 0]}, {duration: 250})

    const transform = {
      translateX: [0, diffX],
      translateY: [0, diffY],
      scaleX: [1, diffW],
      scaleY: [1, diffH],
      ...contentColor,
    }

    return animate(this.content, transform, {duration: 500, easing, delay: 100})
      .then(() => animate([this.body, this.backdrop], {opacity: [1, 0]}, {duration: 250}))
      .then(() => {
        this.content.style.cssText = ''
        this.dialog.setAttribute('aria-hidden', 'false')
        unlockScroll()
        this._trapFocus()
        this.isOpen = true
      })

  }

  close() {
    const {easing} = this.options
    this.isOpen = false

    lockScroll()

    // -----> First
    const btnPos = this.button.getBoundingClientRect()
    const btnColor = parseBackgroundColor(this.button)
    // -----> Last
    const contentPos = this.content.getBoundingClientRect()
    //const contentColor = parseBackgroundColor(this.content);

    // -----> Invert
    const diffX = btnPos.left - contentPos.left
    const diffY = btnPos.top - contentPos.top
    const diffW = btnPos.width / contentPos.width
    const diffH = btnPos.height / contentPos.height

    return animate([this.body, this.backdrop], {opacity: [0, 1]}, {duration: 250}).then(() => {
      // -----> Play
      return animate(this.content, {
        translateX: [diffX, 0],
        translateY: [diffY, 0],
        scaleX: [diffW, 1],
        scaleY: [diffH, 1],
        ...btnColor,
      }, {duration: 500, easing, delay: 100}).then(() => {
        this.button.style.opacity = 1
        return animate(this.content, {opacity: [0, 1]}, {duration: 250})
      }).then(() => {
        unlockScroll()
        this._releaseFocus()
        this.content.style.cssText = ''
        this.dialog.setAttribute('aria-hidden', 'true')
        this.dialog.classList.remove('modal-morph--is-open')
      })
    })
  }

  _handleDismissClick = () => {
    if (this.isOpen) {
      this.close()
    }
  }

  _handleKeyDown = event => {
    if (this.isOpen && getKey(event) === 'Escape') {
      this.close()
    }
  }

  _trapFocus() {
    this.dialog.focus()
    document.addEventListener('focus', this._enforceFocus, true)
  }

  _releaseFocus() {
    document.removeEventListener('focus', this._enforceFocus, true)
    this.button.focus()
  }

  _enforceFocus = event => {
    const {activeElement} = document
    if (activeElement !== this.dialog && !this.dialog.contains(activeElement)) {
      this.dialog.focus()
    }
  }
}

on(document.body, 'click', '[data-toggle="modal-morph"]', ({event, target}) => {
  event.preventDefault()
  target.blur()
  const modal = document.getElementById(target.dataset.target)
  getInstance(modal).openFrom(target)
})
