import Router from './router'
import actions from './router/actions'

import PageLoader from './PageLoader'
import PageManager from './PageManager'

import timer from 'embo/utils/timer'
import dom from 'embo/utils/dom'
import {trackPageView, trackOutboundLink, trackException} from 'embo/utils/analytics'

import ProposalForm from 'embo/ui/form/ProposalForm'
import MembershipForm from 'embo/ui/form/MembershipForm'
import mountCarousels from 'embo/ui/carousels'
import mountMinical from 'embo/ui/minical'
import mountPosterGallery from 'embo/ui/posters'
import {unmountReactComponents} from 'embo/ui/react-components'

import initializeSlabText from 'embo/ui/text/slab'


const noop = () => {}


// setup base path when front controller is visible
// FIXME: place this into a conditional
const base = location.pathname.match(/^\/app(?:_.*)?\.php/)
const router = new Router({
  base: base ? base[0] : '',
  dispatch: true,
})

router.onOutboundLinkClicked(link => trackOutboundLink(link))

let previousPath
const pageManager = new PageManager(
  new PageLoader(Embo.DEBUG),
)

if (Embo.DEBUG) {
  router.exclude('/_profiler')
}


function fetchPage(ctx) {
  const url = ctx.canonicalPath
  return pageManager.fetch(url).then(({title, layout}) => {
    document.title = ctx.title = title
    return layout
  })
}

/**
 * Handles Response or NetworkError thrown by fetchPage
 *
 * @param err
 * @param ctx
 *
 * @returns {Promise.<T>}
 */
function handleFetchError(err, ctx) {
  if (!err.result) {
    // Network, Unknown error or Exception in kernel.debug mode
    return pageManager.showError(err, false)
  }
  // ResponseError
  return pageManager.showError(err).then(() => {
    const {title, layout} = err.result
    document.title = ctx.title = title

    pageManager
      .setContext(ctx)
      .setLayout('centered')
      .setTheme('default')

    return pageManager.restoreScrollPosition()
      .then(() => pageManager.fadeOut())
      .then(() => pageManager.animateHeader())
      .then(() => pageManager.updateDOM(layout))
      .then(() => {
        mountMinical()
      })
      .then(() => pageManager.fadeIn())
      .then(() => trackException(err))

  })
}


//TODO: move this to PageManager
function updateNavs() {
  dom.qsa('#main .nav').forEach(nav => {
    dom.qsa('.nav-link.active', nav).forEach(el => el.classList.remove('active'))
    let targetLinks = dom.qsa('.nav-link', nav).filter(el => el.href === location.href)
    if (!targetLinks.length) {
      dom.qs('.nav-link', nav).classList.add('active')
    } else {
      targetLinks.forEach(el => el.classList.add('active'))
    }
  })
}


//
// MiddleWares
// --------------------------------------------------------------------------------------------------------------------

/**
 * Transitions to givent layout & theme, calling beforeEnter before page fades in.
 * Returns a middleware for router.register
 *
 * @param layout
 * @param theme
 * @param {Function} beforeEnter
 *
 * @returns {Function}
 */
function transitionTo(layout, theme, beforeEnter = noop) {
  return (ctx, next) => {
    if (ctx.init) {
      pageManager
        .setContext(ctx)
        .setLayout(layout)
        .setTheme(theme)

      return Promise.resolve(beforeEnter()).then(next)
    }
    fetchPage(ctx).then(
      nextDom => {
        pageManager
          .setContext(ctx)
          .setLayout(layout)
          .setTheme(theme)

        return pageManager.restoreScrollPosition()
          .then(() => pageManager.fadeOut())
          .then(() => pageManager.animateHeader())
          .then(() => pageManager.updateDOM(nextDom))
          .then(() => {
            const {currentLayout, previousLayout} = pageManager
            if (currentLayout.sidebar && !previousLayout.sidebar) {
              mountMinical()
            }
          })
          .then(() => beforeEnter())
          .then(() => pageManager.fadeIn())
          .then(next)
      },
      err => handleFetchError(err, ctx),
    )
  }
}

/**
 * Subtransition by swapping surfaces.
 *
 * @param {Function} defaultMiddleware Middleware used when entering the subsection from outside.
 * @param {string|RegExp} path Path must match to use subtransition
 * @param {string} surfaceId Which surface to swap
 * @param {Function} init Page initializer
 *
 * @returns {Function}
 */
function subTransitionTo(defaultMiddleware, path, surfaceId, init = noop) {
  return (ctx, next) => {
    if (!ctx.init && previousPath.match(new RegExp(path))) {
      return fetchPage(ctx).then(
        nextDom => {
          pageManager.setContext(ctx)
          updateNavs()
          return pageManager.swapSurface(surfaceId, nextDom, init).then(next)
        },
        err => handleFetchError(err, ctx),
      )
    }
    return defaultMiddleware(ctx, next)
  }
}

/**
 * Middleware called at the start of the chain for every route.
 * MUST call next(), in order to fire all following handlers.
 */
function start(ctx, next) {
  if (ctx.init) {
    // Cache the initial page.
    // Called early to get the unmodified original html.
    pageManager.saveInitialPage()
  }
  next()
}

/**
 * Middleware called at the end of the chain for every successfull handler chain.
 * MUST NOT call next(), in order to prevent additional handlers to be fired accidentally.
 */
function end(ctx) {
  const {currentLayout} = pageManager
  if (ctx.init && currentLayout.sidebar) {
    mountMinical()
  }
  if (!ctx.init) {
    trackPageView(ctx.canonicalPath, ctx.title)
  }
}

const home = init => transitionTo('centered', 'homepage', init)
const fluidLayout = init => transitionTo('fluid', 'agenda', init)
const defaultLayout = init => transitionTo('centered', 'default', init)


//
// Routes
// --------------------------------------------------------------------------------------------------------------------

router.register('*', start)
router.exit('*', (ctx, next) => {
  previousPath = ctx.path
  //console.log('[Router] exit from %s to %s (%s)', ctx.path, router.current, ctx.action);
  next()
})


// Home
// ----

router.register('/', home(() => Promise.all([
  initializeSlabText(),
  mountCarousels(),
])), end)


// Static pages
// ------------

const pagesShow = () => Promise.all([
  initializeSlabText(),
  mountCarousels(),
])
router.register(new RegExp('/pages/[^?#]+'), defaultLayout(pagesShow), end)


// Events
// ------

const eventsIn = () => {}
const eventsByYear = subTransitionTo(
  fluidLayout(eventsIn),
  '^/events(/\\d{4})?$',
  'event-list',
  eventsIn,
)
const eventSearch = subTransitionTo(
  fluidLayout(eventsIn),
  '^/events/search(\\?.+)?$',
  'event-list',
  () => pageManager.restoreScrollPosition(),
)
const eventShow = () => Promise.all([
  initializeSlabText(),
  mountCarousels(),
])

router.register('/events/upcoming', fluidLayout(eventsIn), end)
router.register('/events/performer/:performer', fluidLayout(eventsIn), end)
router.register('/events/category/:category', fluidLayout(eventsIn), end)
router.register('/events/tag/:tag', fluidLayout(eventsIn), end)
router.register('/events/search', eventSearch, end)
router.register('/events/:year?', eventsByYear, end)
router.register('/events/:year/:month/:day/:slug', defaultLayout(eventShow), end)

const legacyEventsByYear = subTransitionTo(
  fluidLayout(),
  '^/legacy/events(/\\d{4})?$',
  'event-list',
  eventsIn,
)
const legacyEventSearch = subTransitionTo(
  fluidLayout(),
  '^/legacy/events/search(\\?.+)?$',
  'event-list',
  () => pageManager.restoreScrollPosition(),
)
const legacyEventShow = () => Promise.all([
  initializeSlabText(),
])
router.register('/legacy/events/search', legacyEventSearch, end)
router.register('/legacy/events/:year?', legacyEventsByYear, end)
router.register('/legacy/events/show/:id', defaultLayout(legacyEventShow), end)

// Posts
// -----

const postsByYear = subTransitionTo(
  fluidLayout(),
  '^/posts(/\\d{4})?$',
  'post-list',
)
const postSearch = subTransitionTo(
  fluidLayout(),
  '^/posts/search(\\?.+)?$',
  'post-list',
  () => pageManager.restoreScrollPosition(),
)
const postShow = () => Promise.all([
  initializeSlabText(),
  mountCarousels(),
])

router.register('/posts/author/:author', fluidLayout(), end)
router.register('/posts/category/:category', fluidLayout(), end)
router.register('/posts/tag/:tag', fluidLayout(), end)
router.register('/posts/search', postSearch, end)
router.register('/posts/:year?', postsByYear, end)
router.register('/posts/:year/:month/:day/:slug', defaultLayout(postShow), end)

const legacyPostsByYear = subTransitionTo(
  fluidLayout(),
  '^/legacy/posts(/\\d{4})?$',
  'post-list',
)
const legacyPostShow = () => Promise.all([
  initializeSlabText(),
])
router.register('/legacy/posts/:year?', legacyPostsByYear, end)
router.register('/legacy/posts/show/:slug', defaultLayout(legacyPostShow), end)


// Posters
// -------

const postersIn = () => mountPosterGallery()
const postersByYear = subTransitionTo(
  fluidLayout(postersIn),
  '^/posters(/\\d{4})?$',
  'posters-list',
  postersIn,
)

router.register('/posters/:year?', postersByYear, end)
router.register('/posters/author/:author', fluidLayout(postersIn), end)


// Links
// -----

router.register('/links', fluidLayout(), end)


// Forms
// -----

router.register('/proposal', defaultLayout(() => {
  new ProposalForm()
}), end)
router.register('/membership', defaultLayout(() => {
  new MembershipForm()
}), end)


// Catchall
// --------

router.register('*', defaultLayout(), end)

export default router
