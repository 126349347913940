// get event's target, handling shadow dom
const getEventTarget = event => event.path ? event.path[0] : event.target


function getDelegateTarget(el, event, selector) {
  for (let target = getEventTarget(event); target; target = target.parentElement) {
    if (target.matches(selector)) {
      return target
    }
    if (target === el || target.nodeType === Node.DOCUMENT_NODE) {
      return
    }
  }
}

/**
 * @param {HTMLElement} el
 * @param {string} eventName
 * @param {string} selector
 * @param {Function} handler
 *
 * @returns {Function}
 */
export function delegate(el, eventName, selector, handler) {
  const proxy = event => {
    let target = getDelegateTarget(el, event, selector)
    if (target) {
      handler({event, target})
    }
  }

  el.addEventListener(eventName, proxy)

  return () => el.removeEventListener(eventName, proxy)
}

/**
 * @param {HTMLElement} el
 * @param {string} eventName
 * @param {string} selector
 * @param {Function} handler
 *
 * @returns {Function}
 */
export function delegateOnce(el, eventName, selector, handler) {
  let off
  const proxy = event => {
    let target = getDelegateTarget(el, event, selector)
    if (target) {
      handler({event, target})
      off()
    }
  }

  off = () => el.removeEventListener(eventName, proxy)
  el.addEventListener(eventName, proxy)

  return off
}
