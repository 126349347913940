import React, {useCallback, useRef} from 'react'
import PropTypes from 'prop-types'

import clamp from 'embo/utils/math/clamp'
import vsync from 'embo/utils/vsync'
import Timer from './Timer'


const {min, max, floor} = Math

/**
 * Given an x coordinate and the coordinates of a rectangle,
 * returns the ratio of x to the rectangle.
 *
 * @param {number} x
 * @param {ClientRect} rect
 * @returns {number}
 */
const computeProgressFromCoords = (x, rect) => {
  const ratio = (x - rect.left) / rect.width
  return clamp(ratio, 0, 1)
}


const Progress = ({
  position = 0,
  duration = 0,
  onSeek,
}) => {
  const progress = clamp(position / duration, 0, 1)

  const progressTrack = useRef(null)

  const handleClick = useCallback(({clientX}) => {
    vsync.run({
      measure: () => progressTrack.current.getBoundingClientRect(),
      mutate: coords => {
        const progress = computeProgressFromCoords(clientX, coords)
        onSeek(progress * duration)
      },
    })
  }, [duration, onSeek])

  const handleKeyDown = useCallback(event => {
    let handled = true
    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowLeft':
        // seek -1 sec
        onSeek(max(0, position - 1))
        break
      case 'ArrowUp':
      case 'ArrowRight':
        // seek +1 sec
        onSeek(min(duration, position + 1))
        break
      case 'PageDown':
        // seek -10 sec
        onSeek(max(0, position - 10))
        break
      case 'PageUp':
        // seek -10 sec
        onSeek(min(duration, position + 10))
        break
      case 'Home':
        onSeek(0)
        break
      case 'End':
        onSeek(duration)
        break
      default:
        handled = false
        break
    }
    if (handled) {
      event.preventDefault()
    }
  }, [position, duration, onSeek])

  return (
    <div className="embo-player__progress" role="status">
      <Timer value={floor(position)} title="Position"/>
      <div className="embo-player__progress__wrapper" onClick={handleClick}>
        <div ref={progressTrack}
          className="embo-player__progress__track"
          role="slider" tabIndex="0"
          aria-valuemin="0" aria-valuemax={floor(duration)} aria-valuenow={floor(position)}
          onKeyDown={handleKeyDown}
        >
          <div className="embo-player__progress__bar" style={{transform: `scaleX(${progress})`}}/>
        </div>
      </div>
      <Timer value={duration} title="Duration"/>
    </div>
  )
}
Progress.propTypes = {
  position: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  onSeek: PropTypes.func.isRequired,
}

export default Progress
