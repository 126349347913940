import React, {Component} from 'react'
import {findDOMNode, unmountComponentAtNode} from 'react-dom'

import dom from 'embo/utils/dom'

/**
 *
 * @param {React.Component|string} component
 * @param {HTMLElement|string} container
 *
 * @returns {Array.<HTMLElement>}
 */
export function findComponentNodes(component = null, container = null) {
  if (component instanceof Component) {
    return [findDOMNode(component)]
  }
  const selector = component ? `[data-react-component="${component}"]` : '[data-react-component]'
  return dom.qsa(selector, container)
}

/**
 *
 * @param {React.Component|string} component
 * @param {HTMLElement|string} container
 *
 * @returns {Promise.<Array.<HTMLElement>>}
 */
export function unmountReactComponents(component = null, container = null) {
  const nodes = findComponentNodes(component, container)
  return Promise.resolve(nodes.map(node => unmountComponentAtNode(node)))
}
