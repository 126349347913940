import React from 'react'
import {render} from 'react-dom'

import store from 'embo/store'
import MinicalContainer from 'embo/components/minical/App'

import {findComponentNodes} from './react-components'


export default function mountMinical() {
  const nodes = findComponentNodes('Minical')
  if (nodes.length) {
    render(<MinicalContainer store={store}/>, nodes[0])
  }
}
