import Path from 'embo/utils/svg/path'
import {getPentagonPoints} from './circle'


const {PI} = Math
const DEG = 180 / PI


/**
 *
 * @param r Radius of the pentagram's circumscribed circle
 * @param cx center x of the circumscribed circle
 * @param cy center y of the circumscribed circle
 *
 * @returns {Path}
 */
export function getPentagramPath(r, cx, cy) {
  // get the points of the pentagon
  const points = getPentagonPoints(r, cx, cy, -45 * DEG).map(({x, y}) => [x, y])
  // iterate by two
  return new Path([
    ['M', points[0]],
    ['L', points[2]],
    ['L', points[4]],
    ['L', points[1]],
    ['L', points[3]],
    ['Z'],
    //['L', points[0]],
  ])
}

/**
 * Returns the path length of a pentagram given the radius
 * of it's circumscribed circle.
 * @see http://www.had2know.com/academics/pentagon-measurement-calculator.html
 *
 * @param r Radius of the pentagram's circumscribed circle
 *
 * @returns {number}
 */
export function getPentagramLength(r) {
  const h = r * (1 + Math.cos(PI / 5))
  const c = h / Math.cos(PI / 10)
  return Math.ceil(c * 5)
}
