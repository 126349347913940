import dom from 'embo/utils/dom'
import {stop, on, off, getKey, getModifierState} from 'embo/utils/events'
import animate from 'embo/utils/animate'


const BREAKPOINT = 768 - 1


export default function initializeNavbar() {
  const toggler = dom.id('navbar-toggler')
  const nav = dom.id('navbar-navs')
  /** @const {MediaQueryList} */
  const mediaQuery = window.matchMedia(`(max-width: ${BREAKPOINT}px)`)

  // hide side-panel by default
  nav.classList.add('offscreen')
  dom.attrs(toggler, {
    role: 'button',
    'aria-controls': 'navbar-navs',
    'aria-expanded': 'false',
  })

  on(toggler, 'click', e => {
    e.preventDefault()
    const hidden = nav.classList.contains('offscreen')
    nav.classList.toggle('offscreen')
    toggler.setAttribute('aria-expanded', hidden)
  })

  let removeLinkClickHandler = () => {}
  const onMenuLinkClick = () => {
    removeLinkClickHandler()
    nav.classList.add('offscreen')
    toggler.setAttribute('aria-expanded', false)
  }
  const addLinkClickHandler = () => on(nav, 'click', 'a:not(.dropdown-toggle)', onMenuLinkClick)

  //
  // Handle Keyboard focus ring
  //------------------------------------------------------------------------------------------------------------------

  const links = dom.qsa('a:not(.dropdown-toggle)', nav)
  const firstLink = links[0]
  const lastLink = links[links.length - 1]

  const onLastLinkKeydown = event => {
    // TAB at end of navigation block: return focus to navigation menu button
    if (getKey(event) === 'Tab' && !getModifierState(event, 'Shift')) {
      event.preventDefault()
      toggler.focus()
    }
  }
  const onFirstLinkKeydown = event => {
    // SHIFT+TAB at start of navigation block: refocus close button
    if (getKey(event) === 'Tab' && getModifierState(event, 'Shift')) {
      event.preventDefault()
      toggler.focus()
    }
  }
  const onTogglerKeydown = event => {
    if (getKey(event) !== 'Tab') {
      return
    }
    // If the menu is visible, always TAB into it from the menu button
    if (toggler.getAttribute('aria-expanded') === 'false') {
      return
    }
    if (!getModifierState(event, 'Shift')) {
      event.preventDefault()
      firstLink.focus()
      //console.log(links[0]);
    } else {
      // Do nothing since the link before toggler is an anchor to #main
      // otherwise we would have to dom.id('#main').focus();
    }
  }

  const trapFocus = () => {
    on(lastLink, 'keydown', onLastLinkKeydown)
    on(firstLink, 'keydown', onFirstLinkKeydown)
    on(toggler, 'keydown', onTogglerKeydown)
  }
  const releaseFocus = () => {
    off(lastLink, 'keydown', onLastLinkKeydown)
    off(firstLink, 'keydown', onFirstLinkKeydown)
    off(toggler, 'keydown', onTogglerKeydown)
  }

  const onMediaQueryChanged = mql => {
    if (mql.matches) {
      trapFocus()
      removeLinkClickHandler = addLinkClickHandler()
    } else {
      releaseFocus()
      removeLinkClickHandler()
    }
  }

  mediaQuery.addListener(onMediaQueryChanged)
  onMediaQueryChanged(mediaQuery)
}
