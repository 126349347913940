import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import vsync from 'embo/utils/vsync'
import blurCanvas from 'embo/utils/canvas/stackblur/rgb'


const drawImage = (canvas, img, width, height) => {
  const context = canvas.getContext('2d')
  canvas.width = width
  canvas.height = height
  context.clearRect(0, 0, width, height)
  context.drawImage(img, 0, 0)

  blurCanvas(canvas, 0, 0, width, height, 100)

  context.fillStyle = 'rgba(0,0,0,0.4)'
  context.fillRect(0, 0, width, height)
}


const BackgroundBlur = ({image}) => {

  const canvas = useRef(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!image) return () => setLoaded(false)

    const onLoad = ({target: img}) => {
      vsync.run({
        measure: () => ({
          width: img.naturalWidth,
          height: img.naturalHeight,
        }),
        mutate: ({width, height}) => {
          drawImage(canvas.current, img, width, height)
          setLoaded(true)
        },
      })
    }

    const img = new Image()
    // Image must have CORS headers set if it is cross-domain in order to get the image data from canvas.
    img.crossOrigin = 'anonymous'
    img.addEventListener('load', onLoad)
    img.src = image

    return () => {
      setLoaded(false)
      img.removeEventListener('load', onLoad)
    }
  }, [image])

  const classes = cx('bg-blur', {'bg-blur--is-loaded': loaded})

  return <canvas ref={canvas} className={classes}/>
}
BackgroundBlur.propTypes = {
  image: PropTypes.string,
}

export default BackgroundBlur
