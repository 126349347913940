import React from 'react'
import PropTypes from 'prop-types'


const VolumeIcon = ({
  width = 36,
  height = 36,
  bars = 2,
}) => (
  <svg width={width} height={height} viewBox="0 0 36 36">
    <path d="M12.39,15.54 L10,15.54 L10,20.44 L12.4,20.44 L17,25.50 L17,10.48 L12.39,15.54 Z"/>
    <path d="M22,17.99 C22,16.4 20.74,15.05 19,14.54 L19,21.44 C20.74,20.93 22,19.59 22,17.99 Z"
      opacity={bars > 0 ? 1 : 0}/>
    <path d="M19,24.31 L19,26 C22.99,25.24 26,21.94 26,18 C26,14.05 22.99,10.75 19,10 L19,11.68 C22.01,12.41 24.24,14.84 24.24,18 C24.24,21.15 22.01,23.58 19,24.31 Z"
      opacity={bars > 1 ? 1 : 0}/>
    <path d="M19.63,15.92 L20.68,14.93 L22.81,16.94 L24.94,14.93 L26,15.92 L23.86,17.93 L26,19.93 L24.94,20.92 L22.81,18.92 L20.68,20.92 L19.63,19.93 L21.76,17.93 L19.63,15.92 Z"
      opacity={bars === 0 ? 1 : 0}/>
  </svg>
)
VolumeIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  bars: PropTypes.number,
}

export default React.memo(VolumeIcon, (prevProps, nextProps) => {
  return nextProps.bars === prevProps.bars
    && nextProps.width === prevProps.width
    && nextProps.height === prevProps.height
})
