import SymfonyForm from './BaseSymfonyForm'
import dom from 'embo/utils/dom'
import {on} from 'embo/utils/events'
import animate from 'embo/utils/animate'

/**
 * Handles the membership form.
 *
 * ATM, just hides/shows the amount field, but we may have to implement PayPal stuff later.
 */
export default class MembershipForm extends SymfonyForm {
  constructor() {
    super('embo_membership')

    this.membershipTypeGroup = dom.id('embo_membership_type')
    this.amountInput = dom.id('embo_membership_amount')
    this.amountGroup = this.amountInput.closest('.form-group')
    this.amountGroupToggler = dom.id('embo_membership_type_2')

    this.isAmountVisible = true
    this.initializeAmountToggle()
  }

  initializeAmountToggle() {
    this.isAmountVisible = this.amountGroupToggler.checked
    if (!this.isAmountVisible) {
      this.amountGroup.setAttribute('aria-hidden', 'true')
      this.amountGroup.style.display = 'none'
      this.amountGroup.style.opacity = 0
    }
    this.amountGroup.style.transition = 'opacity .2s ease-out'

    on(this.membershipTypeGroup, 'change', 'input', ({target}) => {
      if (target.value === 'support') {
        this.amountGroup.style.opacity = 1
        animate(this.amountGroup, 'slideDown', {duration: 200})
          .then(() => {
            this.isAmountVisible = true
            this.amountGroup.setAttribute('aria-hidden', 'false')
          })
      } else if (this.isAmountVisible) {
        this.amountGroup.style.opacity = 0
        animate(this.amountGroup, 'slideUp', {duration: 200})
          .then(() => {
            this.isAmountVisible = false
            this.amountGroup.setAttribute('aria-hidden', 'true')
          })
      }
    })
  }
}
