import React from 'react'
import PropTypes from 'prop-types'

import svgIcons from '@images/icons.svg'

const Icon = ({
  name,
  width = 24,
  height = 24,
  ...props
}) => (
  <svg {...props} className={`icon icon-${name}`} width={width} height={height}>
    <use xlinkHref={`${svgIcons}#${name}`}/>
  </svg>
)
Icon.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default React.memo(Icon)
