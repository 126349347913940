import React, {useCallback, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {Transition} from 'react-transition-group'

import Placeholder from './Placeholder'
import Slider from './Slider'

import constants from 'embo/constants'
import animate from 'embo/utils/animate'
import {domRect} from 'embo/utils/dom/layoutRect'


const easing = constants.easing.default
const TIMEOUTS = {
  enter: 1100,
  exit: 1100,
}


const Carousel = ({images}) => {
  const [isViewing, setIsViewing] = useState(false)
  const container = useRef(null)
  const placeholder = useRef(null)
  const dimensions = useRef({})

  const classes = cx('embo-carousel', {
    'embo-carousel--is-viewing': isViewing,
  })
  // TODO: a better way ?
  const thumb = images[0]

  const handlePlay = useCallback(() => {
    dimensions.current = domRect(container.current)
    setIsViewing(true)
  }, [])
  const handleClose = useCallback(() => {
    dimensions.current = domRect(container.current)
    setIsViewing(false)
  }, [])

  const sliderWillEnter = useCallback(slider => {
    const doc = document.documentElement
    // hide slider
    slider.style.opacity = 0
    Object.entries(dimensions.current).forEach(([k, v]) => slider.style[k] = `${v}px`)
    // hide slider children
    const children = Array.from(slider.children)
    children.forEach(child => child.style.opacity = 0)
    // crossfade
    animate(placeholder.current, {opacity: [0, 1]}, {duration: 250})
    animate(slider, {opacity: [1, 0]}, {duration: 250})
    // gogogo
    const {top, left, width, height} = dimensions.current
    return animate(slider, {
      top: [0, top],
      left: [0, left],
      width: [doc.clientWidth, width],
      height: [doc.clientHeight, height],
    }, {duration: 500, easing}).then(() => {
      return animate(children, {opacity: [1, 0]}, {duration: 250})
    }).then(() => {
      slider.setAttribute('style', '')
    })
  }, [])

  const sliderWillLeave = useCallback(slider => {
    // hide slider children
    const children = Array.from(slider.children)
    const {top, left, width, height} = dimensions.current
    return animate(children, {opacity: [0, 1]}, {duration: 250}).then(() => {
      return animate(slider, {
        top: [top, 0],
        left: [left, 0],
        width,
        height,
      }, {duration: 500, easing})
    }).then(() => {
      // crossfade
      animate(placeholder.current, {opacity: [1, 0]}, {duration: 250})
      return animate(slider, {opacity: [0, 1]}, {duration: 250})
    })
  }, [])

  return (
    <div ref={container} className={classes}>
      <Placeholder ref={placeholder} image={thumb} onPlay={handlePlay}/>
      <Transition in={isViewing} timeout={TIMEOUTS}
        mountOnEnter={true}
        unmountOnExit={true}
        onEnter={sliderWillEnter}
        onExit={sliderWillLeave}
      >
        <Slider images={images} onClose={handleClose}/>
      </Transition>
    </div>
  )
}
Carousel.propTypes = {
  images: PropTypes.array.isRequired,
}
export default Carousel
