import React, {PureComponent} from 'react'
import {connect} from 'react-redux'

import platform from 'embo/utils/platform'

// actions
import {
  setPlaylistVisible,
  setPlaying,
  setVolume,
  setCurrentTime, storeCurrentTime,
  setCurrentDuration,
} from 'embo/state/player'
import {
  nextTrack, previousTrack, setCurrentTrack,
  addTrack, removeTrack, moveTrack,
  setInfoPanelTrack, nextTrackInfo, previousTrackInfo,
  audioError, audioLoadSuccess,
  trackPresented,
  // selectors
  selectTracks,
  selectCurrentTrack,
  selectInfoPanelTrack,
} from 'embo/state/playlist'

import Audio from './Audio'
import PlayButton from './PlayButton'
import NextButton from './NextButton'
import PrevButton from './PrevButton'
import PlaylistButton from './PlaylistButton'
import TitleBar from './TitleBar'
import Progress from './Progress'
import Volume from './Volume'
import Playlist from './Playlist'
import TrackInfoPanel from './TrackInfoPanel'


const IS_MOBILE = platform.isMobile()


const mapStateToProps = state => {
  const {currentIndex, currentTrackId, presentedTrackId} = state.playlist
  const {
    playlistVisible, infoPanelVisible,
    playing, currentTime, requestedCurrentTime, currentDuration,
    volume,
  } = state.player
  return {
    tracks: selectTracks(state),
    currentIndex,
    currentTrackId,
    currentTrack: selectCurrentTrack(state),
    presentedTrackId,
    playlistVisible,
    infoPanelVisible,
    infoPanelTrack: selectInfoPanelTrack(state),
    playing,
    currentTime,
    requestedCurrentTime,
    currentDuration,
    volume,
  }
}

class Player extends PureComponent {
  constructor() {
    super()
    this._chainTimeout = 0
  }

  componentWillUnmount() {
    clearTimeout(this._chainTimeout)
  }

  _chainPlay() {
    this._chainTimeout = setTimeout(() => this._setPlaying(true), 2000)
  }

  _setPlaying(playing) {
    clearTimeout(this._chainTimeout)
    this.props.dispatch(setPlaying(playing))
  }

  render() {
    const {
      dispatch,
      playing,
      currentTime, requestedCurrentTime,
      currentDuration,
      volume,
      tracks,
      currentIndex,
      currentTrackId,
      presentedTrackId,
      currentTrack,
      playlistVisible,
      infoPanelVisible,
      infoPanelTrack,
    } = this.props

    return (
      <div className="embo-player">

        <Audio
          src={currentTrack ? currentTrack.url : null}
          playing={playing} volume={volume} currentTime={requestedCurrentTime}
          onPlay={this.handleAudioPlay}
          onPause={this.handleAudioPause}
          onLoad={this.handleAudioLoad}
          onEnd={this.handleAudioEnd}
          onError={this.handleAudioError}
          onVolume={this.handleAudioVolumeChange}
          onTimer={this.handleAudioTimeChange}
          onDuration={this.handleAudioDurationChange}
        />

        <div className="embo-player__controls">
          <PrevButton onClick={this.handlePrevClicked}/>
          <PlayButton playing={playing} onClick={this.handlePlayClicked}/>
          <NextButton onClick={this.handleNextClicked}/>
          <div className="embo-player__statusbar">
            <TitleBar track={currentTrack} onTrackInfoRequest={this.handleTrackInfoRequest}/>
            <Progress
              position={currentTime}
              duration={currentDuration}
              onSeek={this.handleProgressbarSeek}
            />
          </div>
          {!IS_MOBILE && (
            // Mobile OSes usually don't let us change volume, don't bother
            <Volume volume={volume} onChange={volume => dispatch(setVolume(volume))}/>
          )}
          <PlaylistButton
            open={playlistVisible}
            onClick={this.handlePlaylistToggle}
          />
        </div>

        <Playlist
          visible={playlistVisible}
          tracks={tracks} current={currentTrackId} presented={presentedTrackId}
          onItemSelected={this.handleTrackSelected}
          onItemRemoved={this.handleTrackRemoved}
          onItemMoved={this.handleTrackMoved}
          onTrackInfoRequest={this.handleTrackInfoRequest}
          onTrackPresented={this.handleTrackPresented}
        />

        <TrackInfoPanel
          visible={infoPanelVisible}
          track={infoPanelTrack}
          onNext={this.handleInfoPanelNext}
          onPrevious={this.handleInfoPanelPrev}
          onClose={this.handleInfoPanelClose}
        />

      </div>
    )
  }

  handleAudioPlay = () => this._setPlaying(true)
  handleAudioPause = () => this._setPlaying(false)

  handleAudioLoad = () => this.props.dispatch(audioLoadSuccess(this.props.currentTrackId))
  handleAudioError = err => this.props.dispatch(audioError(this.props.currentTrackId, err))

  handleAudioEnd = () => {
    this.props.dispatch(nextTrack())
    this._chainPlay()
  }

  handleAudioVolumeChange = volume => this.props.dispatch(setVolume(volume))
  handleAudioTimeChange = currentTime => this.props.dispatch(storeCurrentTime(currentTime))
  handleAudioDurationChange = duration => this.props.dispatch(setCurrentDuration(duration))

  handlePlayClicked = () => this._setPlaying(!this.props.playing)

  handleNextClicked = () => {
    this.props.dispatch(nextTrack())
    this._setPlaying(true)
  }

  handlePrevClicked = () => {
    this.props.dispatch(previousTrack())
    this._setPlaying(true)
  }

  handleTrackSelected = trackId => {
    this.props.dispatch(setCurrentTrack(trackId))
    this._setPlaying(true)
  }

  handleProgressbarSeek = position => this.props.dispatch(setCurrentTime(position))

  handleTrackRemoved = trackId => this.props.dispatch(removeTrack(trackId))

  handleTrackMoved = (trackId, position) => this.props.dispatch(moveTrack(trackId, position))

  handleTrackInfoRequest = trackId => this.props.dispatch(setInfoPanelTrack(trackId))

  handlePlaylistToggle = () => this.props.dispatch(setPlaylistVisible(!this.props.playlistVisible))

  handleTrackPresented = () => this.props.dispatch(trackPresented())

  handleInfoPanelNext = () => this.props.dispatch(nextTrackInfo())
  handleInfoPanelPrev = () => this.props.dispatch(previousTrackInfo())
  handleInfoPanelClose = () => this.props.dispatch(setInfoPanelTrack(null))

  /**
   * TODO: handle dragging new tracks to playlist
   */
  handleTrackDrop = (track, pos) => this.props.dispatch(addTrack(track, pos))
}

export default connect(mapStateToProps)(Player)
