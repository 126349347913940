import React, {useCallback, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'

import {
  useSpring,
  useTransition,
  animated,
  config as SpringPresets,
} from 'react-spring'

import viewport from 'embo/utils/viewport'
import vsync from 'embo/utils/vsync'
import debounce from 'embo/utils/function/debounce'

import Event from './Event'
import {eventShape} from './propTypes'

const AnimatedEvent = animated(Event)

const TRANSITION_CONFIG = {
  from: {y: 0, opacity: 0},
  enter: {y: 0, opacity: 1},
  leave: {y: 0, opacity: 0},
  update: ({y, opacity}) => ({y, opacity}),
  trail: 6,
  config: SpringPresets.stiff,
}
const getTransitionKey = event => event.id
const getTransitionItems = (events, layouts) => {
  let currentHeight = 0
  let totalHeight = 0

  const items = events.map(event => {
    const layout = layouts[event.id]
    const style = {
      y: currentHeight,
      opacity: 1,
    }
    if (layout) {
      currentHeight += layout.height
      totalHeight = currentHeight
    }
    return {...event, ...style}
  })

  return {items, totalHeight}
}


const EventList = ({
  events,
}) => {
  const [layouts, setLayouts] = useState(events)
  const nodes = useRef({})

  useEffect(() => {
    const handleViewportChange = debounce(({relayoutAll}) => {
      if (!relayoutAll) return
      vsync.run({
        measure: () => Object.keys(nodes.current)
          .reduce((layouts, id) => {
            layouts[id] = nodes.current[id].getBoundingClientRect()
            return layouts
          }, {}),
        mutate: layouts => setLayouts(layouts),
      })
    }, 300)

    return viewport.onChange(handleViewportChange)
  }, [])

  const handleItemMount = useCallback(
    (id, node) => {
      nodes.current[id] = node
      vsync.run({
        measure: () => node.getBoundingClientRect(),
        mutate: rect => setLayouts(prev => ({...prev, [id]: rect})),
      })
    },
    [nodes]
  )
  const handleItemUnmount = useCallback(
    id => delete nodes.current[id],
    [nodes]
  )

  const {items, totalHeight} = getTransitionItems(events, layouts)
  const numItems = items.length

  const listStyles = useSpring({height: totalHeight, config: SpringPresets.stiff})
  const transitions = useTransition(items, getTransitionKey, TRANSITION_CONFIG)

  return (
    <animated.ul className="embo-minical__event-list" style={{
      position: 'relative',
      zIndex: 0,
      height: listStyles.height,
    }}>
      {transitions.map(({item, key, props: {y, opacity}}, index) => (
        <AnimatedEvent key={key}
          style={{
            position: 'absolute',
            width: '100%',
            zIndex: numItems - index,
            opacity,
            transform: y.interpolate(y => `translate3d(0, ${y}px, 0)`),
          }}
          event={item}
          onUnmount={handleItemUnmount}
          onMount={handleItemMount}
        />
      ))}
    </animated.ul>
  )
}
EventList.propTypes = {
  events: PropTypes.arrayOf(eventShape).isRequired,
}

export default EventList
