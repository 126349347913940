import timer from 'embo/utils/timer'

import {delegate, delegateOnce} from './delegation'

export {default as getKey} from './getKey'
export {default as getModifierState} from './getModifierState'


/**
 * @param {HTMLElement} el
 * @param {string} eventName
 * @param {string|Function} selector
 * @param {Function} [handler]
 *
 * @returns {Function}
 */
export function on(el, eventName, selector, handler) {
  const typeofSelector = typeof selector
  const typeofHandler = typeof handler

  if (typeofSelector === 'string' && typeofHandler === 'function') {
    return delegate(el, eventName, selector, handler)
  }
  if (typeofSelector === 'function') {
    handler = selector
    el.addEventListener(eventName, handler)
    return () => el.removeEventListener(eventName, handler)
  }

  throw new TypeError('Wrong argument type passed to on().')
}

/**
 * @param {HTMLElement} el
 * @param {string} eventName
 * @param {Function} handler
 */
export function off(el, eventName, handler) {
  el.removeEventListener(eventName, handler)
}

/**
 * @param {Event} event
 */
export function stop(event) {
  event.preventDefault()
  event.stopPropagation()
}

/**
 * @param {HTMLElement} el
 * @param {string} eventName
 * @param {string|number} selector
 * @param {number} [timeout]
 *
 * @returns {Promise<Event>}
 */
export function one(el, eventName, selector, timeout) {
  let off, executor
  const typeofSelector = typeof selector

  if (typeofSelector === 'string') {
    executor = resolve => off = delegateOnce(el, eventName, selector, resolve)
  } else if (typeofSelector === 'number') {
    timeout = selector
    executor = resolve => off = listenOnce(el, eventName, resolve)
  }

  return racePromise(new Promise(executor), off, timeout)
}

function listenOnce(el, eventName, handler) {
  let off
  const proxy = event => {
    handler(event)
    off()
  }
  off = () => el.removeEventListener(eventName, proxy)
  el.addEventListener(eventName, proxy)

  return off
}

function racePromise(promise, off, timeout) {
  let racePromise
  if (timeout === undefined) {
    // Timeout is not specified: return promise.
    racePromise = promise
  } else {
    // Timeout has been specified: add a timeout condition.
    racePromise = timer.timeout(timeout || 0, promise)
  }
  if (!off) {
    return racePromise
  }

  return racePromise.then(
    result => {
      off()
      return result
    },
    reason => {
      off()
      return Promise.reject(reason)
    },
  )
}


export function onTransitionEnd(el, handler) {
  return on(el, 'transitionend', e => e.target === el && handler(e))
}

export function oneTransitionEnd(el, timeout = 2000) {
  let off
  const eventPromise = new Promise((resolve, reject) => {
    off = listenOnce(el, 'transitionend', e => e.target === el && resolve(e))
  })
  return racePromise(eventPromise, off, timeout)
}
