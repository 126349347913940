import React from 'react'
import PropTypes from 'prop-types'
import {
  useSpring,
  animated,
  config as SpringPresets,
} from 'react-spring'


const paths = {
  play: 'M11,10 L18,13.74 L18,22.28 L11,26 M18,13.74 L26,18 L26,18 L18,22.28',
  pause: 'M11,10 L17,10 L17,26 L11,26 M20,10 L26,10 L26,26 L20,26',
}

const PlayButton = ({
  playing,
  onClick,
}) => {
  const destPath = playing ? paths.pause : paths.play
  const styles = useSpring({path: destPath, config: SpringPresets.stiff})

  return (
    <button className="embo-player__play-btn" title="Play" onClick={onClick}>
      <svg width="36" height="36" viewBox="0 0 36 36">
        <animated.path d={styles.path}/>
      </svg>
    </button>
  )
}
PlayButton.propTypes = {
  playing: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}
export default React.memo(PlayButton, (prevProps, nextProps) => {
  return prevProps.playing === nextProps.playing
})
