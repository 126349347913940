import React from 'react'
import PropTypes from 'prop-types'


const PrevButton = ({onClick}) => (
  <button className="embo-player__prev-btn" title="Previous" aria-label="Previous" onClick={onClick}>
    <svg width="36" height="36" viewBox="0 0 36 36">
      <path d="M19.8,12.5 L19.8,16.49 L27,12.5 L27,23.5 L19.8,19.50 L19.8,23.5 L11.5,19.1 L11.5,23.5 L9,23.5 L9,12.5 L11.5,12.5 L11.5,17.45 L19.8,12.5 Z"/>
    </svg>
  </button>
)
PrevButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}
export default React.memo(PrevButton, () => true)
