import {MUL_TABLE, SHG_TABLE} from './constants'
import BlurStack from './BlurStack'


export default function processCanvas(canvas, x, y, width, height, radius) {
  if (isNaN(radius) || radius < 1) {
    return
  }
  radius |= 0

  const context = canvas.getContext('2d')
  let imageData = context.getImageData(x, y, width, height)
  imageData = processImageData(imageData, width, height, radius)
  context.putImageData(imageData, x, y)
}


export function processImageData(imageData, width, height, radius) {
  var pixels = imageData.data

  var x, y, i, p, yp, yi, yw, r_sum, g_sum, b_sum,
    r_out_sum, g_out_sum, b_out_sum,
    r_in_sum, g_in_sum, b_in_sum,
    pr, pg, pb, rbs

  var div = radius + radius + 1
  var w4 = width << 2
  var widthMinus1 = width - 1
  var heightMinus1 = height - 1
  var radiusPlus1 = radius + 1
  var sumFactor = radiusPlus1 * (radiusPlus1 + 1) / 2

  var stackStart = new BlurStack()
  var stackEnd
  var stack = stackStart
  for (i = 1; i < div; i++) {
    stack = stack.next = new BlurStack()
    if (i === radiusPlus1) {
      stackEnd = stack
    }
  }
  stack.next = stackStart
  var stackIn = null
  var stackOut = null

  yw = yi = 0

  var mul_sum = MUL_TABLE[radius]
  var shg_sum = SHG_TABLE[radius]

  for (y = 0; y < height; y++) {
    r_in_sum = g_in_sum = b_in_sum = r_sum = g_sum = b_sum = 0

    r_out_sum = radiusPlus1 * (pr = pixels[yi])
    g_out_sum = radiusPlus1 * (pg = pixels[yi + 1])
    b_out_sum = radiusPlus1 * (pb = pixels[yi + 2])

    r_sum += sumFactor * pr
    g_sum += sumFactor * pg
    b_sum += sumFactor * pb

    stack = stackStart

    for (i = 0; i < radiusPlus1; i++) {
      stack.r = pr
      stack.g = pg
      stack.b = pb
      stack = stack.next
    }

    for (i = 1; i < radiusPlus1; i++) {
      p = yi + ((widthMinus1 < i ? widthMinus1 : i) << 2)
      r_sum += (stack.r = (pr = pixels[p])) * (rbs = radiusPlus1 - i)
      g_sum += (stack.g = (pg = pixels[p + 1])) * rbs
      b_sum += (stack.b = (pb = pixels[p + 2])) * rbs

      r_in_sum += pr
      g_in_sum += pg
      b_in_sum += pb

      stack = stack.next
    }


    stackIn = stackStart
    stackOut = stackEnd
    for (x = 0; x < width; x++) {
      pixels[yi] = (r_sum * mul_sum) >> shg_sum
      pixels[yi + 1] = (g_sum * mul_sum) >> shg_sum
      pixels[yi + 2] = (b_sum * mul_sum) >> shg_sum

      r_sum -= r_out_sum
      g_sum -= g_out_sum
      b_sum -= b_out_sum

      r_out_sum -= stackIn.r
      g_out_sum -= stackIn.g
      b_out_sum -= stackIn.b

      p = (yw + ((p = x + radius + 1) < widthMinus1 ? p : widthMinus1)) << 2

      r_in_sum += (stackIn.r = pixels[p])
      g_in_sum += (stackIn.g = pixels[p + 1])
      b_in_sum += (stackIn.b = pixels[p + 2])

      r_sum += r_in_sum
      g_sum += g_in_sum
      b_sum += b_in_sum

      stackIn = stackIn.next

      r_out_sum += (pr = stackOut.r)
      g_out_sum += (pg = stackOut.g)
      b_out_sum += (pb = stackOut.b)

      r_in_sum -= pr
      g_in_sum -= pg
      b_in_sum -= pb

      stackOut = stackOut.next

      yi += 4
    }
    yw += width
  }


  for (x = 0; x < width; x++) {
    g_in_sum = b_in_sum = r_in_sum = g_sum = b_sum = r_sum = 0

    yi = x << 2
    r_out_sum = radiusPlus1 * (pr = pixels[yi])
    g_out_sum = radiusPlus1 * (pg = pixels[yi + 1])
    b_out_sum = radiusPlus1 * (pb = pixels[yi + 2])

    r_sum += sumFactor * pr
    g_sum += sumFactor * pg
    b_sum += sumFactor * pb

    stack = stackStart

    for (i = 0; i < radiusPlus1; i++) {
      stack.r = pr
      stack.g = pg
      stack.b = pb
      stack = stack.next
    }

    yp = width

    for (i = 1; i <= radius; i++) {
      yi = (yp + x) << 2

      r_sum += (stack.r = (pr = pixels[yi])) * (rbs = radiusPlus1 - i)
      g_sum += (stack.g = (pg = pixels[yi + 1])) * rbs
      b_sum += (stack.b = (pb = pixels[yi + 2])) * rbs

      r_in_sum += pr
      g_in_sum += pg
      b_in_sum += pb

      stack = stack.next

      if (i < heightMinus1) {
        yp += width
      }
    }

    yi = x
    stackIn = stackStart
    stackOut = stackEnd
    for (y = 0; y < height; y++) {
      p = yi << 2
      pixels[p] = (r_sum * mul_sum) >> shg_sum
      pixels[p + 1] = (g_sum * mul_sum) >> shg_sum
      pixels[p + 2] = (b_sum * mul_sum) >> shg_sum

      r_sum -= r_out_sum
      g_sum -= g_out_sum
      b_sum -= b_out_sum

      r_out_sum -= stackIn.r
      g_out_sum -= stackIn.g
      b_out_sum -= stackIn.b

      p = (x + (((p = y + radiusPlus1) < heightMinus1 ? p : heightMinus1) * width)) << 2

      r_sum += (r_in_sum += (stackIn.r = pixels[p]))
      g_sum += (g_in_sum += (stackIn.g = pixels[p + 1]))
      b_sum += (b_in_sum += (stackIn.b = pixels[p + 2]))

      stackIn = stackIn.next

      r_out_sum += (pr = stackOut.r)
      g_out_sum += (pg = stackOut.g)
      b_out_sum += (pb = stackOut.b)

      r_in_sum -= pr
      g_in_sum -= pg
      b_in_sum -= pb

      stackOut = stackOut.next

      yi += width
    }
  }

  return imageData
}