/**
 * Helps to manage observers.
 * Observers can be added, removed or fired through an instance of this class.
 */
export default class Observable {
  constructor() {
    this._handlers = []
  }

  get size() {
    return this._handlers.length
  }

  /**
   * Adds an observer, and returns a function to remove it.
   */
  add(handler) {
    this._handlers.push(handler)
    return () => this.remove(handler)
  }

  /**
   * Removes an observer.
   */
  remove(handler) {
    const i = this._handlers.indexOf(handler)
    if (i > -1) {
      this._handlers.splice(i, 1)
    }
  }

  /**
   * Removes all observers.
   */
  clear() {
    this._handlers = []
  }

  /**
   * Fires an event. All observers are called.
   */
  fire(event) {
    this._handlers.forEach(handler => handler(event))
  }
}
