import SymfonyForm from './BaseSymfonyForm'


export default class NewsletterForm extends SymfonyForm {
  constructor() {
    const container = document.getElementById('embo-form-newsletter')
    const form = container.querySelector('form')
    super(form)
  }
}
