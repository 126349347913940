import constants from 'embo/constants'
import dom from 'embo/utils/dom'
import * as events from 'embo/utils/events'
import animate from 'embo/utils/animate'


const TOGGLE_SELECTOR = '[data-toggle="collapse"]'
const CLASSES = {
  SHOW: 'show',
  COLLAPSED: 'collapsed',
}
const defaults = {
  duration: 350,
  easing: constants.easing.default,
  toggle: true,
}

const instances = new WeakMap()

export default function getInstance(el) {
  if (!instances.has(el)) {
    instances.set(el, new Collapse(el))
  }
  return instances.get(el)
}


function getTargetFromTrigger(trigger) {
  let target

  if (trigger.hasAttribute('aria-controls')) {
    return dom.id(trigger.getAttribute('aria-controls'))
  } else if (trigger.hasAttribute('data-target')) {
    return dom.qs(trigger.getAttribute('data-target'))
  } else {
    return dom.id(target.hash)
  }
}


class Collapse {

  constructor(el, options = {}) {
    this.trigger = typeof el === 'string' ? dom.qs(el) : el
    this.options = {...defaults, ...options}
    this.target = getTargetFromTrigger(this.trigger)
    this.isTransitioning = false
  }

  get isOpen() {
    return this.target.classList.contains(CLASSES.SHOW)
  }

  toggle() {
    if (this.isOpen) {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    if (this.isTransitioning || this.isOpen) return
    this.isTransitioning = true

    const {duration, easing} = this.options
    animate(this.target, 'slideDown', {duration, easing}).then(() => {
      this.target.classList.remove(CLASSES.COLLAPSED)
      this.target.classList.add(CLASSES.SHOW)
      this.target.setAttribute('aria-expanded', true)
      this.trigger.classList.remove(CLASSES.COLLAPSED)
      this.trigger.setAttribute('aria-expanded', true)
      this.isTransitioning = false
    })
  }

  hide() {
    if (this.isTransitioning || !this.isOpen) return
    this.isTransitioning = true

    const {duration, easing} = this.options
    animate(this.target, 'slideUp', {duration, easing}).then(() => {
      this.target.classList.remove(CLASSES.SHOW)
      this.target.classList.add(CLASSES.COLLAPSED)
      this.target.setAttribute('aria-expanded', false)
      this.trigger.classList.add(CLASSES.COLLAPSED)
      this.trigger.setAttribute('aria-expanded', false)
      this.isTransitioning = false
    })
  }
}


events.on(document.body, 'click', TOGGLE_SELECTOR, ({event, target}) => {
  if (!target.dataset.target) {
    event.preventDefault()
  }

  getInstance(target).toggle()
})
