import dom from 'embo/utils/dom'
import LRUCache, {CacheMiss} from 'embo/utils/cache/lru'
import Exception from 'embo/utils/Exception'


export class ResponseError extends Exception {
  constructor(response, result) {
    const {url, status, statusText} = response
    super(`ResponseError: ${status} (${statusText}) fetching ${url}`)

    this.response = response
    this.result = result
  }
}


function parseResult(html) {
  const result = dom.el('div', {html})
  return {
    title: dom.qs('#ajax-title', result).innerHTML.trim(),
    layout: dom.qs('#ajax-layout', result),
  }
}


export default class PageLoader {
  constructor(debug = false) {
    this._debug = debug
    // keep 32 pages for 20 minutes
    this._cache = new LRUCache(32, 20 * 60 * 1000)
  }

  load(url) {
    if (this._cache.has(url)) {
      const result = this._cache.get(url)
      return Promise.resolve(parseResult(result))
    }
    return fetch(url, {
      headers: {'X-Requested-With': 'XMLHttpRequest'},
    }).then(response => {
      if (response.ok) {
        return response.text()
      }
      // Server returned error response
      // if we're in debug mode, just throw
      if (this._debug) throw new ResponseError(response)
      // else, Symfony returns a parsable error page, so parse it and rethrow
      return response.text().then(html => {
        throw new ResponseError(response, this._processResponse(url, html))
      })
    }).then(html => this._processResponse(url, html))
  }

  needsFetching(url) {
    return !this._cache.has(url)
  }

  save(url) {
    const html = `
            <div id="ajax-title">${document.title}</div>
            <div id="ajax-layout">${dom.id('layout-wrapper').innerHTML}</div>
        `
    this._cache.set(url, html)
  }

  _processResponse(url, html) {
    this._cache.set(url, html)
    return parseResult(html)
  }
}
