/**
 * Helper with all things Timer.
 */
export class Timer {

  /**
   * @param {!Window} win
   */
  constructor(win) {
    /** @const {!Window} */
    this.win = win
  }

  /**
   * Returns the current EPOC time in milliseconds.
   * @return {number}
   */
  now() {
    return Date.now()
  }

  /**
   * returns a promise that resolves after the specified delay.
   *
   * @param {Number} delay
   * @returns {Promise}
   */
  delay(delay = 0) {
    return new Promise(resolve => this.win.setTimeout(resolve, delay))
  }

  /**
   * Returns a promise that rejects after the specified delay.
   * Optionally, this method can take racePromise parameter.
   * In this case, the resulting promise will either:
   * reject when the specified delay expires,
   * or resolve based on the racePromise, whichever happens first.
   *
   * @param {Number} delay
   * @param {!Promise<RESULT>|undefined} racePromise
   * @return {!Promise<RESULT>}
   * @template RESULT
   */
  timeout(delay, racePromise) {
    const timeoutPromise = this.delay(delay).then(() => Promise.reject())
    if (!racePromise) {
      return timeoutPromise
    }
    return Promise.race([timeoutPromise, racePromise])
  }
}


const timer = new Timer(window)
export default timer
