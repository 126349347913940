import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  setVisibilityFilter,
  selectVisibilityFilter,
  selectVisibleEvents,
} from 'embo/state/minical'

import FilterList from './FilterList'
import EventList from './EventList'
import {eventShape} from './propTypes'


const mapStateToProps = state => ({
  visibilityFilter: selectVisibilityFilter(state),
  visibleEvents: selectVisibleEvents(state),
})
const mapDispatchToProps = {
  setVisibilityFilter,
}

const Minical = ({
  visibilityFilter,
  visibleEvents,
  setVisibilityFilter,
}) => {

  const handleFilterChanged = useCallback(
    filter => setVisibilityFilter(filter),
    [setVisibilityFilter]
  )

  return (
    <div className='embo-minical'>
      <header className='page-header embo-minical__header'>
        <h2>{'Agenda'}</h2>
        <FilterList
          activeFilter={visibilityFilter}
          onChange={handleFilterChanged}
        />
      </header>
      <div className='embo-minical__events'>
        <EventList events={visibleEvents}/>
      </div>
    </div>
  )
}
Minical.propTypes = {
  visibilityFilter: PropTypes.string.isRequired,
  visibleEvents: PropTypes.arrayOf(eventShape).isRequired,
  setVisibilityFilter: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Minical)
