import React, {useCallback, useEffect, useRef} from 'react'
import {createPortal} from 'react-dom'
import PropTypes from 'prop-types'
import createFocusTrap from 'focus-trap'

import Icon from '../common/Icon'
import Poster from './Poster'
import {PosterType} from './propTypes'


const Modal = ({
  poster,
  onClose,
  onAuthorClick,
}) => {
  const container = useRef(null)
  useEffect(() => {
    const focusTrap = createFocusTrap(container.current, {
      initialFocus: container.current,
    })
    focusTrap.activate()

    return () => focusTrap.deactivate()
  }, [])

  const handleKeyDown = useCallback(
    event => {
      if (event.key === 'Escape') {
        onClose()
      }
    },
    [onClose]
  )

  return createPortal(
    <div ref={container} className="embo-posters__modal" tabIndex="0" role="dialog" onKeyDown={handleKeyDown}>
      <Poster poster={poster} onAuthorClick={onAuthorClick}/>
      <button className="embo-posters__modal__close" onClick={onClose}>
        <Icon name="close" width={48} height={48}/>
        <span className="sr-only">Fermer</span>
      </button>
    </div>,
    document.body
  )
}
Modal.propTypes = {
  poster: PosterType.isRequired,
  onClose: PropTypes.func.isRequired,
  onAuthorClick: PropTypes.func.isRequired,
}
export default Modal
