import {createSelector, createSelectorCreator, defaultMemoize} from 'reselect'

import DateRange from 'embo/utils/date/Range'

import {
  selectEvents,
  selectPerformers,
} from './entities'


//
// Filter types
// --------------------------------------------------------------------------------------------------------------------

export const FILTER_ALL = 'embo/minical/FILTER_ALL'
export const FILTER_TODAY = 'embo/minical/FILTER_TODAY'
export const FILTER_THIS_WEEK = 'embo/minical/FILTER_THIS_WEEK'
export const FILTER_THIS_MONTH = 'embo/minical/FILTER_THIS_MONTH'
export const FILTER_NEXT_WEEK = 'embo/minical/FILTER_NEXT_WEEK'
export const FILTER_NEXT_MONTH = 'embo/minical/FILTER_NEXT_MONTH'


//
// Action creators
// --------------------------------------------------------------------------------------------------------------------

const SET_VISIBILITY_FILTER = 'embo/minical/SET_VISIBILITY_FILTER'

export function setVisibilityFilter(filter) {
  return {type: SET_VISIBILITY_FILTER, payload: filter}
}


const SET_CUSTOM_FILTER = 'embo/minical/CUSTOM_FILTER'

export function setCustomFilter(start, end) {
  return {type: SET_CUSTOM_FILTER, payload: {start, end}}
}


//
// Reducers
// --------------------------------------------------------------------------------------------------------------------

export default function reducer(state = FILTER_ALL, action = {}) {
  const {type, payload} = action
  switch (type) {
    case SET_VISIBILITY_FILTER:
      if (state === payload) return state
      return payload
    case SET_CUSTOM_FILTER: {
      return payload
    }
    default:
      return state
  }
}


//
// Selectors
// --------------------------------------------------------------------------------------------------------------------

function compareStartDate(a, b) {
  a = a.startDate
  b = b.startDate
  return a > b ? 1 : a < b ? -1 : 0
}


const createDateEqualSelector = createSelectorCreator(
  defaultMemoize,
  (a, b) => a.getTime() === b.getTime(),
)
const selectDeadline = createDateEqualSelector(state => {
  const deadline = new Date()
  let minutes = deadline.getMinutes()
  minutes -= minutes % 5
  deadline.setHours(deadline.getHours(), minutes, 0, 0)
  return deadline
})


const selectUpcomingEvents = createSelector(
  selectEvents,
  selectDeadline,
  selectPerformers,
  (events, deadline, performers) => Object.values(events)
    .filter(event => event.endDate > deadline)
    .map(event => ({
      ...event,
      performer: event.performer.map(id => performers[id]),
    }))
    .sort(compareStartDate),
)

export const selectVisibilityFilter = state => state.minical

export const selectVisibleEvents = createSelector(
  [selectUpcomingEvents, selectVisibilityFilter],
  (events, filter) => {
    let targetRange
    switch (filter) {
      case FILTER_TODAY:
        targetRange = DateRange.today()
        break
      case FILTER_THIS_WEEK:
        targetRange = DateRange.thisWeek(1)
        break
      case FILTER_NEXT_WEEK: {
        const {start} = DateRange.thisWeek(1)
        start.setDate(start.getDate() + 7)
        targetRange = DateRange.week(start, 1)
        break
      }
      case FILTER_THIS_MONTH:
        targetRange = DateRange.thisMonth()
        break
      case FILTER_NEXT_MONTH: {
        const {start} = DateRange.thisMonth()
        start.setMonth(start.getMonth() + 1)
        targetRange = DateRange.month(start)
        break
      }
      case FILTER_ALL:
        return events
      default:
        break
    }
    if (filter.start && filter.end) {
      targetRange = new DateRange(filter.start, filter.end)
    }
    return events.filter(event => {
      const eventRange = new DateRange(event.startDate, event.endDate)
      return eventRange.intersects(targetRange)
    })
  },
)
