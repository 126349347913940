import React, {useState, useCallback} from 'react'
import PropTypes from 'prop-types'

import BackgroundBlur from '../common/images/BackgroundBlur'
import Image from './Image'
import Icon from '../common/Icon'


const FORMATS = [
  'default_carousel_xs',
  'default_carousel_sm',
  'carousel_xs',
  'carousel_sm',
]


const getSizes = img => {
  const {width, height} = img
  // In the event layout, we're in .container > .col-md-8
  // 100vw by default, then 2/3 of the container from 992px and up
  return `
    ((min-aspect-ratio: ${width}/${height}) and (min-width:992px)) calc(100vh * ${width}/${height} * 2/3),
    (min-aspect-ratio: ${width}/${height}) calc(100vh * ${width}/${height})
  `
}

const Placeholder = React.forwardRef(({image, onPlay}, ref) => {
  const [backgroundImage, setBackgroundImage] = useState(null)
  const handleImageLoaded = useCallback(
    event => setBackgroundImage(event.target.currentSrc),
    [setBackgroundImage]
  )
  const handlePlayClicked = useCallback(() => onPlay(), [onPlay])

  const bg = backgroundImage ? <BackgroundBlur image={backgroundImage}/> : null

  return (
    <div ref={ref} className="embo-carousel__placeholder">
      {bg}
      <Image image={image} sizes={getSizes(image)} formats={FORMATS} onLoad={handleImageLoaded}/>
      <button className="embo-carousel__placeholder__play" onClick={handlePlayClicked}>
        <span className="sr-only">Voir les images</span>
        <span>
          <Icon name="av-play" width={48} height={48}/>
        </span>
      </button>
    </div>
  )
})
Placeholder.displayName = 'Placeholder'
Placeholder.propTypes = {
  image: PropTypes.object.isRequired,
  onPlay: PropTypes.func.isRequired,
}

export default Placeholder
