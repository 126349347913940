import React from 'react'
import ReactDOM from 'react-dom'

import store from 'embo/store'
import {loadEvent} from 'embo/api/dom'
import {addEntities} from 'embo/state/entities'
import {enqueue, setCurrentTrack, presentTrack} from 'embo/state/playlist'
import {
  setPlaylistVisible,
  setPlaying,
} from 'embo/state/player'

import Player from 'embo/components/player/App'

import {on} from 'embo/utils/events'


function enqueueEvent(eventId) {
  const {entities} = store.getState()
  // event might be loaded, but not necessarily enqueued
  if (entities.event[eventId]) {
    const trackIds = Object.keys(entities.track).filter(trackId => {
      return entities.track[trackId].event === eventId
    })
    store.dispatch(enqueue(trackIds))
    return Promise.resolve(trackIds[0])
  }
  return loadEvent(eventId).then(data => {
    store.dispatch(addEntities(data.entities))
    const trackIds = Object.keys(data.entities.track)
    store.dispatch(enqueue(trackIds))
    return trackIds[0]
  })
}


function enqueuePerformance(performanceId, eventId) {
  const {entities} = store.getState()
  // performer might be loaded, but not necessarily enqueued
  if (entities.performer[performanceId]) {
    const trackIds = entities.performer[performanceId].track
    store.dispatch(enqueue(trackIds))
    return Promise.resolve(trackIds[0])
  }
  return loadEvent(eventId).then(data => {
    store.dispatch(addEntities(data.entities))
    const performer = data.entities.performer[performanceId]
    const trackIds = performer.track
    store.dispatch(enqueue(trackIds))
    return trackIds[0]
  })
}


function showTrack(trackId) {
  store.dispatch(setPlaylistVisible(true))
  store.dispatch(presentTrack(trackId))
}

function playTrack(trackId) {
  store.dispatch(setCurrentTrack(trackId))
  showTrack(trackId)
  store.dispatch(setPlaying(true))
}


export default function initializePlayer() {

  ReactDOM.render(
    <Player store={store}/>,
    document.getElementById('embo-main-player'),
  )

  const {body} = document

  on(body, 'click', '.btn-event-enqueue', ({target}) => {
    const {eventId} = target.dataset
    enqueueEvent(eventId).then(showTrack)
  })
  on(body, 'click', '.btn-event-play', ({target}) => {
    const {eventId} = target.dataset
    enqueueEvent(eventId).then(playTrack)
  })
  on(body, 'click', '.btn-performance-enqueue', ({target}) => {
    const {eventId, performanceId} = target.dataset
    enqueuePerformance(performanceId, eventId).then(showTrack)
  })
  on(body, 'click', '.btn-performance-play', ({target}) => {
    const {eventId, performanceId} = target.dataset
    enqueuePerformance(performanceId, eventId).then(playTrack)
  })

}
