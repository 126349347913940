import dom from 'embo/utils/dom'
import vsync from 'embo/utils/vsync'

const CSS_RULE = `
    :focus, :active {outline: 0 !important}
    ::-moz-focus-inner {border: 0 !important}
`

/**
 * Removes focus outlines in an accessible way.
 * We removes them when we detect mouse usage,
 * and re-adds them when we detect keyboard usage.
 */
export default function() {
  let outlinesRemoved = false
  const style = dom.el('style', {html: CSS_RULE})
  style.disabled = true
  document.head.appendChild(style)

  document.addEventListener('mousedown', () => {
    if (!outlinesRemoved) {
      outlinesRemoved = true
      vsync.mutate(() => style.disabled = false)
    }
  })
  document.addEventListener('keydown', () => {
    if (outlinesRemoved) {
      outlinesRemoved = false
      vsync.mutate(() => style.disabled = true)
    }
  })
}
