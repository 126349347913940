/**
 * The models don't map exactly to the Doctrine entities,
 * but rather to the schema.org schemas.
 * This is intentional: we put the JSON+LD metadata directly in the DOM for SEO,
 * and just grab it from there.
 *
 * For example events use the http://schema.org/MusicEvent schema,
 * so event.performances becomes event.performer (notice the singular form),
 * etc...
 *
 * @see the EmboBundle\Serializer\JsonLd\*Serializer classes for details.
 */

import {schema, normalize} from 'normalizr'

//
// Schema definitions
// --------------------------------------------------------------------------------------------------------------------

const trackEntity = new schema.Entity('track', {}, {idAttribute: '@id'})
const performanceEntity = new schema.Entity('performer', {track: new schema.Array(trackEntity)}, {idAttribute: '@id'})
const eventEntity = new schema.Entity('event', {performer: new schema.Array(performanceEntity)}, {idAttribute: '@id'})
const posterEntity = new schema.Entity('poster', {}, {idAttribute: '@id'})

const eventResponse = new schema.Object({event: eventEntity})
const eventListResponse = new schema.Object({events: new schema.Array(eventEntity)})
const posterListResponse = new schema.Object({posters: new schema.Array(posterEntity)})


//
// Normalization functions
// --------------------------------------------------------------------------------------------------------------------

function normalizeHydraId(entity) {
  if (entity['@id']) {
    entity.id = entity['@id']
  }
}

function normalizeEventStatus(status) {
  switch (status) {
    case 'http://schema.org/EventCancelled':
      return 'canceled'
    case 'http://schema.org/EventPostponed':
      return 'postponed'
    case 'http://schema.org/EventRescheduled':
      return 'rescheduled'
    default:
      return 'confirmed'
  }
}

function normalizeEvent(event) {
  normalizeHydraId(event)
  // We use alternateName to store the real event's title
  event.name = event.alternateName
  event.startDate = new Date(event.startDate)
  event.endDate = new Date(event.endDate)
  event.doorTime = new Date(event.doorTime)
  event.status = normalizeEventStatus(event.eventStatus)

  // Add-back bidirectional relationships
  event.performer.forEach(performer => {
    normalizeHydraId(performer)
    performer.event = event.id
    performer.track.forEach(track => {
      normalizeHydraId(track)
      track.performer = performer.id
      track.event = event.id
    })
    performer.image.forEach(normalizeHydraId)
  })
}


//
// Loaders
// --------------------------------------------------------------------------------------------------------------------

function loadScript(selector, node = document) {
  return new Promise((resolve, reject) => {
    const script = node.querySelector(selector)
    if (!script) {
      reject(new Error(`Element "${selector}" not found`))
    }
    try {
      const data = JSON.parse(script.textContent)
      resolve(data)
    } catch (err) {
      console.warn('Invalid JSON', script.textContent)
      reject(err)
    }
  })
}


export function loadUpcomingEvents() {
  return loadScript('script#upcoming-events-data').then(events => {
    events.forEach(normalizeEvent)
    return normalize({events}, eventListResponse)
  })
}

export function loadEvent(id) {
  const selector = `script.event-data[data-event-id="${id}"]`
  return loadScript(selector).then(event => {
    normalizeEvent(event)
    return normalize({event}, eventResponse)
  })
}

export function loadPerformance(eventId, performanceId) {
  return loadEvent(eventId).then(({entities: {performer}}) => {
    if (!performer || !performer[performanceId]) {
      throw new Error(`Performance not found: ${performanceId}`)
    }
    return performer[performanceId]
  })
}

export function loadPost(id) {
  const selector = `script.post-data[data-post-id="${id}"]`
  return loadScript(selector).then(post => {
    post.image.forEach(normalizeHydraId)
    return post
  })
}

export function loadPosters() {
  return loadScript('script#posters-data').then(posters => {
    posters.forEach(poster => {
      normalizeHydraId(poster)
      normalizeHydraId(poster.image)
    })
    return normalize({posters}, posterListResponse)
  })
}

export function loadStaticCarousel(node) {
  return loadScript('script[type="application/ld+json"]', node).then((images) => {
    images.forEach(image => {
      normalizeHydraId(image)
    })
    return images
  })
}
