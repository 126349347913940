/**
 * Check if `href` has the same origin as the current location.
 *
 * @param href A fully resolved url (such as returned by a.href property)
 *
 * @returns {boolean}
 */
export function isSameOrigin(href) {
  const {protocol, hostname, port} = location
  const origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`

  return href && href.startsWith(origin)
}
