import React from 'react'
import PropTypes from 'prop-types'

import Layout from './layouts/Justified'
import Thumbnail from './Thumbnail'
import {PosterType} from './propTypes'


const {min, max, round} = Math


const getLayoutOptions = ({height}) => {
  // ideal thumbnail height: viewport.height / 2,
  // minus size of floating elements (navbar, player)
  const idealHeight = round(height / 2) - 50
  return {
    maxRatio: 1.1,
    margin: 8,
    maxHeight: min(480 * 0.9, max(240, idealHeight)),
  }
}

const isItemInViewport = (listTop, viewport, item) => {
  const itemTopFromViewport = listTop + item.y
  const itemBottomFromViewport = itemTopFromViewport + item.height

  return itemBottomFromViewport > 0 && itemTopFromViewport < viewport.scrollTop + viewport.height
}

const ThumbnailList = ({
  posters,
  top,
  width,
  viewport,
  onItemClicked,
}) => {
  const layout = new Layout(posters, getLayoutOptions(viewport))
  const thumbnails = layout.render(width)

  let totalHeight = 0

  const items = thumbnails.map(item => {
    const {data, x, y, width, height} = item
    const bottom = y + height
    if (bottom > totalHeight) {
      totalHeight = bottom
    }
    return (
      <Thumbnail key={data.id}
        poster={data}
        visible={isItemInViewport(top, viewport, item)}
        style={{
          width,
          height,
          transform: `translate3d(${x}px, ${y}px, 0)`,
        }}
        onClick={onItemClicked}
      />
    )
  })

  return (
    <div className="embo-posters__thumbnails" style={{minHeight: round(totalHeight)}}>
      {items}
    </div>
  )
}
ThumbnailList.propTypes = {
  posters: PropTypes.arrayOf(PosterType).isRequired,
  // top & width of the parent component
  top: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  // scrollTop & height of the viewport
  viewport: PropTypes.shape({
    scrollTop: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  onItemClicked: PropTypes.func.isRequired,
}

export default ThumbnailList
