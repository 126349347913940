import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import router from 'embo/routing'

import TrackInfoButton from './TrackInfoButton'
import ErrorInfo from './ErrorInfo'
import {TrackType} from './propTypes'


const EmptyState = () => {
  const {base} = router
  return (
    <div className="embo-player__titlebar embo-player__titlebar--is-empty" role="status">
      <div className="embo-player__titlebar__title">
        {'Ajoutes des pistes en visitant '}
        <a href={`${base}/events/upcoming`}>{"l'agenda"}</a>
        {' ou les '}
        <a href={`${base}/events`}>archives</a>.
      </div>
    </div>
  )
}

const TitleBar = ({
  track,
  onTrackInfoRequest,
}) => {
  if (!track) return <EmptyState/>

  const {performer, name, error} = track
  const handleTrackInfoClicked = useCallback(
    () => onTrackInfoRequest(track.id),
    [track, onTrackInfoRequest]
  )
  const classes = cx('embo-player__titlebar', {
    'embo-player__titlebar--has-error': !!error,
  })
  const label = performer ? `${performer.name} - ${name}` : name
  const title = performer ? `"${name}", by ${performer.name}` : name

  return (
    <div className={classes} role="status" title={title}>
      <TrackInfoButton onClick={handleTrackInfoClicked}/>
      <div className="embo-player__titlebar__title" onClick={handleTrackInfoClicked}>
        {label}
      </div>
      {error && <ErrorInfo error={error}/>}
    </div>
  )
}
TitleBar.propTypes = {
  track: TrackType,
  onTrackInfoRequest: PropTypes.func.isRequired,
}
export default React.memo(TitleBar, (prevProps, nextProps) => {
  return nextProps.track === prevProps.track
})
