const NativePrototype = Element.prototype

const ElementPrototype = {

  closest(selector) {
    for (let current = this; current; current = current.parentElement) {
      if (current.matches(selector)) {
        return current
      }
    }
    return null
  },
}

if (typeof NativePrototype.matches !== 'function') {
  const matchMethod = [
    'matchesSelector',
    'webkitMatchesSelector',
    'msMatchesSelector',
  ].filter(m => typeof NativePrototype[m] === 'function')[0]

  if (matchMethod) {
    NativePrototype.matches = NativePrototype[matchMethod]
  } else {
    NativePrototype.matches = function matches(selector) {
      const elements = (this.document || this.ownerDocument).querySelectorAll(selector)
      let i = 0
      while (elements[i] && elements[i] !== this) {
        i++
      }
      return !!elements[i]
    }
  }
}

Object.keys(ElementPrototype).forEach(method => {
  if (NativePrototype[method]) return
  NativePrototype[method] = ElementPrototype[method]
})
