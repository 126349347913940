const hasOwnProp = Object.prototype.hasOwnProperty

export default function deepFreeze(obj) {
  const isFunction = typeof obj === 'function'
  Object.freeze(obj)
  Object.getOwnPropertyNames(obj).forEach(prop => {
    if (hasOwnProp.call(obj, prop)
      && (isFunction ? prop !== 'caller' && prop !== 'callee' && prop !== 'arguments' : true)
      && obj[prop] !== null
      && (typeof obj[prop] === 'object' || typeof obj[prop] === 'function')
      && !Object.isFrozen(obj[prop])
    ) {
      deepFreeze(obj[prop])
    }
  })

  return obj
}
