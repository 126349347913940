import React, {useCallback} from 'react'
import PropTypes from 'prop-types'

import {
  FILTER_ALL,
  FILTER_TODAY,
  FILTER_THIS_WEEK, FILTER_NEXT_WEEK,
  FILTER_THIS_MONTH, FILTER_NEXT_MONTH,
} from 'embo/state/minical'

import FilterChoice from './FilterChoice'
import Icon from '../common/Icon'


const CHOICES = {
  [FILTER_ALL]: 'Tout afficher',
  [FILTER_TODAY]: "Aujourd'hui",
  [FILTER_THIS_WEEK]: 'Cette semaine',
  [FILTER_NEXT_WEEK]: 'La semaine prochaine',
  [FILTER_THIS_MONTH]: 'Ce mois-ci',
  [FILTER_NEXT_MONTH]: 'Le mois prochain',
}

const FilterList = ({
  onChange,
  activeFilter,
}) => {

  const handleFilterSelected = useCallback(
    filter => onChange(filter),
    [onChange]
  )

  return (
    <div className="dropdown">
      <button
        className="btn btn-sm btn-primary dropdown-toggle"
        id="embo-minical-dropdown"
        data-toggle="dropdown"
        aria-haspopup="true"
      >
        <Icon name="search" width={24} height={24}/>
        <span className="sr-only">{'Filtrer les évènements à venir'}</span>
      </button>
      <div className="dropdown-menu dropdown-menu-right"
        role="menu"
        aria-labelledby="embo-minical-dropdown"
      >
        {Object.keys(CHOICES).map(filter => (
          <FilterChoice
            key={filter}
            filter={filter}
            label={CHOICES[filter]}
            active={filter === activeFilter}
            onSelect={handleFilterSelected}
          />
        ))}
      </div>
    </div>
  )
}
FilterList.propTypes = {
  onChange: PropTypes.func.isRequired,
  activeFilter: PropTypes.oneOf(Object.keys(CHOICES)).isRequired,
}

export default FilterList
