/**
 * Base Exception class to be extended by all others in order to preserve instanceof semantics.
 *
 * What the native Error class constructor essentially does is this:
 *
 * <code>
 * class MyError {
 *   constructor () {
 *     // the 'this' here would be your `MyError`, BUT:
 *     // Ignore `this` and returns an entirely new Error
 *     return new Error()
 *   }
 * }
 * </code>
 *
 * @see https://phabricator.babeljs.io/T3083
 */
function Exception(msg, ...args) {
  Error.apply(this, [msg, ...args])
  this.message = msg
}

Exception.prototype = Object.create(Error.prototype)
Object.setPrototypeOf(Exception, Error)

export default Exception
