import store from './store'
import router from './routing'

import {loadUpcomingEvents} from './api/dom'
import {addEntities} from './state/entities'
import {enqueue} from './state/playlist'

import removeOutlines from './ui/outlines'
import initializePlayer from './ui/player'

import './bootstrap/dropdown'
import './bootstrap/collapse'

import './ui/modal/morph'

import initializeNavbar from './ui/navbar'
import initializeInputs from './ui/form/inputs'
import initializeSearchForms from './ui/form/searchForms'
import NewsletterForm from './ui/form/NewsletterForm'
import {on} from './utils/events'
import {trackEvent, trackSocialAction, trackException} from './utils/analytics'

/**
 * Initialize app on dom ready.
 *
 * Further stuff happens in route handlers, see ./routing/index.js
 */
document.addEventListener('DOMContentLoaded', () => {

  // passed by the templates
  const {Embo} = window

  initializeNavbar()
  removeOutlines()

  const {pathname, hostname} = location
  if (pathname.indexOf('/admin/') === -1 || hostname.indexOf('admin') === -1) {
    router.start({dispatch: true})
  }

  loadUpcomingEvents().then(({entities, result: {events}}) => {
    if (!events.length) return
    store.dispatch(addEntities(entities))
    if (!entities.track) return
    const trackIds = Object.keys(entities.track)
    store.dispatch(enqueue(trackIds))
  }).then(() => {
    initializePlayer()
  })

  // initialize forms
  initializeInputs()
  initializeSearchForms()
  new NewsletterForm()
})

//
// Analytics
// -----------------------------------------------------------------------------------------------------------------

window.onerror = (msg, url, line, col, error) => {
  window.ga('send', 'exception', {
    exDescription: `${msg} in ${url}`,
    exFatal: true,
  })
}

window.addEventListener('unhandledrejection', ({reason}) => {
  let message = ''
  if (reason instanceof Error) {
    message = reason.toString()
  } else {
    try {
      message = JSON.stringify(reason)
    } catch (err) {
      message = reason.toString()
    }
  }

  window.ga('send', 'exception', {
    exDescription: `Unhandled promise rejection. Reason: ${message}`,
    exFatal: true,
  })
})

on(document.body, 'click', 'a[data-social-action]', ({target}) => {
  const {socialNetwork, socialAction, socialTarget} = target.dataset
  trackSocialAction(socialNetwork, socialAction, socialTarget)
})
document.addEventListener('submit', ({target}) => trackEvent('Form', 'submit', target.action))
