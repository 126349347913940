import React from 'react'
import {render, unmountComponentAtNode} from 'react-dom'

import Spinner from 'embo/components/common/spinner/Pentagram'

import dom from 'embo/utils/dom'
import vsync from 'embo/utils/vsync'
import timer from 'embo/utils/timer'
import * as events from 'embo/utils/events'
import animate from 'embo/utils/animate'
import icon from './icon'


const noop = () => {
}


export default class PageSpinner {
  constructor() {
    this.container = dom.el('div', {className: 'page-spinner'}, {display: 'none'})
    this.spinner = dom.el('div', {className: 'page-spinner__spinner'})
    this.messageBox = dom.el('div', {className: 'page-spinner__msg'})

    vsync.mutate(() => {
      this._renderPentagram()
      this.container.appendChild(this.spinner)
      this.container.appendChild(this.messageBox)
      document.body.appendChild(this.container)
    })
  }

  show() {
    return vsync
      .mutate(() => this._renderPentagram(false))
      .then(() => animate(this.container, 'fadeIn', {duration: 300, display: 'flex'}))
  }

  hide() {
    return animate(this.container, 'fadeOut', {duration: 200})
      .then(() => unmountComponentAtNode(this.spinner))
  }

  /**
   * Shows an error message when an error cannot be resolved to an error page.
   *
   * @param {String|Error} msg
   * @param {Number} timeout
   *
   * @returns {Promise.<T>}
   */
  showFatalError(msg, timeout = 10000) {
    return vsync.mutate(() => {
      this.messageBox.innerHTML = `${icon('warning')} ${msg}`
      this.container.classList.add('page-spinner--has-error')
      this._renderPentagram(true)
    })
      .then(() => events.one(this.container, 'click', timeout).catch(noop))
      .then(() => this.hide())
      .then(() => vsync.mutate(() => {
        this.messageBox.innerHTML = ''
        this.container.classList.remove('page-spinner--has-error')
        this._renderPentagram(false)
      }))
  }

  /**
   * Displays spinner's error state during the time given by timeout.
   * Use before resolving an error to a valid error page.
   *
   * @param timeout
   * @returns {Promise.<T>}
   */
  showTransientError(timeout = 0) {
    return vsync.mutate(() => {
      this.container.classList.add('page-spinner--has-error')
      this._renderPentagram(true)
    })
      .then(() => timer.delay(timeout))
      .then(() => this.hide())
      .then(() => vsync.mutate(() => {
        this.container.classList.remove('page-spinner--has-error')
        this._renderPentagram(false)
      }))
  }

  _renderPentagram(error = false) {
    return render(<Spinner size="lg" error={error} spinning={!error}/>, this.spinner)
  }
}
