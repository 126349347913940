/**
 * Remove URL encoding from the given `value`.
 * Accommodates whitespace in both x-www-form-urlencoded and regular percent-encoded form.
 *
 * @param {string} value component to decode
 */
export default function decodeURLEncodedURIComponent(value) {
  if (typeof value !== 'string') {
    return value
  }
  return decodeURIComponent(value.replace(/\+/g, ' '))
}
