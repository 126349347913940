import {
  SET_CURRENT_POSTER,
  selectCurrentPoster,
} from 'embo/state/posters'
import {
  SET_PLAYING,
} from 'embo/state/player'
import {
  AUDIO_ERROR,
  selectCurrentTrack,
} from 'embo/state/playlist'

import {trackEvent, trackException} from 'embo/utils/analytics'


/**
 * Middleware for handling action analytics.
 * Called if the action includes a truthy meta.analytics property.
 * The middleware is called with the action and the state as parameters.
 *
 * @param {Function} track The analytics tracking function.
 *
 * @returns {object}
 */
const createMiddleware = track => store => next => action => {
  const returnValue = next(action)

  if (!action || !action.meta || !action.meta.analytics) {
    return returnValue
  }

  track(action, store.getState())

  return returnValue
}

const tracker = (action, state) => {
  const {type, payload} = action
  switch (type) {
    case SET_CURRENT_POSTER: {
      const poster = selectCurrentPoster(state)
      if (!poster) return
      trackEvent('Poster', 'view', `«${poster.name}» par ${poster.author}`)
      break
    }
    case SET_PLAYING: {
      const track = selectCurrentTrack(state)
      trackEvent('Audio', 'play', `«${track.name}» par ${track.performer.name}`)
      break
    }
    case AUDIO_ERROR: {
      const {trackId, error} = payload
      const track = state.entities.track[trackId]
      const performer = state.entities.performer[track.performer]
      const errorMsg = `${error.constructor.name} [${error.code}]: ${error.toString()}`
      trackEvent('Audio', 'error', `${performer.name} - ${track.name} | ${errorMsg}`)
      break
    }
  }
}

export default createMiddleware(tracker)
