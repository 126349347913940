/**
 * The structure that combines position and size for an element. The exact
 * interpretation of position and size depends on the use case.
 *
 * @typedef {{
 *   top: number,
 *   bottom: number,
 *   left: number,
 *   right: number,
 *   width: number,
 *   height: number
 * }}
 */
let LayoutRectDef


/**
 * Creates a layout rect based on the left, top, width and height parameters in that order.
 *
 * @param {number} left
 * @param {number} top
 * @param {number} width
 * @param {number} height
 * @return {!LayoutRectDef}
 */
export function layoutRect(left, top, width, height) {
  return {left, top, width, height, bottom: top + height, right: left + width}
}


/**
 * Creates a layout rect based on the element.
 * This calls getBoundingClientRect behind the scene, so should be wrapped in a vsync measure task.
 *
 * @param {!HTMLElement} el
 * @return {!LayoutRectDef}
 */
export function domRect(el) {
  const rect = el.getBoundingClientRect()
  return {
    left: rect.left,
    top: rect.top,
    width: rect.width,
    height: rect.height,
    bottom: rect.top + rect.height,
    right: rect.left + rect.width,
  }
}


/**
 * Returns roughly the same result as domRect(), but without transforms applied.
 * /!\ ATM this implementation assumes box-sizing: border-box on ALL elements.
 * /!\ This is MUCH slower than domRect, so should be used ONLY when we need
 * an element's bounding box without transforms.
 *
 * @param {!HTMLElement}
 * @return {!LayoutRectDef}
 */
export function offsetRect(el) {
  const {body, documentElement} = document
  const width = el.offsetWidth
  const height = el.offsetHeight

  let left = el.offsetLeft
  let top = el.offsetTop
  let offsetParent = el.offsetParent
  let prevOffsetParent = el

  if (el === body) {
    return layoutRect(left, top, width, height)
  }

  while ((el = el.parentNode) && el !== body && el !== documentElement) {
    top -= el.scrollTop
    left -= el.scrollLeft
    if (el === offsetParent) {
      top += el.offsetTop
      left += el.offsetLeft
      prevOffsetParent = offsetParent
      offsetParent = el.offsetParent
    }
  }
  return layoutRect(left, top, width, height)
}


/**
 * Returns true if the specified two rects overlap by a single pixel.
 * @param {!LayoutRectDef} r1
 * @param {!LayoutRectDef} r2
 * @return {boolean}
 */
export function rectOverlaps(r1, r2) {
  return (
    r1.top <= r2.bottom
    && r2.top <= r1.bottom
    && r1.left <= r2.right
    && r2.left <= r1.right
  )
}

/**
 * Expand the layout rect using multiples of width and height.
 *
 * @param {!LayoutRectDef} rect Original rect.
 * @param {number} dw Expansion in width, specified as a multiple of width.
 * @param {number} dh Expansion in height, specified as a multiple of height.
 * @return {!LayoutRectDef}
 */
export function expandRect(rect, dw, dh) {
  return {
    top: rect.top - rect.height * dh,
    bottom: rect.bottom + rect.height * dh,
    left: rect.left - rect.width * dw,
    right: rect.right + rect.width * dw,
    width: rect.width * (1 + dw * 2),
    height: rect.height * (1 + dh * 2),
  }
}
