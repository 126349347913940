const {PI} = Math

const TWO_PI = PI * 2
const DEG = 180 / PI


export function getPointAtLength(r, cx, cy, length) {
  const circum = r * TWO_PI
  const angle = (length / circum) * TWO_PI
  return {
    y: cy + (Math.sin(angle) * r),
    x: cx + (Math.cos(angle) * r),
  }
}


/**
 * @param r circle radius
 * @param cx circle center x
 * @param cy circle center y
 * @param offsetAngle pentagon rotation in radians
 * @param startingPoint start index of the pentagon
 */
export function getPentagonPoints(r, cx, cy, offsetAngle, startingPoint = 0) {
  const circum = r * TWO_PI
  const arc = circum / 5
  const points = []
  for (let i = 0; i < 5; i++) {
    const length = (arc * i) + (arc * startingPoint)
    const angle = (TWO_PI * length / circum) + offsetAngle
    points.push({
      y: cy + Math.sin(angle) * r,
      x: cx + Math.cos(angle) * r,
    })
  }
  return points
}
