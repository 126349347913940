import {on} from 'embo/utils/events'

import router from 'embo/routing'


const parser = document.createElement('a')

const getRoute = form => {
  parser.href = form.action
  // FIXME: maybe this should belong to router ?
  return parser.pathname.replace(/^\/app(?:_dev)?\.php/, '')
}

export default () => {
  on(document, 'submit', '.embo-search-form', ({event, target}) => {
    event.preventDefault()
    const query = target.elements.term.value
    if (query) {
      const url = `${getRoute(target)}?term=${encodeURIComponent(query)}`
      router.show(url)
    }
  })
}
