import constants from 'embo/constants'
import dom from 'embo/utils/dom'
import {on, getKey, stop} from 'embo/utils/events'
import animate from 'embo/utils/animate'


const TOGGLE_SELECTOR = '[data-toggle="dropdown"]'
const MENU_SELECTOR = '.dropdown-menu'
const easing = constants.easing.default

const INPUT_TAGS = ['INPUT', 'SELECT', 'TEXTAREA']
const isInputElement = el => INPUT_TAGS.includes(el.tagName)

const isAnchorElement = el => el.tagName === 'A'


const instances = new WeakMap()

export default function getInstance(el) {
  if (!instances.has(el)) {
    instances.set(el, new Dropdown(el))
  }
  return instances.get(el)
}


function getDropdowns() {
  return dom.qsa(TOGGLE_SELECTOR).map(getInstance)
}

function getDropdownFromToggler(toggler) {
  const {target} = toggler.dataset
  if (target) {
    return dom.id(target)
  }
  return toggler.parentNode
}


class Dropdown {
  constructor(toggler) {
    this.toggler = toggler
    this.dropdown = getDropdownFromToggler(toggler)
    this.toggler.tabIndex = 0
    this.menu = this.dropdown.querySelector(MENU_SELECTOR)
  }

  get active() {
    return this.dropdown.classList.contains('open')
  }

  get disabled() {
    return this.toggler.disabled || this.toggler.classList.contains('disabled')
  }

  toggle() {
    if (this.disabled) return
    getDropdowns()
      .filter(d => d !== this)
      .map(d => d.close())
    this.active ? this.close() : this.open()
  }

  open() {
    if (this.disabled || this.active) return
    // Add open styles before animating so Velocity can read them.
    this.dropdown.classList.add('open')
    return animate(this.menu, 'slideDown', {duration: 250, easing, display: ''})
      .then(() => {
        this.toggler.setAttribute('aria-expanded', true)
      })
  }

  close(asap = false) {
    if (!this.active) return
    if (asap) {
      this.dropdown.classList.remove('open')
      this.toggler.setAttribute('aria-expanded', false)
      return
    }
    return animate(this.menu, 'slideUp', {duration: 100, easing})
      .then(() => {
        this.dropdown.classList.remove('open')
        this.toggler.setAttribute('aria-expanded', false)
        // Remove inline display:none left by Velocity
        this.menu.style.display = ''
      })
  }

  handleKeyDown(event) {
    const {target} = event
    if (isInputElement(target)) {
      return
    }

    const key = getKey(event)
    let action
    switch (key) {
      case 'Escape':
        this.close()
        return
      case ' ':
      case 'Enter':
        action = 'trigger'
        break
      case 'ArrowUp':
      case 'ArrowLeft':
        action = 'up'
        break
      case 'ArrowDown':
      case 'ArrowRight':
        action = 'down'
        break
    }
    if (!action) {
      return
    }
    if (action === 'up' || action === 'down') {
      const children = dom.qsa('.dropdown-item:not(.disabled)', this.menu)
      if (!children.length) {
        return
      }
      let index = children.findIndex(el => el === target)
      if (action === 'up' && index > 0) {
        index--
      }
      if (action === 'down' && index < children.length - 1) {
        index++
      }
      if (!~index) {
        index = 0
      }
      children[index].focus()
      event.preventDefault()
    } else if (action === 'trigger') {
      if (key === ' ' && isAnchorElement(target)) {
        return
      }
      if (target === this.toggler) {
        this.toggle()
        event.preventDefault()
      } else {
        this.close()
      }
    }
  }
}

on(document.body, 'click', TOGGLE_SELECTOR, ({event, target}) => {
  stop(event)
  getInstance(target).toggle()
})
// Handle keydown on toggler
on(document.body, 'keydown', TOGGLE_SELECTOR, ({event, target}) => {
  getInstance(target).handleKeyDown(event)
})
on(document.body, 'keydown', MENU_SELECTOR, ({event, target}) => {
  const toggler = target.parentElement.querySelector(TOGGLE_SELECTOR)
  getInstance(toggler).handleKeyDown(event)
})

// Handle clicks outside dropdown
on(document.body, 'click', event => {
  if (event.which !== 1 || event.defaultPrevented) return
  const isInput = isInputElement(event.target)
  getDropdowns().forEach(dropdown => {
    //if (event.target === dropdown.button) return;
    if (isInput && dropdown.menu.contains(event.target)) return
    dropdown.close()
  })
})

