export default function buildDom(...nodes) {
  const frag = document.createDocumentFragment()
  let node = nodes.shift()
  while (node) {
    if (typeof node === 'string') {
      const div = document.createElement('div')
      div.innerHTML = node
      while (div.firstChild) {
        frag.appendChild(div.firstChild)
      }
    } else {
      frag.appendChild(node)
    }
    node = nodes.shift()
  }

  return frag
}
