import React, {useState, useCallback} from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/**
 * Just adds some classes on load & error events
 */
const Image = ({
  onLoad,
  onError,
  ...props
}) => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)

  const handleLoaded = useCallback(
    event => {
      setLoaded(true)
      onLoad && onLoad(event)
    },
    [onLoad]
  )
  const handleError = useCallback(
    event => {
      setError(true)
      onError && onError(event)
    },
    [onError]
  )

  const classes = cx('image', {
    'image--is-loaded': loaded,
    'image--has-error': error,
  })
  return <img {...props} className={classes} onLoad={handleLoaded} onError={handleError}/>
}
Image.propTypes = {
  onLoad: PropTypes.func,
  onError: PropTypes.func,
}

export default Image
