import React, {useState, useEffect, useRef, useCallback} from 'react'
import {createPortal} from 'react-dom'
import PropTypes from 'prop-types'
import createFocusTrap from 'focus-trap'

import SpringSlider from '../common/Slider'
import Image from './Image'
import BackgroundBlur from '../common/images/BackgroundBlur'
import Icon from '../common/Icon'
import useHorizontalSwipe from 'embo/components/hooks/useHorizontalSwipe'


const {floor} = Math


const getImageSizes = img => {
  const w = floor(img.width)
  const h = floor(img.height)
  return `(min-aspect-ratio: ${w}/${h}) calc(100vh * (${w}/${h})), 100vw`
}

const NavigationButton = ({
  back = false,
  onClick,
}) => {

  const className = `embo-carousel__control embo-carousel__control--${back ? 'prev' : 'next'}`
  const iconName = `chevron-${back ? 'left' : 'right'}`

  return (
    <button className={className} onClick={onClick}>
      <Icon name={iconName} width={48} height={48}/>
      <span className="sr-only">
        {`Image ${back ? 'précédente' : 'suivante'}`}
      </span>
    </button>
  )
}
NavigationButton.propTypes = {
  back: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

const CloseButton = ({onClick}) => (
  <button className="embo-carousel__close" onClick={onClick}>
    <span className="sr-only">Fermer</span>
    <Icon name="close" width={48} height={48}/>
  </button>
)
CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const Slide = ({
  image,
  backgroundImage,
  onLoad,
}) => {

  const handleImageLoaded = useCallback(
    event => onLoad(image, event),
    [image, onLoad]
  )

  return (
    <figure className="embo-carousel__slide">
      <BackgroundBlur image={backgroundImage}/>
      <Image image={image} sizes={getImageSizes(image)} onLoad={handleImageLoaded}/>
      <figcaption className="embo-carousel__slide__caption">
        <div>{image.name}</div>
        <div>{image.license}</div>
      </figcaption>
    </figure>
  )
}
Slide.propTypes = {
  image: PropTypes.object.isRequired,
  backgroundImage: PropTypes.string,
  onLoad: PropTypes.func.isRequired,
}

const SWIPE_VELOCITY_THRESHOLD = 0.3

const Slider = ({
  images,
  onClose,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [backgroundImages, setBackgroundImages] = useState({})
  const container = useRef(null)
  const slider = useRef(null)
  const handleImageLoaded = useCallback((image, event) => {
    const backgroundImage = event.target.currentSrc
    setBackgroundImages(backgroundImages => ({
      ...backgroundImages,
      [image.id]: backgroundImage,
    }))
  }, [])
  const handleCloseClicked = useCallback(() => onClose(), [onClose])

  const hasSwiped = useRef(false)
  const bindSwipeGesture = useHorizontalSwipe(
    (direction) => {
      hasSwiped.current = true
      setCurrentIndex(i => i + direction)
    },
    () => hasSwiped.current = false
  )
  const handlePrevClicked = useCallback(() => {
    if (hasSwiped.current) return
    setCurrentIndex(i => i - 1)
  }, [])
  const handleNextClicked = useCallback(() => {
    if (hasSwiped.current) return
    setCurrentIndex(i => i + 1)
  }, [])

  const handleKeyDown = useCallback(event => {
    switch (event.key) {
      case 'Escape':
        onClose()
        break
      case 'ArrowRight':
      case 'ArrowDown':
        setCurrentIndex(i => i + 1)
        break
      case 'ArrowLeft':
      case 'ArrowUp':
        setCurrentIndex(i => i - 1)
        break
    }
  }, [slider, onClose])

  useEffect(() => {
    const focusTrap = createFocusTrap(container.current, {
      initialFocus: container.current,
    })
    focusTrap.activate()

    return () => focusTrap.deactivate()
  }, [])

  return createPortal(
    <div ref={container} className="embo-carousel__slider" onKeyDown={handleKeyDown} tabIndex="0" role="dialog" {...bindSwipeGesture()}>
      <SpringSlider className="embo-carousel__slides" currentIndex={currentIndex}>
        {images.map(image => (
          <Slide key={image.id} image={image} backgroundImage={backgroundImages[image.id]} onLoad={handleImageLoaded}/>
        ))}
      </SpringSlider>
      {images.length > 1 && (
        <>
          <NavigationButton back onClick={handlePrevClicked}/>
          <NavigationButton onClick={handleNextClicked}/>
        </>
      )}
      <CloseButton onClick={handleCloseClicked}/>
    </div>,
    document.body
  )
}
Slider.propTypes = {
  images: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
}
export default React.memo(Slider)
