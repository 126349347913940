import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../common/Icon'


const TrackInfoButton = ({onClick}) => (
  <button className="embo-player__track-info-btn" onClick={onClick}>
    <Icon name="info-outline" width={18} height={18}/>
  </button>
)
TrackInfoButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}
export default TrackInfoButton
