import React from 'react'
import PropTypes from 'prop-types'
import {Provider} from 'react-redux'

import Gallery from './Gallery'


const PosterGallery = ({
  store,
  initialWidth,
}) => (
  <Provider store={store}>
    <Gallery initialWidth={initialWidth}/>
  </Provider>
)
PosterGallery.propTypes = {
  store: PropTypes.object.isRequired,
  initialWidth: PropTypes.number.isRequired,
}

export default PosterGallery
