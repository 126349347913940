import vsync from '../vsync'
import timer from '../timer'
import {layoutRect} from '../dom/layoutRect'
import Observable from '../Observable'


/**
 * Class to handle viewport dimensions & position.
 */
export default class Viewport {
  /**
   *
   * @param {Window} win
   * @param {ViewportBinding} binding
   */
  constructor(win, binding) {
    this._win = win
    this._binding = binding

    this._scrollTracking = false
    this._scrollCount = 0
    this._scrollTop = null
    this._scrollLeft = null
    this._size = null

    this._scrollObservable = new Observable()
    this._changeObservable = new Observable()
    this._binding.onScroll(this._handleScroll)
    this._binding.onResize(this._handleResize)
  }

  get hasScrolled() {
    return this._scrollCount > 0
  }

  get scrollTop() {
    if (this._scrollTop === null) {
      this._scrollTop = this._binding.getScrollTop()
    }
    return this._scrollTop
  }

  set scrollTop(top) {
    this._scrollTop = null
    this._binding.setScrollTop(top)
  }

  get scrollLeft() {
    if (this._scrollLeft === null) {
      this._scrollLeft = this._binding.getScrollLeft()
    }
    return this._scrollLeft
  }

  set scrollLeft(left) {
    this._scrollLeft = null
    this._binding.setScrollLeft(left)
  }

  scrollTo(x = 0, y = 0) {
    this._scrollLeft = this._scrollTop = null
    this._binding.scrollTo(x, y)
  }

  /**
   * Not cached since it could change at any time without notification.
   *
   * @returns {Number}
   */
  get scrollWidth() {
    return this._binding.getScrollWidth()
  }

  /**
   * Not cached since it could change at any time without notification.
   *
   * @returns {Number}
   */
  get scrollHeight() {
    return this._binding.getScrollHeight()
  }

  get size() {
    if (this._size) {
      return this._size
    }
    return this._size = this._binding.getSize()
  }

  get width() {
    return this.size.width
  }

  get height() {
    return this.size.height
  }

  /**
   * Returns the rect of the viewport which includes scroll positions and size.
   */
  getRect() {
    const {scrollTop, scrollLeft, size} = this
    return layoutRect(scrollLeft, scrollTop, size.width, size.height)
  }

  /**
   * Returns the rect of the element within the document.
   */
  getLayoutRect(el) {
    return this._binding.getLayoutRect(el)
  }

  /**
   * Registers the handler for change events.
   *
   * @param handler
   * @returns {*}
   */
  onChange(handler) {
    return this._changeObservable.add(handler)
  }

  /**
   * Registers the handler for scroll events.
   * These events DO NOT contain scrolling offset,
   * and it's discouraged to read scrolling offset in the event handler.
   * The primary use case for this handler is to inform that scrolling might be going on.
   * To get more information onChange handler should be used.
   *
   * @param {!function()} handler
   * @return {!function()}
   */
  onScroll(handler) {
    return this._scrollObservable.add(handler)
  }

  _handleScroll = () => {
    this._scrollCount++
    this._scrollLeft = this._binding.getScrollLeft()
    const newScrollTop = this._binding.getScrollTop()
    if (newScrollTop < 0) {
      // iOS and some other browsers use negative values of scrollTop for overscroll.
      // Overscroll does not affect the viewport and thus should be ignored here.
      return
    }
    this._scrollTop = newScrollTop
    if (!this._scrollTracking) {
      this._scrollTracking = true
      const now = timer.now()
      // Wait 2 frames and then request an animation frame.
      timer.delay(36).then(() => vsync.measure(() => this._throttledScroll(now, newScrollTop)))
    }
    this._scrollObservable.fire()
  }

  _handleResize = () => {
    const oldSize = this._size
    this._size = null // Need to recalc.
    const newSize = this.size
    this._fireChanged(!oldSize || oldSize.width !== newSize.width, 0)
  }

  _fireChanged(relayoutAll, velocity) {
    const {scrollTop, scrollLeft, size: {width, height}} = this
    this._changeObservable.fire({relayoutAll, velocity, scrollTop, scrollLeft, width, height})
  }

  /**
   * This method is called about every 3 frames (assuming 60hz) and is called in a vsync measure task.
   *
   * @param {number} referenceTime Time when the scroll measurement that triggered this call was made.
   * @param {number} referenceTop Scrolltop at that time.
   *
   * @private
   */
  _throttledScroll = (referenceTime, referenceTop) => {
    this._scrollTracking = false
    const newScrollTop = this._scrollTop = this._binding.getScrollTop()
    const now = timer.now()
    let velocity = 0
    if (now !== referenceTime) {
      velocity = (newScrollTop - referenceTop) / (now - referenceTime)
    }
    // TODO: confirm the desired value and document it well.
    // Currently, this is 30px/second -> 0.03px/ms
    if (Math.abs(velocity) < 0.03) {
      this._fireChanged(false, velocity)
    } else {
      timer.delay(20).then(() => vsync.measure(() => this._throttledScroll(now, newScrollTop)))
    }
  }
}
