import dom from 'embo/utils/dom'
import vsync from 'embo/utils/vsync'
import {domRect, offsetRect} from 'embo/utils/dom/layoutRect'
import * as events from 'embo/utils/events'
import animate from 'embo/utils/animate'

import getVendorPropertyName from 'embo/utils/style/getVendorPropertyName'

import PageSpinner from 'embo/ui/PageSpinner'
import {unmountReactComponents} from 'embo/ui/react-components'


const HEADER_ANIMATION_DURATION = 500
const ANIMATING_CLASS = 'is-animating'
const LAYOUTS = {
  fluid: {
    sidebar: false,
    main: 'fluid',
    className: 'layout-fluid',
  },
  centered: {
    sidebar: true,
    main: 'centered',
    className: 'layout-centered-sidebar',
  },
}
const TRANSFORM = getVendorPropertyName('transform')

const noop = () => {}
const fadeOut = el => animate(el, {opacity: 0}, {duration: 200})
const fadeIn = el => animate(el, {opacity: 1}, {duration: 300})

// TODO: Use the scrollRestoration API when browsers implement it ...
//if ('scrollRestoration' in history) {
//    // Back off, browser, I got this...
//    history.scrollRestoration = 'manual';
//}


export default class PageManager {
  /**
   * @param {PageLoader} loader
   */
  constructor(loader) {
    this._loader = loader
    this.elements = {
      pageWrapper: dom.id('page-wrapper'),
      mainContainer: dom.id('main-container'),
      main: dom.id('main'),
      sidebar: dom.id('sidebar'),
      logo: dom.id('logo-beton'),
      crab: dom.id('logo-crabe'),
    }
    this.currentLayout = LAYOUTS.homepage
    this.previousLayout = null
    this.currentTheme = 'homepage'
    this.previousTheme = null
    this.context = null
    this._spinner = new PageSpinner()
  }

  setContext(ctx) {
    this.context = ctx
    return this
  }

  setLayout(name) {
    this.previousLayout = this.currentLayout
    this.currentLayout = LAYOUTS[name]
    return this
  }

  setTheme(name) {
    this.previousTheme = this.currentTheme
    this.currentTheme = name
    return this
  }

  saveInitialPage() {
    this._loader.save(location.pathname)
  }

  fetch(url) {
    // do not show spinner if page is in cache
    if (!this._loader.needsFetching(url)) {
      return this._loader.load(url)
    }
    // TODO: show spinner only after a certain amount of time (1sec ?)
    return this.showSpinner()
      .then(() => this._loader.load(url))
      .then(result => this.hideSpinner().then(() => result))
  }

  getSurface(id, container = null) {
    return dom.qs(`[data-surface-id="${id}"]`, container)
  }

  swapSurface(id, nextDom, beforeEnter = noop) {
    const surface = this.getSurface(id)
    const nextSurface = this.getSurface(id, nextDom)
    return fadeOut(surface)
      .then(() => unmountReactComponents(null, surface))
      .then(() => {
        surface.innerHTML = nextSurface.innerHTML
        return beforeEnter(surface)
      })
      .then(() => fadeIn(surface))

  }

  fadeOut() {
    const {currentLayout, previousLayout, elements: {main, sidebar}} = this
    const fadeSidebar = previousLayout.sidebar !== currentLayout.sidebar

    return Promise.all([
      fadeOut(main),
      fadeSidebar ? fadeOut(sidebar) : Promise.resolve(),
    ]).then(() => {
      unmountReactComponents(null, main)
      if (fadeSidebar) unmountReactComponents(null, sidebar)
    })
  }

  fadeIn() {
    const {currentLayout, previousLayout, elements: {main, sidebar}} = this
    const fadeSidebar = currentLayout.sidebar && !previousLayout.sidebar

    return Promise.all([
      fadeIn(main),
      fadeSidebar ? fadeIn(sidebar) : Promise.resolve(),
    ])
  }

  showSpinner() {
    return this._spinner.show()
  }

  hideSpinner() {
    return this._spinner.hide()
  }

  showError(err, transient = true) {
    if (transient) {
      return this._spinner.showTransientError(1000)
    }
    return this._spinner.showFatalError(err, 10000)
  }

  restoreScrollPosition() {
    if (this.context.action === 'PUSH') {
      // Forward action, scroll to top...
      return animate(document.documentElement, 'scroll', {duration: 300, offset: 0})
    }
    // TODO: Backward action, restore scroll position...
    return Promise.resolve()
  }

  updateDOM(nextDom) {
    const {currentLayout, previousLayout, elements: {main, sidebar}} = this

    main.innerHTML = dom.qs('#main', nextDom).innerHTML
    if (currentLayout.sidebar && !previousLayout.sidebar) {
      sidebar.innerHTML = dom.qs('#sidebar', nextDom).innerHTML
    }
    this._setLayoutClasses()
  }

  animateHeader() {
    if (this.currentTheme === this.previousTheme) {
      return Promise.resolve()
    }
    const {logo, crab, pageWrapper} = this.elements
    //
    // -----> First
    const start = this._collectProperties()
    //
    // -----> Last
    this._setBodyClass()
    const end = this._collectProperties()
    //
    // -----> Invert
    const diff = this._computeDiffs(start, end)
    logo.style[TRANSFORM] = `
      translateX(${diff.logo.left}px)
      ${diff.logo.transform === 'none' ? '' : diff.logo.transform}
    `
    crab.style.opacity = start.crab.opacity
    pageWrapper.style[TRANSFORM] = `translateY(${diff.pageWrapper}px)`
    //
    // -----> Play
    pageWrapper.offsetTop
    logo.offsetLeft

    if (this.currentTheme === 'homepage') {
      return this._animateLogo()
        .then(this._animatePageAndCrab)
        .then(this._cleanupStyleAttributes)
    }
    if (this.previousTheme === 'homepage') {
      return this._animatePageAndCrab()
        .then(this._animateLogo)
        .then(this._cleanupStyleAttributes)
    }
    return this._animateLogo().then(this._cleanupStyleAttributes)
  }

  _setBodyClass() {
    const theme = `theme-${this.currentTheme}`
    const layout = this.currentLayout.className
    document.body.className = `${theme} ${layout}`
  }

  _setLayoutClasses() {
    const {mainContainer, main, sidebar} = this.elements

    switch (this.currentLayout.main) {
      case 'fluid':
        mainContainer.className = 'container-fluid'
        main.className = 'col-xs-12'
        sidebar.className = 'col-hidden'
        break
      case 'centered':
        mainContainer.className = 'container'
        main.className = 'col-lg-8'
        sidebar.className = 'col-lg-4'
        break
    }
  }

  _animateLogo = () => {
    const {logo} = this.elements
    return vsync.mutate(() => {
      logo.classList.add(ANIMATING_CLASS)
      logo.style[TRANSFORM] = ''
    }).then(() => {
      return events.one(logo, 'transitionend', HEADER_ANIMATION_DURATION * 1.5)
        .catch(() => console.warn('timeout in animateLogo'))
    }).then(() => {
      return vsync.mutate(() => logo.classList.remove(ANIMATING_CLASS))
    })
  }

  _animatePageAndCrab = () => {
    const {crab, pageWrapper} = this.elements
    return vsync.mutate(() => {
      crab.classList.add(ANIMATING_CLASS)
      pageWrapper.classList.add(ANIMATING_CLASS)
      crab.style.opacity = ''
      pageWrapper.style[TRANSFORM] = ''
    }).then(() => {
      return events.one(crab, 'transitionend', HEADER_ANIMATION_DURATION * 1.5)
        .catch(() => console.warn('timeout in animatePageAndCrab'))
    }).then(() => {
      return vsync.mutate(() => {
        crab.classList.remove(ANIMATING_CLASS)
        pageWrapper.classList.remove(ANIMATING_CLASS)
      })
    })
  }

  _cleanupStyleAttributes = () => {
    const {logo, crab, pageWrapper} = this.elements
    return vsync.mutate(() => {
      [logo, crab, pageWrapper].forEach(el => el.style.cssText = '')
    })
  }

  _collectProperties() {
    const {logo, crab, pageWrapper} = this.elements
    return {
      // have to use offsetRect here because the logo has a CSS transform in the stylesheet
      // see https://github.com/GoogleChrome/flipjs/issues/4
      logo: {
        ...offsetRect(logo),
        transform: getComputedStyle(logo)[TRANSFORM],
      },
      crab: {
        ...domRect(crab),
        opacity: parseFloat(getComputedStyle(crab).opacity),
      },
      pageWrapper: domRect(pageWrapper),
    }
  }

  _computeDiffs(start, end) {
    return {
      logo: {
        left: start.logo.left - end.logo.left,
        transform: start.logo.transform,
      },
      crab: start.crab/* - end.crab*/,
      pageWrapper: start.pageWrapper.top - end.pageWrapper.top,
    }
  }
}
