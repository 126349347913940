import React from 'react'
import PropTypes from 'prop-types'

import BaseImage from '../common/Image'


const sortedThumbnails = (thumbnails) => Object.keys(thumbnails)
  .sort((a, b) => {
    const aw = thumbnails[a].width
    const bw = thumbnails[b].width
    return aw > bw ? 1 : aw < bw ? -1 : 0
  })
  .map(k => thumbnails[k])

const selectFormats = (thumbnails, formats = []) => {
  if (!formats.length) {
    return thumbnails
  }
  const selected = formats.reduce((thumbs, format) => {
    if (thumbnails[format]) {
      thumbs[format] = thumbnails[format]
    }
    return thumbs
  }, {})

  if (!Object.keys(selected).length) {
    throw new Error(`None of formats ${formats} found in ${Object.keys(thumbnails)}`)
  }

  return selected
}

const getSrcSet = (image, formats, defaultFormat) => {
  // keep only given formats
  const thumbnails = selectFormats(image.thumbnail, formats)
  // sort thumbnails by size
  const thumbList = sortedThumbnails(thumbnails)
  const biggest = thumbList[thumbList.length - 1]
  // get max dimensions
  const style = {
    maxWidth: biggest.width,
    maxHeight: biggest.height,
  }
  // build srcset string
  const srcset = thumbList.map(({url, width}) => `${url} ${width}w`).join(', ')

  return {srcset, style}
}

const Image = ({
  image,
  sizes,
  formats,
  onLoad = () => {},
}) => {
  const {srcset, style} = getSrcSet(image, formats)
  return (
    <BaseImage srcSet={srcset} sizes={sizes} alt={image.name} style={style} onLoad={onLoad}/>
  )
}
Image.propTypes = {
  image: PropTypes.shape({
    name: PropTypes.string.isRequired,
    thumbnail: PropTypes.object.isRequired,
  }).isRequired,
  sizes: PropTypes.string.isRequired,
  formats: PropTypes.arrayOf(String),
  onLoad: PropTypes.func,
}

export default React.memo(Image)
