//
// Action creators
// --------------------------------------------------------------------------------------------------------------------


export const SET_PLAYING = 'embo/player/SET_PLAYING'

export function setPlaying(playing = true) {
  return {
    type: SET_PLAYING,
    payload: playing,
    meta: {analytics: playing},
  }
}


const SET_CURRENT_TIME = 'embo/player/SET_CURRENT_TIME'

export function setCurrentTime(payload) {
  return {type: SET_CURRENT_TIME, payload}
}


const STORE_CURRENT_TIME = 'embo/player/STORE_CURRENT_TIME'

export function storeCurrentTime(payload) {
  return {type: STORE_CURRENT_TIME, payload}
}


const SET_CURRENT_DURATION = 'embo/player/SET_CURRENT_DURATION'

export function setCurrentDuration(payload) {
  return {type: SET_CURRENT_DURATION, payload}
}


const SET_VOLUME = 'embo/player/SET_VOLUME'

export function setVolume(payload) {
  return {type: SET_VOLUME, payload}
}


const SET_PLAYLIST_VISIBLE = 'embo/player/SET_PLAYLIST_VISIBLE'

export function setPlaylistVisible(payload = true) {
  return {type: SET_PLAYLIST_VISIBLE, payload}
}


//
// Reducers
// --------------------------------------------------------------------------------------------------------------------

const initialState = {
  playlistVisible: false,
  playing: false,
  currentTime: 0,
  requestedCurrentTime: 0,
  currentDuration: 0,
  volume: 1.0,
}

export default function reducer(state = initialState, action = {}) {
  const {type, payload} = action
  switch (type) {

    case SET_PLAYLIST_VISIBLE:
      if (state.playlistVisible === payload) {
        return state
      }
      return {...state, playlistVisible: payload}

    case SET_PLAYING:
      return payload === state.playing ? state : {...state, playing: payload}

    case SET_CURRENT_TIME:
      return {...state, requestedCurrentTime: payload}

    case STORE_CURRENT_TIME:
      return {...state, currentTime: payload}

    case SET_CURRENT_DURATION:
      return {...state, currentDuration: payload}

    case SET_VOLUME:
      return payload === state.volume ? state : {...state, volume: payload}

    default:
      return state
  }
}
