import dom from 'embo/utils/dom'
import viewport from 'embo/utils/viewport'
import vsync from 'embo/utils/vsync'
import escapeHTML from 'embo/utils/dom/escapeHTML'
import {ucfirst} from 'embo/utils/string/case'
import debounce from 'embo/utils/function/debounce'


const DEFAULTS = {
  // The ratio used when computing the characters per line
  // (availableWidth / (font-size * fontRatio)).
  fontRatio: 0.78,
  // The baseline to respect for vertical rhythm.
  // We'll add bottom padding to the header so that it always fit a multiple of this number.
  verticalRhythmBaseline: 24,
  // Always recompute the line length, not just when the font-size changes.
  // Defaults to true (CPU intensive, but more accurate on resize)
  forceNewCharCount: true,
  // Do we wrap ampersands in <span class="amp">
  wrapAmpersand: true,
  // Between which pixel widths do we apply the slabtext styling?
  minWidth: 420, maxWidth: Infinity,
  // The maximum pixel font size the script can set
  maxFontSize: 24 * 10,
  // The min num of chars a line should contain
  minCharsPerLine: 4,
}

const TEXT_CONTEXT = dom.el('canvas').getContext('2d')

function getTextWidth(text, {size, family, weight, transform}) {
  if (typeof size === 'number') {
    size = `${size}px`
  }
  TEXT_CONTEXT.font = `${weight} ${size} ${family}`
  switch (transform) {
    case 'uppercase':
      text = text.toUpperCase()
      break
    case 'lowercase':
      text = text.toLowerCase()
      break
    case 'capitalize':
      text = ucfirst(text)
      break
    default:
      // who cares about full-width ?
      break
  }
  return TEXT_CONTEXT.measureText(text).width
}


function getVerticalRhythmBaseline() {
  return getComputedStyle(document.body).lineHeight
}


const Classes = {
  main: 'slabtext',
  line: 'slabtext__line',
  done: 'slabtext--done',
  inactive: 'slabtext--inactive',
  documentEnabled: 'slabtext-enabled',
}


class SlabText {
  constructor(el, options = {}) {
    this._el = el
    this._options = {...DEFAULTS, ...options}

    this._keepSpans = dom.qsa(`.${Classes.line}`, this._el).length
    this._words = this._keepSpans ? [] : this._el.textContent.replace(/\s{2,}/g, ' ').split(' ')
    this._font = null
    this._originalMargin = parseFloat(getComputedStyle(this._el).marginBottom)
    this._idealLineLength = null
    this._fontRatio = this._options.fontRatio
    this._verticalBaseline = this._options.verticalRhythmBaseline
    this._forceNewCharCount = this._options.forceNewCharCount
    this._minWidth = this._options.minWidth
    this._maxWidth = this._options.maxWidth
    this._minCharsPerLine = this._options.minCharsPerLine || 2
  }

  disable() {
    return vsync.mutate(() => this._disable())
  }

  /**
   * A (very) stripped down AS3 to JS port of the slabtype algorithm by Eric Loyer.
   *
   * http://erikloyer.com/index.php/blog/the_slabtype_algorithm_part_1_background/
   */
  resize() {
    if (!this._keepSpans && this._words.join(' ').length < this._minCharsPerLine) {
      // Text length is too short, bail out.
      // No need to cleanup here since text shouldn't change.
      return
    }

    let availableWidth
    let font

    return vsync.run({
      measure: () => {
        const css = getComputedStyle(this._el)
        availableWidth = this._el.offsetWidth - parseFloat(css.paddingLeft) - parseFloat(css.paddingRight)
        font = {
          size: parseFloat(css.fontSize),
          family: css.fontFamily,
          weight: css.fontWeight,
          transform: css.textTransform,
        }
      },
      mutate: () => {
        if (availableWidth < this._minWidth || availableWidth > this._maxWidth) {
          this._disable()
          return Promise.reject({disabled: true})
        }
        // Remove the --done and --inactive classes to enable the inline-block shrink-wrap effect
        //this._el.classList.remove(Classes.done);
        this._el.classList.remove(Classes.inactive)
      },
    }).then(() => {
      // If the parent containers font-size has changed or the "forceNewCharCount" option is true (the default),
      // then recalculate the "characters per line" count and re-render the inner spans
      // Setting "forceNewCharCount" to false will save CPU cycles...
      if (
        !this._keepSpans
        && (this._forceNewCharCount || font.size !== this._font.size)
      ) {
        this._font = font
        const newCharPerLine = Math.min(60, Math.floor(availableWidth / (this._font.size * this._fontRatio)))

        if (newCharPerLine && newCharPerLine !== this._idealLineLength) {
          this._idealLineLength = newCharPerLine
          return vsync.run({
            measure: () => this._computeLines(),
            mutate: lines => this._el.innerHTML = lines.join(' '),
          }).then(() => this._updateLines(availableWidth))
        } else {
          vsync.mutate(() => this._el.classList.add(Classes.done))
        }
      } else {
        // We only need the font-size for the resize-to-fit functionality
        // if not injecting the spans
        this._font = font
        return this._updateLines(availableWidth)
      }
    }).catch(reason => {
      if (reason.disabled) return
      throw reason
    })
  }

  _disable() {
    // Remove the --done and --inactive classes to enable the inline-block shrink-wrap effect
    this._el.classList.remove(Classes.done)
    // Add the --inactive class to set the spans as inline
    // and to reset the font-size to 1em (inherit won't work in IE6/7)
    this._el.classList.add(Classes.inactive)
    // Reset margin bottom to default.
    this._el.style.marginBottom = ''
  }

  _computeLines() {
    const lines = []
    let wordIndex = 0

    while (wordIndex < this._words.length) {
      let postText = ''
      let preText = ''
      let finalText = ''
      // build two strings (preText and postText) word by word,
      // with one string always one word behind the other,
      // until the length of one string is less than the ideal number of characters per line,
      // while the length of the other is greater than that ideal
      while (postText.length < this._idealLineLength) {
        preText = postText
        postText += this._words[wordIndex] + ' '
        if (++wordIndex >= this._words.length) {
          break
        }
      }
      // This bit hacks in a minimum characters per line test
      // on the last line
      if (this._minCharsPerLine) {
        const slice = this._words.slice(wordIndex).join(' ')
        if (slice.length < this._minCharsPerLine) {
          postText += slice
          preText = postText
          wordIndex = this._words.length + 2
        }
      }
      // calculate the character difference between
      // the two strings and the ideal number of characters per line
      const preDiff = this._idealLineLength - preText.length
      const postDiff = postText.length - this._idealLineLength

      // if the smaller string is closer to the length of the ideal than the longer string
      // and doesn’t contain less than minCharsPerLine characters,
      // then use that one for the line
      if (preDiff < postDiff && preText.length >= this._minCharsPerLine) {
        finalText = preText
        wordIndex--
        // otherwise, use the longer string for the line
      } else {
        finalText = postText
      }

      // HTML-escape the text
      finalText = escapeHTML(finalText)

      // Wrap ampersands in spans with class `amp` for specific styling
      if (this._options.wrapAmpersand) {
        finalText = finalText.replace(/&amp;/g, '<span class="amp">&amp;</span>')
      }

      finalText = finalText.trim()
      //FIXME: there shouldn't be empty lines !
      if (finalText) {
        lines.push(`<span class="${Classes.line}">${finalText}</span>`)
      }
    }
    return lines
  }

  _updateLines(availableWidth) {
    vsync.run({
      measure: () => {
        return dom.qsa(`.${Classes.line}`, this._el).map(line => {
          const innerText = line.textContent
          const numChars = innerText.length
          const numWords = innerText.split(' ').length
          const useWordSpacing = numWords > 1

          // Measure line width w/ original font size
          const textWidth = getTextWidth(innerText, this._font)
          const ratio = availableWidth / textWidth
          const nextFontSize = Math.min(this._font.size * ratio, this._options.maxFontSize)
          // Measure line width w/ the new font size
          const {family, weight, transform} = this._font
          const nextWidth = getTextWidth(innerText, {size: nextFontSize, family, weight, transform})
          // Do we still have space to try to fill or crop ?
          const spacingDiff = availableWidth - nextWidth
          // A "dumb" tweak in the blind hope that the browser will
          // resize the text to better fit the available space.
          // Better "dumb" and fast...
          const spacingProp = (useWordSpacing ? 'word' : 'letter') + '-spacing'
          const n = useWordSpacing ? numWords - 1 : numChars

          const cssText = `
            font-size: ${nextFontSize}px;
            ${spacingProp}: ${spacingDiff / n}px;
          `
          return {line, cssText}
        })
      },
      mutate: (lines) => {
        lines.forEach(({line, cssText}) => line.style.cssText = cssText)
        // Add the class slabtextdone to set a display:block on the child spans
        // and avoid styling & layout issues associated with inline-block
        this._el.classList.add(Classes.done)
      },
    }).then(() => {
      // Post process the container to fit vertical rhythm
      return vsync.run({
        measure: () => {
          const height = this._el.getBoundingClientRect().height
          const vlines = Math.ceil(height / this._verticalBaseline)
          const vheight = vlines * this._verticalBaseline

          return `${this._originalMargin + (vheight - height)}px`
        },
        // cannot use padding since padding can't be negative (in case vheight < height)
        mutate: marginBottom => this._el.style.marginBottom = marginBottom,
      })
    })
  }
}


class SlabTextManager {
  constructor() {
    this.instances = new WeakMap()
    this.unwatchViewport = null
  }

  initialize() {
    // Grab vertical rhythm info from body
    //TODO: add MediaQueryListener in case this must change
    vsync.run({
      measure: () => {
        DEFAULTS.verticalRhythm = getVerticalRhythmBaseline()
      },
      mutate: () => {
        // Add the slabtext-enabled classname to the body to initiate the styling of
        // the injected spans
        document.documentElement.classList.add(Classes.documentEnabled)
      },
    })
  }

  enable(options) {
    const {width} = viewport
    const promises = this.getSlabs(options).map(slab => slab.resize(width))

    if (!this.unwatchViewport) {
      this.unwatchViewport = viewport.onChange(({relayoutAll, width}) => {
        if (relayoutAll) {
          this.getSlabs(options).forEach(slab => slab.resize(width))
        }
      })
    }

    return Promise.all(promises)
  }

  disable() {
    this.unwatchViewport()
    return Promise.resolve()
  }

  getSlabs(options) {
    return dom.qsa(`.${Classes.main}`).map(el => this.getSlabInstance(el, options))
  }

  getSlabInstance(el, options) {
    if (!this.instances.has(el)) {
      this.instances.set(el, new SlabText(el, options))
    }
    return this.instances.get(el)
  }
}

const slabManager = new SlabTextManager()
document.addEventListener('DOMContentLoaded', () => slabManager.initialize())

export default function initSlabs(options) {
  return slabManager.enable(options)
}

export function removeSlabs() {
  return slabManager.disable()
}

