import React, {useCallback, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Transition, TransitionGroup} from 'react-transition-group'

import constants from 'embo/constants'
import animate from 'embo/utils/animate'
import {domRect} from 'embo/utils/dom/layoutRect'
import viewport from 'embo/utils/viewport'
import vsync from 'embo/utils/vsync'
import timer from 'embo/utils/timer'

import router from 'embo/routing'
import {
  setCurrentPoster,
  selectPosters,
  selectCurrentPoster,
} from 'embo/state/posters'

import ThumbnailList from './ThumbnailList'
import Modal from './Modal'
import {PosterType} from './propTypes'


const easing = constants.easing.default
const TIMEOUTS = {
  enter: 1100,
  exit: 1100,
}

const mapStateToProps = state => ({
  posters: selectPosters(state),
  current: selectCurrentPoster(state),
})

const Gallery = ({
  posters,
  current,
  initialWidth,
  dispatch,
}) => {

  const container = useRef(null)
  // stores the requested route, so we can call the router after animations.
  const willNavigateTo = useRef(null)

  const [viewportDimensions, setViewportDimensions] = useState({scrollTop: 0, height: 0})
  const [containerDimensions, setContainerDimensions] = useState({
    width: initialWidth,
    top: 0,
  })
  const [currentThumbnail, setCurrentThumbnail] = useState(null)

  useEffect(() => {
    vsync.run({
      measure: () => {
        const {top, width} = domRect(container.current)
        const {scrollTop, height} = viewport
        return {top, width, scrollTop, height}
      },
      mutate: ({top, width, scrollTop, height}) => {
        setContainerDimensions({top, width})
        setViewportDimensions({scrollTop, height})
      },
    })
    return viewport.onChange(({relayoutAll, scrollTop, height}) => {
      if (!container.current) return
      if (relayoutAll) {
        vsync.run({
          measure: () => domRect(container.current),
          mutate: ({top, width}) => {
            setContainerDimensions({top, width})
            setViewportDimensions({scrollTop, height})
          },
        })
      } else {
        vsync.mutate(() => setViewportDimensions({scrollTop, height}))
      }
    })
  }, [])

  const handleThumbnailClick = useCallback((id, node) => {
    dispatch(setCurrentPoster(id))
    setCurrentThumbnail(node)
  }, [dispatch])
  const handleModalClose = useCallback(() => {
    dispatch(setCurrentPoster(null))
  }, [dispatch])
  const handleAuthorClick = useCallback(author => {
    willNavigateTo.current = `/posters/author/${encodeURIComponent(author)}`
    // close the modal
    dispatch(setCurrentPoster(null))
  }, [dispatch])

  const modalWillEnter = useCallback(modal => {
    const start = domRect(currentThumbnail)
    const doc = document.documentElement
    // hide modal
    modal.style.opacity = 0
    Object.keys(start).forEach(k => modal.style[k] = `${start[k]}px`)
    // hide modal children
    const children = Array.from(modal.children)
    children.forEach(child => child.style.opacity = 0)
    // fade in
    animate(modal, {opacity: [1, 0]}, {duration: 250})
    // gogogo
    return animate(modal, {
      top: [0, start.top],
      left: [0, start.left],
      width: [doc.clientWidth, start.width],
      height: [doc.clientHeight, start.height],
    }, {duration: 500, easing}).then(() => {
      return animate(children, {opacity: [1, 0]}, {duration: 250})
    }).then(() => {
      modal.setAttribute('style', '')
    })
  }, [currentThumbnail])
  const modalWillLeave = useCallback(modal => {
    const start = domRect(currentThumbnail)
    // hide modal children
    const children = Array.from(modal.children)
    return animate(children, {opacity: [0, 1]}, {duration: 250}).then(() => {
      return animate(modal, {
        top: [start.top, 0],
        left: [start.left, 0],
        width: start.width,
        height: start.height,
      }, {duration: 500, easing})
    }).then(() => {
      // fade out
      return animate(modal, {opacity: [0, 1]}, {duration: 250})
    })
  }, [currentThumbnail])
  const modalDidLeave = useCallback(modal => {
    const url = willNavigateTo.current
    if (url) {
      // set a delay so we have time to unmount everything cleanly.
      timer.delay(250).then(() => router.show(url))
      willNavigateTo.current = null
    }
  }, [])

  return (
    <div ref={container} className="embo-posters">
      <ThumbnailList
        viewport={viewportDimensions}
        top={containerDimensions.top} width={containerDimensions.width}
        posters={posters}
        onItemClicked={handleThumbnailClick}
      />
      <TransitionGroup>
        {current ? (
          <Transition
            key={current.id}
            timeout={TIMEOUTS}
            onEnter={modalWillEnter}
            onExit={modalWillLeave}
            onExited={modalDidLeave}
          >
            <Modal poster={current} onClose={handleModalClose} onAuthorClick={handleAuthorClick}/>
          </Transition>
        ) : []}
      </TransitionGroup>
    </div>
  )
}
Gallery.propTypes = {
  posters: PropTypes.arrayOf(PosterType).isRequired,
  current: PosterType,
  initialWidth: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default connect(mapStateToProps)(Gallery)
