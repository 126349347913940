import {useDrag} from 'react-use-gesture'

const {abs} = Math

/**
 * @param {Function} onAccept
 * @param {Function} onReject
 * @param {object} options
 * @returns {(...args: any[]) => ReactEventHandlers}
 */
export default function useHorizontalSwipe(onAccept, onReject, options = {}) {
  const {
    velocityThreshold = 0.3,
    distanceThreshold = 20,
  } = options

  return useDrag(({last, vxvy: [vx, vy], movement: [mx]}) => {
    if (last && abs(vx) > abs(vy) && abs(mx) > distanceThreshold) {
      if (vx > velocityThreshold) {
        // swipe right is when horizontal velocity is superior to threshold
        return onAccept(1)
      } else if (vx < -velocityThreshold) {
        // swipe left is when horizontal velocity is inferior to minus threshold
        return onAccept(-1)
      }
    }
    return onReject()
  })
}
