import dom from 'embo/utils/dom'
import timer from 'embo/utils/timer'
import post, {ValidationError} from './api'
import {showErrors, clearErrors} from './validationErrors'
import {SpinnerButton} from '../spinner'


export default class SymfonyForm {
  constructor(form) {
    if (typeof form === 'string') {
      this.form = dom.qs(`form[name="${form}"]`)
    } else {
      this.form = form
    }
    this.submitButton = dom.qs('button[type="submit"]', this.form)
    this.spinner = new SpinnerButton(this.submitButton)
    this.form.addEventListener('submit', this.onSubmit)
    this.form.addEventListener('reset', this.onReset)
    this.isFetching = false
    this.didSucceed = false
  }

  onSubmit = event => {
    event.preventDefault()
    if (this.isFetching || this.didSucceed) return

    this.isFetching = true
    this.spinner.spin().then(() => {
      return post(this.form).then(
        response => {
          this.isFetching = false
          return this.handleSuccess(response)
        },
        err => {
          this.isFetching = false
          if (err instanceof ValidationError) {
            return this.handleValidationError(err.validation)
          }
          console.log(err.constructor)
          return this.handleValidationError({
            [this.form.name]: [err],
          })
        },
      )
    })
  }

  onReset = event => this.reset()

  handleSuccess(response) {
    return this.spinner.success(response.message)
      .then(() => {
        this.didSucceed = true
        return clearErrors(this.form)
      })
      .then(() => response)

  }

  handleValidationError(validation) {
    return this.spinner.failure()
      .then(() => showErrors(this.form, validation))
      .then(() => validation)

  }

  reset() {
    this.didSucceed = false
    this.spinner.reset()
    return clearErrors(this.form)
  }
}
