import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'


const FilterChoice = ({
  label,
  filter,
  active,
  onSelect,
}) => {

  const handleClicked = useCallback(
    () => onSelect(filter),
    [filter, onSelect]
  )

  return (
    <button
      role="menuitem"
      className={cx('dropdown-item', {active})}
      onClick={handleClicked}
    >
      {label}
    </button>
  )
}
FilterChoice.propTypes = {
  label: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default FilterChoice
