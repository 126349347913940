import buildDom from './buildDom'

const ParentNodePrototype = {

  append(...nodes) {
    this.appendChild(buildDom(...nodes))
  },

  prepend(...nodes) {
    this.insertBefore(buildDom(...nodes), this.firstChild)
  },
}

const implementors = ['Element', 'Document', 'DocumentFragment']

implementors.forEach(klass => {
  Object.keys(ParentNodePrototype).forEach(method => {
    if (window[klass].prototype[method]) return
    window[klass].prototype[method] = ParentNodePrototype[method]
  })
})
