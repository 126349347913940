import velocity from 'velocity-animate'

/**
 *
 * @param {HTMLElement|Array.<HTMLElement>} who
 * @param {string|object} what
 * @param {object} options
 * @param {Array} rest
 *
 * @returns {Promise.<Array.<HTMLElement>>}
 */
export default function animate(who, what, options, ...rest) {
  return velocity(who, what, options, ...rest)
}

animate.set = (el, props) => Object.keys(props).forEach(k => velocity.hook(el, k, props[k]))


export function transitionPromise(el) {
  return new Promise((resolve, reject) => {
    let off
    const proxy = event => {
      if (event.target !== el) return
      off()
      resolve(event)
    }
    off = () => el.removeEventListener('transitionend', proxy)
    el.addEventListener('transitionend', proxy)
  })
}

export function scrollIntoView(element, container, duration = 300) {
  container = container || document.documentElement
  const elRect = element.getBoundingClientRect()
  const parentRect = container.getBoundingClientRect()
  const offset = elRect.top - parentRect.top
  return animate(container, 'scroll', {container, duration, offset})
}
