import {createSelector} from 'reselect'

import {
  selectEvents,
  selectPerformers,
  selectTracks as selectTrackEntities,
} from './entities'


//
// Action creators
// --------------------------------------------------------------------------------------------------------------------

const ADD_TRACK = 'embo/player/ADD_TRACK'

/**
 * Adds the track with the given id to the end of the playlist,
 * or optionally at the given position.
 *
 * @param {String} trackId
 * @param {Number} position
 * @returns {{type: string, payload: {trackId: String, position: Number}}}
 */
export function addTrack(trackId, position = Infinity) {
  return {type: ADD_TRACK, payload: {trackId, position}}
}


const ENQUEUE = 'embo/player/ENQUEUE'

/**
 * Adds the given track ids to the end of the playlist.
 *
 * @param {Array} tracks (array of track ids)
 * @returns {{type: string, payload: Array}}
 */
export function enqueue(tracks) {
  return {type: ENQUEUE, payload: tracks}
}


const REMOVE_TRACK = 'embo/player/REMOVE_TRACK'

/**
 * Removes the track with the given id from the playlist.
 *
 * @param {String} trackId
 * @returns {{type: string, payload: String}}
 */
export function removeTrack(trackId) {
  return {type: REMOVE_TRACK, payload: trackId}
}


const MOVE_TRACK = 'embo/player/MOVE_TRACK'

/**
 * Moves the track with the given id to the given position.
 *
 * @param {String} trackId
 * @param {Number} position
 * @returns {{type: string, payload: {trackId: String, position: Number}}}
 */
export function moveTrack(trackId, position) {
  return {type: MOVE_TRACK, payload: {trackId, position}}
}


const SET_CURRENT_POSITION = 'embo/player/SET_CURRENT_POSITION'

/**
 * Sets the current playlist (zero-based) index. Index can be negative.
 *
 * @param {Number} index
 * @returns {{type: string, payload: Number}}
 */
export function setCurrentPosition(index) {
  return {type: SET_CURRENT_POSITION, payload: index}
}


const SET_CURRENT_TRACK = 'embo/player/SET_CURRENT_TRACK'

/**
 * Sets the current track to the track with the given id.
 *
 * @param {String} trackId
 * @returns {{type: string, payload: string}}
 */
export function setCurrentTrack(trackId) {
  return {type: SET_CURRENT_TRACK, payload: trackId}
}


const NEXT_TRACK = 'embo/player/NEXT_TRACK'

/**
 * Skips to the next track.
 *
 * @returns {{type: string}}
 */
export function nextTrack() {
  return {type: NEXT_TRACK}
}


const PREVIOUS_TRACK = 'embo/player/PREVIOUS_TRACK'

/**
 * Skips to the previous track.
 *
 * @returns {{type: string}}
 */
export function previousTrack() {
  return {type: PREVIOUS_TRACK}
}


const SET_INFO_PANEL_TRACK = 'embo/player/SET_INFO_PANEL_TRACK'

/**
 * Shows info about the track with the given id int the track info panel.
 *
 * @param {String} trackId
 *
 * @returns {{type: string, payload: string}}
 */
export function setInfoPanelTrack(trackId) {
  return {type: SET_INFO_PANEL_TRACK, payload: trackId}
}


const NEXT_TRACK_INFO = 'embo/player/NEXT_TRACK_INFO'

/**
 * Shows the next playlist's track int the track info panel.
 *
 * @returns {{type: string}}
 */
export function nextTrackInfo() {
  return {type: NEXT_TRACK_INFO}
}


const PREVIOUS_TRACK_INFO = 'embo/player/PREVIOUS_TRACK_INFO'

/**
 * Shows the previous playlist's track int the track info panel.
 *
 * @returns {{type: string}}
 */
export function previousTrackInfo() {
  return {type: PREVIOUS_TRACK_INFO}
}


const PRESENT_TRACK = 'embo/player/PRESENT_TRACK'

/**
 * Requests the track with the given id to be scrolled into view.
 *
 * @param {String} trackId
 * @returns {{type: string, payload: string}}
 */
export function presentTrack(trackId) {
  return {type: PRESENT_TRACK, payload: trackId}
}


const TRACK_PRESENTED = 'embo/player/TRACK_PRESENTED'

/**
 * Notifies that the track previously requested has been scrolled into view.
 *
 * @returns {{type: string}}
 */
export function trackPresented() {
  return {type: TRACK_PRESENTED}
}


export const AUDIO_ERROR = 'embo/player/AUDIO_ERROR'

/**
 * Notifies the track with the given id has encountered the given error.
 *
 * @param {String} trackId
 * @param {Error} error
 * @returns {{type: string, payload: {trackId: string, error: Error}}}
 */
export function audioError(trackId, error) {
  return {
    type: AUDIO_ERROR,
    payload: {trackId, error},
    meta: {analytics: true},
  }
}


const AUDIO_LOAD_SUCCESS = 'embo/player/AUDIO_LOAD_SUCCESS'

/**
 * Notifies the track with the given id has successfully loaded.
 *
 * @param {String} trackId
 * @returns {{type: string, payload: string}}
 */
export function audioLoadSuccess(trackId) {
  return {type: AUDIO_LOAD_SUCCESS, payload: trackId}
}


//
// Reducers
// --------------------------------------------------------------------------------------------------------------------

const {abs} = Math
const initialState = {
  tracks: [],
  currentIndex: 0,
  currentTrackId: null,
  presentedTrackId: null,
  infoPanelTrack: null,
  errors: {},
}

function circularIndex(i, arr) {
  const l = arr.length
  if (i < 0) return l - (abs(i) % l)
  return i % l
}

function findIndexById(id, tracks, errorMsg) {
  const i = tracks.indexOf(id)
  if (i === -1) {
    throw new Error(`${errorMsg} : ${id} not found.`)
  }
  return i
}

function insert(trackIds, pos, tracks) {
  const t = tracks.slice(0)
  if (!Array.isArray(trackIds)) {
    trackIds = [trackIds]
  }
  t.splice(pos, 0, ...trackIds)
  return t
}

function updateTrack(pos, data, tracks) {
  const track = tracks[pos]
  const t = tracks.slice(0)
  t.splice(pos, 1, {...track, ...data})
  return t
}

function remove(id, tracks) {
  const i = findIndexById(id, tracks, 'Could not remove track')
  const t = tracks.slice(0)
  t.splice(i, 1)
  return t
}

function move(id, pos, tracks) {
  const i = findIndexById(id, tracks, 'Could not move track')
  const t = tracks.slice(0)
  pos = circularIndex(pos, tracks)
  t.splice(i, 1)
  t.splice(pos, 0, id)
  return t
}


export default function reducer(state = initialState, action = {}) {
  const {type, payload} = action
  const {tracks, currentTrackId, currentIndex, errors} = state

  switch (type) {

    case SET_CURRENT_POSITION: {
      const i = circularIndex(payload, tracks)
      return {...state, currentIndex: i, currentTrackId: tracks[i]}
    }

    case NEXT_TRACK: {
      const i = tracks.indexOf(currentTrackId)
      const next = circularIndex(i + 1, tracks)
      return {...state, currentIndex: next, currentTrackId: tracks[next]}
    }

    case PREVIOUS_TRACK: {
      const i = tracks.indexOf(currentTrackId)
      const prev = circularIndex(i - 1, tracks)
      return {...state, currentIndex: prev, currentTrackId: tracks[prev]}
    }

    case SET_CURRENT_TRACK: {
      const i = findIndexById(payload, tracks, 'Could not set current track')
      return {...state, currentIndex: i, currentTrackId: payload}
    }

    case ADD_TRACK: {
      if (tracks.indexOf(payload.trackId) > -1) {
        return state
      }
      return {
        ...state,
        tracks: insert(payload.trackId, payload.position, tracks),
        currentTrackId: currentTrackId === null ? payload.trackId : currentTrackId,
      }
    }

    case ENQUEUE: {
      const newTracks = payload.filter(id => tracks.indexOf(id) === -1)
      if (!newTracks.length) {
        return state
      }
      return {
        ...state,
        tracks: insert(newTracks, Infinity, tracks),
        currentTrackId: currentTrackId === null ? newTracks[0] : currentTrackId,
      }
    }

    case REMOVE_TRACK: {
      if (tracks.length <= 1) {
        return initialState
      }
      const newTracks = remove(payload, tracks)
      if (payload === currentTrackId) {
        const next = circularIndex(currentIndex, newTracks)
        return {
          ...state,
          tracks: newTracks,
          currentIndex: next,
          currentTrackId: newTracks[next],
        }
      }
      return {...state, tracks: newTracks}
    }

    case MOVE_TRACK:
      return {...state, tracks: move(payload.trackId, payload.position, tracks)}

    case PRESENT_TRACK:
      return {...state, presentedTrackId: payload}
    case TRACK_PRESENTED:
      return {...state, presentedTrackId: null}

    case SET_INFO_PANEL_TRACK:
      if (state.infoPanelTrack === payload) return state
      return {...state, infoPanelTrack: payload}

    case NEXT_TRACK_INFO: {
      const i = tracks.indexOf(state.infoPanelTrack)
      const next = circularIndex(i + 1, tracks)
      return {...state, infoPanelTrack: tracks[next]}
    }

    case PREVIOUS_TRACK_INFO: {
      const i = tracks.indexOf(state.infoPanelTrack)
      const next = circularIndex(i - 1, tracks)
      return {...state, infoPanelTrack: tracks[next]}
    }

    case AUDIO_ERROR: {
      const {trackId, error} = payload
      return {...state, errors: {...errors, [trackId]: error}}
    }

    case AUDIO_LOAD_SUCCESS:
      if (!errors[payload]) {
        return state
      }
      delete errors[payload]
      return {...state, errors: {...errors}}

    default:
      return state
  }
}


//
// Selectors
// --------------------------------------------------------------------------------------------------------------------

const selectTrackIds = state => state.playlist.tracks
const selectCurrentIndex = state => state.playlist.currentIndex
const selectCurrentTrackId = state => state.playlist.currentTrackId
const selectAudioErrors = state => state.playlist.errors
const selectInfoPanelTrackId = state => state.playlist.infoPanelTrack


export const selectTracks = createSelector(
  [selectTrackIds, selectTrackEntities, selectEvents, selectPerformers, selectAudioErrors],
  (trackIds, tracks, events, performers, errors) => {
    return trackIds.map(id => {
      const track = tracks[id]
      const event = events[track.event]
      const performer = performers[track.performer]
      return {
        ...track,
        id: track['@id'],
        event,
        performer,
        error: errors[id],
      }
    })
  },
)

export const selectCurrentTrack = createSelector(
  [selectTracks, selectCurrentTrackId],
  (tracks, id) => tracks.find(track => track.id === id),
)

export const selectInfoPanelTrack = createSelector(
  [selectInfoPanelTrackId, selectTrackEntities, selectEvents, selectPerformers],
  (id, tracks, events, performers) => {
    if (!id) return
    const track = tracks[id]
    const event = events[track.event]
    const performer = performers[track.performer]
    return {
      ...track,
      performer,
      event: {
        ...event,
        performers: event.performer.map(id => performers[id]),
      },
    }
  },
)
