
const empty = []

/**
 * Utility for building SVG paths and convert them to string.
 */
export default class Path {
  constructor(intructions = []) {
    this.instructions = intructions
  }

  toString() {
    return this.instructions
      .map(([cmd, args]) => `${cmd} ${(args || empty).join(',')}`)
      .join(' ')
  }
}
