const IOS_TEST_RX = /iPhone|iPad|iPod/i
const SAFARI_TEST_RX = /Safari/i
const CHROME_TEST_RX = /Chrome|CriOS/i
const FIREFOX_TEST_RX = /Firefox|FxiOS/i
const OPERA_TEST_RX = /OPR|Opera|OPiOS/i
const IE_TEST_RX = /Trident|MSIE|IEMobile/i
const EDGE_TEST_RX = /Edge/i

const MOBILE_UA_RX = new RegExp([
  'mobile', 'tablet',
  'Android',
  'webOS',
  'iPhone', 'iPad', 'iPod',
  'BlackBerry',
  'IEMobile',
  'Opera (?:Mini|Mobi)',
  'SymbianOS',
  'Fennec',
  'GoBrowser',
  'Iris',
  'Maemo Browser',
  'Minimo',
  'SEMC-Browser',
  'Skyfire',
  'Teleca',
  'uZardWeb',
  'NetFront',
].join('|'), 'i')


/**
 * Provides information about device/OS/browser currently running.
 */
class Platform {
  /**
   * @param {!Window} win
   */
  constructor(win) {
    this.navigator = win.navigator
    this._ua = this._detect()
  }

  isMobile() {
    return this._ua.mobile
  }

  /**
   * Whether the current platform an iOS device.
   * @return {boolean}
   */
  isIos() {
    return this._ua.ios
  }

  /**
   * Whether the current browser is Safari.
   * @return {boolean}
   */
  isSafari() {
    const {safari, chrome, ie, edge, firefox, opera} = this._ua
    return safari && !chrome && !ie && !edge && !firefox && !opera
  }

  /**
   * Whether the current browser is a Chrome browser.
   * @return {boolean}
   */
  isChrome() {
    const {chrome, edge, opera} = this._ua
    return chrome && !edge && !opera
  }

  isFirefox() {
    const {firefox, edge} = this._ua
    return firefox && !edge
  }

  isOpera() {
    return this._ua.opera
  }

  isIe() {
    return this._ua.ie
  }

  isEdge() {
    return this._ua.edge
  }

  _detect() {
    const {userAgent} = this.navigator
    return {
      ios: IOS_TEST_RX.test(userAgent),
      safari: SAFARI_TEST_RX.test(userAgent),
      chrome: CHROME_TEST_RX.test(userAgent),
      firefox: FIREFOX_TEST_RX.test(userAgent),
      opera: OPERA_TEST_RX.test(userAgent),
      ie: IE_TEST_RX.test(userAgent),
      edge: EDGE_TEST_RX.test(userAgent),
      mobile: MOBILE_UA_RX.test(userAgent),
    }
  }
}

const platform = new Platform(window)
export default platform
