import React from 'react'
import {render} from 'react-dom'

import {loadEvent, loadPerformance, loadPost, loadStaticCarousel} from 'embo/api/dom'
import dom from 'embo/utils/dom'
import Carousel from 'embo/components/carousel/Carousel'


function renderCarousel(images, node) {
  // prevent duplicate images
  const ids = new Set()
  images = images.filter(img => !ids.has(img.id) && ids.add(img.id))
  return render(<Carousel images={images}/>, node)
}

function mountEventCarousel(node) {
  const {eventId} = node.dataset
  return loadEvent(eventId).then(({entities: {event}}) => {
    const {image} = event[eventId]
    if (image && image.length) {
      return renderCarousel(image, node)
    }
  })
}

function mountPerformanceCarousel(node) {
  const {eventId, performanceId} = node.dataset
  return loadPerformance(eventId, performanceId).then(({image}) => {
    if (image && image.length) {
      return renderCarousel(image, node)
    }
  })
}

function mountPostCarousel(node) {
  const {postId} = node.dataset
  return loadPost(postId).then(({image}) => {
    if (image && image.length) {
      return renderCarousel(image, node)
    }
  })
}

function mountStaticCarousel(node) {
  return loadStaticCarousel(node).then(images => {
    return renderCarousel(images, node)
  })
}

export default function mountCarousels() {
  const nodes = dom.qsa('[data-react-component="Carousel"]')
  const promises = nodes.map(node => {
    if (node.dataset.eventId) {
      if (node.dataset.performanceId) {
        return mountPerformanceCarousel(node)
      }
      return mountEventCarousel(node)
    } else if (node.dataset.postId) {
      return mountPostCarousel(node)
    }
    return mountStaticCarousel(node)
  })

  return Promise.all(promises)
}

