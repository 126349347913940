import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {getPentagramPath} from 'embo/utils/geom/pentagram'


const SIZES = {
  sm: {radius: 16, strokeWidth: 2},
  md: {radius: 32, strokeWidth: 2},
  lg: {radius: 64, strokeWidth: 4},
}

const Pentagram = ({
  size = 'sm',
  spinning = true,
  error = false,
}) => {
  const {radius, strokeWidth} = SIZES[size]
  const halfStroke = Math.max(strokeWidth / 2, 1)
  const doubleStroke = strokeWidth * 2
  const width = radius * 2 + doubleStroke + halfStroke
  const cx = width / 2
  const path = getPentagramPath(radius, cx, cx)
  const classes = cn('spinner-pentagram', `spinner-pentagram--${size}`, {
    'spinner-pentagram--spinning': spinning,
    'spinner-pentagram--error': error,
  })

  return (
    <div className={classes}>
      <svg width={width} height={width} viewBox={`0 0 ${width} ${width}`}>
        <circle className="spinner-pentagram__circle-outer"
          cx={cx} cy={cx}
          r={radius + strokeWidth}
          strokeWidth={halfStroke}
        />
        <circle className="spinner-pentagram__circle-inner"
          cx={cx} cy={cx}
          r={radius - doubleStroke}
          strokeWidth={halfStroke}
        />
        <path className="spinner-pentagram__pentagram"
          d={path.toString()}
          strokeWidth={strokeWidth}
        />
      </svg>
    </div>
  )
}
Pentagram.propTypes = {
  size: PropTypes.oneOf(Object.keys(SIZES)),
  spinning: PropTypes.bool,
  error: PropTypes.bool,
}

export default React.memo(Pentagram)
