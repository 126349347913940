import {on, off} from 'embo/utils/events'
import Observable from 'embo/utils/Observable'

import Route from './route'
import Context from './context'
import {isSameOrigin} from './url'
import actions from './actions'


const defaults = {
  dispatch: true,
  base: '',
}

export default class Router {
  constructor(options) {
    this.options = {
      ...defaults,
      ...options,
    }
    this._base = options.base || ''

    // Add custom exclusion patterns to the click handler
    this._exclusionRules = []
    /**
     * Current path being processed
     * @type {String}
     */
    this._currentPath = ''
    /**
     * Number of pages navigated to.
     * @type {number}
     */
    this._length = 0

    /**
     * Previous context, for capturing
     * page exit events.
     */
    this._prevContext
    this._running = false

    this._handlers = []
    this._exitHandlers = []

    this._removeClickHandler = () => {
    }

    this._outboundLinkObservable = new Observable()
  }

  get base() {
    return this._base
  }

  get current() {
    return this._currentPath
  }

  get previous() {
    return this._prevContext.path
  }

  get size() {
    return this._length
  }

  onOutboundLinkClicked(handler) {
    return this._outboundLinkObservable.add(handler)
  }

  /**
   * Add rules stop exclude links from the routing mechanism.
   * Rule can be:
   *   * a RegExp or string, that will be tested against the pathname
   *   * a function, that will be passed the <a> element and the click event.
   *
   * @param {...(string|RegExp|Function)} rules
   */
  exclude(...rules) {
    this._exclusionRules.push(...rules)
    return this
  }

  /**
   * Register `path` with callback `fn()`,
   *
   *   register(handler);
   *   register('*', handler);
   *   register('/user/:id', load, user);
   *
   * @param {String|RegExp|Function} path
   * @param {...Function} handlers
   * @api public
   */
  register(path, ...handlers) {
    if (typeof path === 'function') {
      return this.register('*', path)
    }

    const route = new Route(path)
    handlers.forEach(handler => {
      this._handlers.push(route.middleware(handler))
    })
    return this
  }

  /**
   * Register an exit route on `path` with
   * callback `fn()`, which will be called
   * on the previous context when a new
   * page is visited.
   */
  exit(path, ...handlers) {
    if (typeof path === 'function') {
      return this.exit('*', path)
    }

    const route = new Route(path)
    handlers.forEach(handler => {
      this._exitHandlers.push(route.middleware(handler))
    })
    return this
  }

  start() {
    if (this._running) {
      return
    }
    this._running = true

    window.addEventListener('popstate', this.onPopState, false)
    this._removeClickHandler = on(document.body, 'click', 'a[href]', this.handleLinkClicked)

    if (!this.options.dispatch) {
      return
    }

    const url = location.pathname + location.search + location.hash
    this.replace(url, {}, true, true)
  }

  stop() {
    if (this._running) {
      return
    }

    this._currentPath = ''
    this._length = 0
    this._running = false

    this._removeClickHandler()
    window.removeEventListener('popstate', this.onPopState, false)
  }

  /**
   * Show `path` with optional `state` object.
   *
   * @param {String} path
   * @param {Object} state
   * @param {Boolean} dispatch
   * @param {Boolean} push
   * @return {Context}
   * @api public
   */
  show(path, state = {}, dispatch = true, push = true) {
    const context = new Context(path, state, this._base)
    this._currentPath = context.path
    context.action = push ? actions.PUSH : actions.POP

    if (dispatch) {
      this.dispatch(context)
    }

    if (push && !context.isPropagationStopped) {
      context.pushState()
      this._length++
    }

    return context
  }

  back(path, state = {}) {
    if (this._length > 0) {
      window.history.back()
      this._length--
    } else if (path) {
      setTimeout(() => this.show(path, state), 0)
    } else {
      setTimeout(() => this.show(this._base, state), 0)
    }
  }

  redirect(from, to) {
    // Define route from a path to another
    if (from && to) {
      this.register(from, () => setTimeout(() => this.replace(to), 0))
    }

    // Wait for the push state and replace it with another
    if (!to) {
      setTimeout(() => this.replace(from), 0)
    }
  }

  replace(path, state = {}, init = false, dispatch = true) {
    const context = new Context(path, state, this._base)
    this._currentPath = context.path
    context.init = init
    context.action = actions.REPLACE
    context.save() // save before dispatching, which may redirect
    if (dispatch) {
      this.dispatch(context)
    }

    return context
  }

  /**
   * Dispatches the given context to the route handlers.
   *
   * @param {Context} context
   */
  dispatch(context) {
    const previousContext = this._prevContext
    this._prevContext = context

    let enters = 0
    let exits = 0

    const nextEnter = () => {
      const callback = this._handlers[enters++]

      if (context.path !== this._currentPath) {
        context.stopPropagation()
        return
      }
      if (!callback) {
        return this.unhandled(context)
      }
      callback(context, nextEnter)
    }

    const nextExit = () => {
      const callback = this._exitHandlers[exits++]
      if (!callback) {
        return nextEnter()
      }
      callback(previousContext, nextExit)
    }

    if (previousContext) {
      nextExit()
    } else {
      nextEnter()
    }
  }

  /**
   * Unhandled `context`.
   * When it's not the initial popstate then redirect.
   * If you wish to handle 404s on your own use `register('*', callback)`.
   *
   * @param {Context} context
   * @api private
   */
  unhandled(context) {
    if (!context.isPropagationStopped) {
      return
    }

    const current = location.pathname + location.search
    if (current === context.canonicalPath) {
      return
    }

    this.stop()
    context.stopPropagation()
    location.href = context.canonicalPath
  }

  onPopState = (() => {
    let loaded = false
    if ('undefined' === typeof window) {
      return
    }
    if (document.readyState === 'complete') {
      loaded = true
    } else {
      window.addEventListener('load', () => setTimeout(() => loaded = true, 0))
    }
    return e => {
      if (!loaded) return
      if (e.state) {
        const path = e.state.path
        this.replace(path, e.state)
      } else {
        this.show(location.pathname + location.hash, {}, true, false)
      }
    }
  })()

  handleLinkClicked = ({event, target: el}) => {
    if (event.defaultPrevented) return

    if (!isSameOrigin(el.href)) {
      this._outboundLinkObservable.fire({target: el})
      return
    }
    if (
      // ignored mouse modifiers
      event.button !== 0
      || event.metaKey || event.ctrlKey || event.shiftKey
      // ignored attributes
      || el.target
      || el.hasAttribute('download')
      || ['download', 'external'].includes(el.rel)
    ) {
      return
    }

    // ensure non-hash for the same path
    const link = el.getAttribute('href')
    if (el.pathname === location.pathname && (el.hash || '#' === link)) {
      return
    }

    // rebuild path
    let path = el.pathname + el.search + (el.hash || '')

    // same page
    const orig = path

    // remove base url from path
    if (path.startsWith(this._base)) {
      path = path.substr(this._base.length)
    }

    if (this._base && orig === path) {
      return
    }

    if (this._isLinkExcluded(event, el, path)) {
      return
    }

    event.preventDefault()

    this.show(orig)
  }

  _isLinkExcluded(event, link, path) {
    return this._exclusionRules.some(rule => {
      if (rule instanceof RegExp) {
        return path.test(rule)
      }
      switch (typeof rule) {
        case 'function':
          return rule(path, link, event)
        case 'string':
          return path.startsWith(rule)
        default:
          return false
      }
    })
  }
}
