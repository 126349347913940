import PropTypes from 'prop-types'

export const eventShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  performer: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })).isRequired,
})
