import React from 'react'
import {Provider} from 'react-redux'

import Minical from './Minical'

const MinicalWrapper = ({store}) => (
  <Provider store={store}>
    <Minical/>
  </Provider>
)
export default MinicalWrapper
