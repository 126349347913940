import './polyfills'


const dom = {

  id: document.getElementById.bind(document),

  qs(selector, element = null) {
    if (typeof element === 'string') {
      element = document.querySelector(element)
    }
    return (element || document).querySelector(selector)
  },

  qsa(selector, element = null) {
    if (typeof element === 'string') {
      element = document.querySelector(element)
    }
    return Array.from((element || document).querySelectorAll(selector))
  },

  el(name, attrs = {}, style = {}) {
    const el = document.createElement(name)
    Object.keys(attrs).forEach(k => {
      if (k === 'html') {
        el.innerHTML = attrs[k]
      } else if (k === 'text') {
        el.textContent = attrs[k]
      } else if (k === 'className') {
        el.className = attrs[k]
      } else {
        el.setAttribute(k, attrs[k])
      }
    })
    Object.keys(style).forEach(k => el.style[k] = style[k])

    return el
  },

  parse(str, all = false) {
    const tmp = document.createElement('div')
    tmp.innerHTML = str
    if (all) return Array.from(tmp.childNodes)
    return Array.from(tmp.children)
  },

  empty(el) {
    el.innerHTML = ''
    return el
  },

  before(node, who) {
    who.parentNode.insertBefore(node, who)
    return node
  },

  after(node, who) {
    who.parentNode.insertBefore(node, who.nextSibling)
    return node
  },

  eval(el) {
    dom.qsa('script', el)
      .filter(script => script.type === 'text/javascript' || !script.type)
      .forEach(script => {
        const newScript = dom.el('script', {
          type: 'text/javascript',
          text: script.textContent,
          src: script.src,
        })
        document.head.appendChild(newScript)
        document.head.removeChild(newScript)
      })
  },

  attrs(el, attrs) {
    Object.keys(attrs).forEach(name => el.setAttribute(name, attrs[name]))
    return el
  },

  addClass(el, ...classes) {
    classes.forEach(cls => el.classList.add(cls))
    return el
  },

  removeClass(el, ...classes) {
    classes.forEach(cls => el.classList.remove(cls))
    return el
  },

  hasClass(el, ...classes) {
    return classes.every(cls => el.classList.contains(cls))
  },
}

export default dom
