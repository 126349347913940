import {SECONDS, MINUTES, HOURS, DAYS} from './constants'


function setDayOfWeek(date, day) {
  day = day % 7
  let distance = day - date.getDay()
  date.setDate(date.getDate() + distance)
  return date
}


export default class DateRange {
  constructor(start, end) {
    this._start = new Date(start)
    this._end = new Date(end)
  }

  get start() {
    return this._start
  }

  set start(date) {
    this._start = new Date(date)
  }

  get end() {
    return this._end
  }

  set end(date) {
    this._end = new Date(date)
  }

  toJSON() {
    return JSON.stringify({
      start: this._start,
      end: this._end,
    })
  }

  get length() {
    let milliseconds = this._end - this._start
    let years = this._end.getFullYear() - this._start.getFullYear()
    let months = this._end.getMonth() - this._start.getMonth() + (years * 12)
    let seconds = milliseconds / SECONDS
    let minutes = milliseconds / MINUTES
    let hours = milliseconds / HOURS
    let days = milliseconds / DAYS
    return {years, months, days, hours, minutes, seconds, milliseconds}
  }

  valueOf() {
    return this._end - this._start
  }

  static day(date) {
    if (!date) {
      date = new Date()
    }
    let start = new Date(date)
    let end = new Date(date)
    start.setHours(0, 0, 0, 0)
    end.setHours(23, 59, 59, 999)
    return new DateRange(start, end)
  }

  static today() {
    return DateRange.day()
  }

  static week(date, startDay = 0) {
    let range = DateRange.day(date)
    setDayOfWeek(range.start, startDay)
    range.end.setDate(range.start.getDate() + 6)
    return range
  }

  static thisWeek(startDay = 0) {
    return DateRange.week(null, startDay)
  }

  static month(date) {
    let range = DateRange.day(date)
    range.start.setDate(1)
    range.end.setFullYear(range.start.getFullYear(), range.start.getMonth() + 1, 0)
    return range
  }

  static thisMonth() {
    return DateRange.month()
  }

  static year(date) {
    let range = DateRange.day(date)
    range.start.setFullYear(range.start.getFullYear(), 0, 1)
    range.end.setFullYear(range.start.getFullYear(), 11, 31)
    return range
  }

  static thisYear() {
    return DateRange.year()
  }

  clone() {
    return new DateRange(this._start, this._end)
  }

  moveTo(to) {
    to = new Date(to)
    let diff = to - this._start
    let end = new Date((+this._end) + diff)
    return new DateRange(to, end)
  }

  intersection(other) {
    if (other instanceof Date) {
      return this.contains(other) ? new DateRange(other, other) : null
    }
    let start = Math.max(this._start, other.start)
    let end = Math.min(this._end, other.end)
    if (start <= end) {
      return new DateRange(start, end)
    }
    return null
  }

  // Iterators

  forEachDay(cb) {
    let it = new Date(this._start)
    let i = 0
    while (it < this._end) {
      cb(new Date(it), i)
      it.setDate(it.getDate() + 1)
      i++
    }
    return this
  }

  // Tests

  contains(other) {
    if (other instanceof Date) {
      return this._start <= other && this._end >= other
    }
    if (other instanceof DateRange) {
      return this._start <= other.start && this._end >= other.end
    }
    throw new Error(
      `Argument 1 for DateRange.contains must be instance of Date or DateRange, not ${typeof other}`,
    )
  }

  intersects(other) {
    if (other instanceof Date) {
      return this.contains(other)
    }
    if (other instanceof DateRange) {
      return (
        (this.contains(other.start) || this.contains(other.end))
        ||
        (other.start < this._start && other.end > this._end)
      )
    }

    throw new Error(
      `Argument 1 for DateRange.intersects must be instance of Date or DateRange, not ${typeof other}`,
    )
  }

  isValid() {
    return this._start <= this._end
  }

  isAfter(other) {
    if (other instanceof Date) {
      return this._start > other
    }
    return this._start > other.end
  }

  isBefore(other) {
    if (other instanceof Date) {
      return this._start < other
    }
    return this._end < other.start
  }

  startsBefore(other) {
    if (other instanceof Date) {
      return this._start < other
    }
    return this._start < other.start
  }

  startsAfter(other) {
    if (other instanceof Date) {
      return this._start > other
    }
    return this._start > other.start
  }

  endsBefore(other) {
    if (other instanceof Date) {
      return this._end < other
    }
    return this._end < other.end
  }

  endsAfter(other) {
    if (other instanceof Date) {
      return this._end > other
    }
    return this._end > other.end
  }
}
